import { FunctionComponent } from 'preact'
import { TextareaAutosize, TextareaAutosizeProps } from '../textarea-autosize'

export interface TextareaComponentProps extends TextareaAutosizeProps {
  autofocus?: boolean
}

export const TextareaComponent: FunctionComponent<TextareaComponentProps> = ({
  value,
  onChange,
  readonly,
  placeholder,
  onFocus,
  onBlur,
  ...props
}) => {
  // const [rows, setRows] = useState(1)
  // TODO: implement row-numbers https://jsfiddle.net/9QzQy/

  // const calculateRowNumber = useCallback(() => {
  //   if (iteration === 0) {
  //     initialHeight =
  //   }
  // }, [])

  // in HTML, textarea does not support the value parameter. In React, child value is not supported (e.g., <textarea>{value}</textarea> is not supported)
  // ref: https://reactjs.org/docs/forms.html#the-textarea-tag
  return (
    <div className="textarea-component-wrapper">
      <TextareaAutosize
        className="textarea-component focus-target"
        onInput={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readonly}
        placeholder={placeholder}
        value={value}
        {...props}
        // style={{
        //   overflow: 'hidden',
        //   minHeight: `${rows}em`,
        // }}
      />
      {/* <div
          style={{
            display: 'none',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            padding: getCss(ref.current, 'padding') as number,
            width: getCss(ref.current, 'width') as number,
            fontFamily: getCss(ref.current, 'fontFamily') as number,
            fontSize: getCss(ref.current, 'fontSize') as number,
            lineHeight: getCss(ref.current, 'lineHeight') as number,
          }}
        >
          {value}
        </div> */}
    </div>
  )
}
