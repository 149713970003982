import { Observable, Quest, track, trxWrap } from '@edvoapp/plm-common';
import { subscribe } from './pubsub';

export class QuestManager {
  activeQuest = new Observable<Quest | null>(null);
  initialized = false;
  pendingActiveQuestId: string | null = null;
  constructor() {
    subscribe('SET_ACTIVE_QUEST', ({ activeQuestID }: { activeQuestID: string | null }) => {
      if (this.initialized) {
        this.activateQuest(activeQuestID ? Quest.getById({ id: activeQuestID }) : null);
      } else {
        this.pendingActiveQuestId = activeQuestID;
      }
    });
  }

  init() {
    this.initialized = true;
    const currentQuestId = this.pendingActiveQuestId || sessionStorage.getItem('currentQuestId');
    this.activeQuest.setValue(currentQuestId ? Quest.getById({ id: currentQuestId }) : null);
    this.pendingActiveQuestId = null;
  }

  async createQuest(name: string): Promise<Quest> {
    return trxWrap(async trx => {
      const quest = Quest.create({ trx, name });
      this.activeQuest.setValue(quest);

      const id = await quest.getId();
      sessionStorage.setItem('currentQuestId', id);

      track('ext_quest_new');
      return quest;
    });
  }

  activateQuest(quest: Quest | null) {
    this.activeQuest.setValue(quest);

    track('ext_quest_change');
    if (quest) {
      void quest.getId().then(id => {
        sessionStorage.setItem('currentQuestId', id);
      });

      void trxWrap(trx => {
        quest.touch(trx);
      }, 'quest');
    } else {
      sessionStorage.removeItem('currentQuestId');
    }
  }
}

const questManager = new QuestManager();
export const useQuestManager = () => {
  return questManager;
};
