import {
  Artifact,
  ArtifactDataDB,
  ContentRegion,
  getCurrentUserID,
  HighlightAttributes,
  NowValueOnce,
  Observable,
  PaintableRegion,
  Query,
  QueryObservable,
} from '@edvoapp/plm-common';
import firebase from 'firebase/app';
import { Highlight } from '../model/highlight';
import { Mode } from '../providers';
import { publishMessageToOuter, subscribe } from './pubsub';

export interface Region {
  paintableRegion: PaintableRegion;
  contentRegion: ContentRegion;
  highlights: Set<Highlight>;
}

type ScrollDims = {
  x: number;
  y: number;
};

export class OverlayManager {
  protected _regions: Region[] = [];
  readonly highlightsByKey: Record<string, Highlight> = {};
  readonly highlightsByIndex: Record<number, Highlight> = {};
  readonly highlightsByArtifactId: Record<string, Highlight> = {};
  // ANTI PATTERN -- just an easy way to index the highlight box itself
  readonly highlightsBySelectorSet: Record<string, Highlight> = {};
  readonly activeHighlight = new Observable<Highlight | null>(null);
  highlightArtifacts = new NowValueOnce<QueryObservable<ArtifactDataDB, Artifact> | null>(null);
  pageArtifact: Artifact | null = null;

  constructor(protected _mode: Mode, private _forceUpdate: (action: unknown) => void) {
    this.bindPaintableRegions();
    this.bindScroll();
    this.bindBlur();
  }

  bindPaintableRegions() {
    subscribe<{
      regions: {
        contentAreaIndex: number;
        contentAreaRect: DOMRectReadOnly;
        paintableRect: DOMRectReadOnly;
      }[];
      scroll: ScrollDims;
    }>('PAINTABLE_REGIONS_SUCCESS', ({ regions, scroll }) => {
      if (this._regions.length) {
        this._regions.forEach(({ paintableRegion, contentRegion }) => {
          contentRegion.unpaint();
          paintableRegion.unpaint();
        });
      }
      const regionList: Region[] = [];
      regions.forEach(r => {
        const { contentAreaIndex, contentAreaRect, paintableRect } = r;
        const contentRegion = new ContentRegion(contentAreaRect, contentAreaIndex, scroll, this._mode);
        contentRegion.paint();
        const paintableRegion = new PaintableRegion(paintableRect, scroll, this._mode);
        paintableRegion.paint();
        regionList.push({ paintableRegion, contentRegion, highlights: new Set<Highlight>() });
      });
      this._regions = regionList;
    });
  }
  bindScroll() {
    subscribe<ScrollDims>('SCROLL', scrollDims => {
      this._regions.forEach(({ contentRegion, paintableRegion }) => {
        contentRegion.move(scrollDims);
        paintableRegion.move(scrollDims);
      });
    });
  }
  bindBlur() {
    window.addEventListener('blur', () => {
      this.activeHighlight.setValue(null);
    });
  }

  // highlight-related logic
  async setPageArtifact(pageArtifact: Artifact) {
    this.pageArtifact = pageArtifact;
    this.highlightArtifacts.set(
      new QueryObservable({
        val: [],
        name: `highlight-manager(${pageArtifact.prettyId()})`,
        ctor: (snapshot): Artifact => {
          const docRef = snapshot.ref;
          const data = snapshot.data();
          if (!data) throw 'OverlayManager: failed to deserialize artifact';
          return Artifact.hydrate({ docRef, data, parent: pageArtifact });
        },
      }),
    );
    this.bindExistingHighlights();
    await this.injectQuery();
    this.bindHighlightCreate();
    this.bindPaintSuccess();
    this.bindHighlightClick();
  }

  setActiveHighlightIndex(highlightIndex: number | null) {
    if (highlightIndex === null) {
      this.activeHighlight.setValue(null);
    } else {
      this.activeHighlight.setValue(this.highlightsByIndex[highlightIndex]);
    }
  }

  setActiveHighlightArtifactID(artifactID: string | null) {
    if (artifactID === null) {
      this.activeHighlight.setValue(null);
    } else {
      this.activeHighlight.setValue(this.highlightsByArtifactId[artifactID]);
    }
  }

  async injectQuery() {
    const firestore = firebase.firestore();
    const uid = getCurrentUserID();
    const id = await this.pageArtifact?.getId();
    console.log('page artifact', id);
    let query = firestore
      .collection('artifact')
      .where('status', '==', 'active')
      .where('parentArtifactID', '==', id)
      .where('kind', '==', 'highlight') as Query<ArtifactDataDB>;

    if (uid) {
      query = query.where('userID', '==', uid);
    }

    const hlArtifacts = this.highlightArtifacts.peek_or_throw('highlight artifacts QO should exist');
    if (hlArtifacts === null) throw 'highlight artifacts QO should exist';
    hlArtifacts.injectQuery(query);
    hlArtifacts.execute();
    await hlArtifacts.awaitLoad();
  }

  bindPaintSuccess() {
    subscribe<{
      id: string;
      highlightIndex: number;
      rect: DOMRectReadOnly;
      contentAreaIndex: number;
    }>('HIGHLIGHT_PAINT_SUCCESS', args => {
      const { id, highlightIndex, rect, contentAreaIndex } = args;
      const hl = this.highlightsByArtifactId[id];
      if (hl) {
        this.highlightsByIndex[highlightIndex] = hl;
        hl.setRenderContext({
          highlightIndex,
          rect,
          contentAreaIndex,
        });
        this.paintSuccess(contentAreaIndex, hl);
        this._forceUpdate(null);
      }
    });
  }

  bindHighlightClick() {
    subscribe<{ highlightIndex: number }>('HIGHLIGHT_CLICK', ({ highlightIndex }) => {
      this.setActiveHighlightIndex(highlightIndex);
    });
  }

  bindExistingHighlights() {
    this.highlightArtifacts.peek()?.subscribe({
      ITEM_LISTENER: (artifact, op) => {
        if (op === 'ADD') {
          const artifactId = artifact.id();
          const selectorSet = (artifact.attributes.peek() as HighlightAttributes | null)?.highlightSelectorSet;
          if (artifactId && selectorSet) {
            const existingHighlight = this.highlightsBySelectorSet[selectorSet];
            if (existingHighlight) {
              this.highlightsByArtifactId[artifactId] = existingHighlight;
            } else {
              let highlight = new Highlight({ artifact });
              this.highlightsByKey[highlight.key] = highlight;
              this.highlightsByArtifactId[artifactId] = highlight;
              this.highlightsBySelectorSet[selectorSet] = highlight;
              const highlightIndex = parseInt(selectorSet.split('$$')[3], 10);
              this.highlightsByIndex[highlightIndex] = highlight;
              publishMessageToOuter('HIGHLIGHT_PAINT_REQUEST', {
                selectorSet: (artifact.attributes.peek_or_throw('attributes') as HighlightAttributes)
                  ?.highlightSelectorSet,
                id: artifactId,
              });
            }
          }
        } else if (op === 'REMOVE') {
          const artifactId = artifact.docReference.peek()?.id;
          if (!artifactId) return;
          const highlight = this.highlightsByArtifactId[artifactId];
          if (!highlight) return;
          publishMessageToOuter('HIGHLIGHT_REMOVE_REQUEST', { key: highlight.key, artifactId });
        }
      },
    });
  }
  bindHighlightCreate() {
    subscribe<{
      rect: DOMRect;
      tagName: string;
      text: string;
      leading: string;
      trailing: string;
      highlightIndex: number;
      contentAreaIndex: number;
    }>('HIGHLIGHT_CREATE', args => {
      const { tagName, text, leading, trailing, highlightIndex, contentAreaIndex, rect } = args;

      const selectorSet = `EdvoHighlight$$${tagName}$$${text}$$${highlightIndex}`;

      const parentArtifact = this.pageArtifact;
      if (parentArtifact) {
        const highlight = new Highlight({
          renderContext: {
            contentAreaIndex,
            rect,
            highlightIndex,
          },
          params: {
            selectorSet,
            parentArtifact,
            text,
            leadingText: leading,
            trailingText: trailing,
          },
        });

        this.highlightsByKey[highlight.key] = highlight;
        this.highlightsByIndex[highlightIndex] = highlight;
        this.highlightsBySelectorSet[selectorSet] = highlight;
        this.paintSuccess(contentAreaIndex, highlight);
        this.activeHighlight.setValue(highlight);
      }
    });
  }

  paintSuccess(regionIndex: number, highlight: Highlight) {
    const region = this._regions[regionIndex];
    region.highlights.add(highlight);
  }

  getRegionByIndex(index: number) {
    return this._regions[index];
  }

  setMode(mode: Mode) {
    this._mode = mode;
    this._regions.forEach(({ paintableRegion, contentRegion }) => {
      contentRegion.unpaint();
      contentRegion.setMode(mode);
      paintableRegion.unpaint();
      paintableRegion.setMode(mode);
      if (mode !== 'INACTIVE') {
        paintableRegion.paint();
        contentRegion.paint();
      }
      this._forceUpdate(null);
    });
  }

  findRegionByHighlight(highlight: Highlight) {
    return this._regions.find(region => region.highlights.has(highlight));
  }

  getContainingPaintableRegion(highlight: Highlight) {
    const region = this.findRegionByHighlight(highlight);
    if (!region) return;
    return region.paintableRegion;
  }
}
