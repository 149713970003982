import { track } from '@edvoapp/plm-common';
import { createContext, FunctionComponent } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { publishMessageToOuter, useSubscribeOnMount } from '../services/pubsub';
import { useProvider } from './common';
import { useNavigator } from './navigator-provider';

export type Mode = 'INACTIVE' | 'BACKGROUND' | 'ACTIVE';
export type ModeContextType = { mode: Mode; updateMode: (mode: Mode) => void; modeDebounce: boolean };

export const ModeContext = createContext<ModeContextType | null>(null);

export const SIDEBAR_WIDTH = 300;

function getMode(): Mode {
  // if the inner is being injected, then likely we want to be in active mode
  const mode = (sessionStorage.getItem('mode') || 'ACTIVE') as Mode;
  publishMessageToOuter('SET_MODE', { mode });
  sessionStorage.setItem('mode', mode);
  return mode;
}

// TODO: add default build flag to default it to inactive for extension, but active for using the Viewer Proxy
export const ModeProvider: FunctionComponent = ({ children }) => {
  const [mode, setMode] = useState<Mode>(getMode);
  const [modeDebounce, setModeDebounce] = useState(false);
  const { publishMessageToExtension } = useNavigator();

  // when setting the mode from within the app, we use updateMode instead of setMode. This lets us send a message to any listeners
  const updateMode = useCallback(
    (mode: Mode) => {
      sessionStorage.setItem('mode', mode);
      setMode(mode);
      publishMessageToOuter('SET_MODE', { mode });
      publishMessageToExtension('SET_MODE_FROM_INNER', { mode });
    },
    [setMode, publishMessageToExtension],
  );

  useSubscribeOnMount<{ mode: Mode }>('SET_MODE_FROM_EXTENSION', ({ mode }) => {
    sessionStorage.setItem('mode', mode);
    setMode(mode);
    console.log('mode from extn!', mode);
  });

  useEffect(() => {
    // when mode changes, stop stuff from rerendering for 1 second
    setModeDebounce(true);
    setTimeout(() => {
      setModeDebounce(false);
    }, 1000);
    // also, send an event trigger
    track(mode === 'ACTIVE' ? 'ext_drawer_open' : 'ext_drawer_hide');
  }, [mode]);

  return (
    <ModeContext.Provider
      value={{
        mode,
        updateMode,
        modeDebounce,
      }}
    >
      {children}
    </ModeContext.Provider>
  );
};

export const ModeConsumer = ModeContext.Consumer;
export const useMode = () => useProvider(ModeContext, 'mode');
