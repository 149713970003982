import firebase from 'firebase/app';
import { RolesMap } from '../internal';
import { DocumentReference } from '../utils/firebase';
import { ActivatableAsync, ActivatableSync, AwaitableValue } from './AwaitValue';
import { Backref, BackrefDataDB } from './Backref';
import { ClaimLinkedListObservable } from './ClaimLinkedListObservable';
import { QueryObservable } from './QueryObservable';

export interface Entity {
  type: string;
  key(): string;
  id(): string | undefined;
  getId(): Promise<string>;
  diag(load: boolean, tier: number, postfix?: string): Promise<string>;
  prettyId(): string;
  unifiedId(): UnifiedId | undefined;
  unifiedIdStruct(): UnifiedIdStruct | undefined;
  docReference: AwaitableValue<DocumentReference<any>>;
  _firebase: firebase.app.App;
}

export interface Referenceable extends Entity {
  backrefs: ActivatableAsync<QueryObservable<BackrefDataDB, Backref>>;
  children(roles: RolesMap): ClaimLinkedListObservable;
  getReverseEdgeTarget(role: string[], kind?: 'claim' | 'artifact' | 'quest'): Promise<Entity | null>;
}

export function isReferenceable(obj: any): obj is Referenceable {
  return 'backrefs' in obj;
}

export interface CollectionParent {
  subCollection(key: string): ActivatableAsync<QueryObservable<any, any>> | ActivatableSync<QueryObservable<any, any>>;
  id(): string;
}
export type CollectionName = 'claim' | 'quest' | 'artifact' | 'claimBackref' | 'claimPart' | 'artifactPart';

export class UnifiedId {
  constructor(readonly collectionName: CollectionName, readonly id: string) { }
  toString(): string {
    return `${this.collectionName}:${this.id}`
  }
  toStruct(): UnifiedIdStruct {
    switch (this.collectionName) {
      case 'claim': return { claimID: this.id };
      case 'quest': return { questID: this.id };
      case 'artifact': return { artifactID: this.id };
      case 'claimBackref': return { backrefID: this.id };
      case 'claimPart': return { claimPartID: this.id };
      case 'artifactPart': return { artifactPartID: this.id };
      default: throw "sanity error"
    }
  }
  static fromStruct(struct: UnifiedIdStruct): UnifiedId | undefined {
    if (struct.claimID) return new UnifiedId('claim', struct.claimID);
    if (struct.questID) return new UnifiedId('quest', struct.questID);
    if (struct.artifactID) return new UnifiedId('artifact', struct.artifactID);
    if (struct.backrefID) return new UnifiedId('claimBackref', struct.backrefID);
    if (struct.claimPartID) return new UnifiedId('claimPart', struct.claimPartID);
    if (struct.artifactPartID) return new UnifiedId('artifactPart', struct.artifactPartID);
    return undefined;
  }
  static fromDocRef(docRef: DocumentReference<any>) {
    switch (docRef.path) {
      case 'claim': return new UnifiedId('claim', docRef.id)
      case 'quest': return new UnifiedId('quest', docRef.id)
      case 'artifact': return new UnifiedId('artifact', docRef.id)
      case 'claim/claimBackref': return new UnifiedId('claimBackref', docRef.id)
      case 'claim/claimPart': return new UnifiedId('claimPart', docRef.id)
      case 'artifact/artifactPart': return new UnifiedId('artifactPart', docRef.id)
    }
  }
  toDocRef(): DocumentReference<any> {
    if (['claimPart', 'claimBackref', 'artifactPart'].includes(this.collectionName)) {
      // TODO - unimplemented
      throw "unimplemented";
    }
    return firebase.firestore().collection(this.collectionName).doc(this.id)
  }
}
export type UnifiedIdStruct = {
  claimID?: string;
  questID?: string;
  artifactID?: string;
  backrefID?: string;
  claimPartID?: string;
  artifactPartID?: string;
};

// import firebase from 'firebase/app';
// import {
//   ArtifactDataDB,
//   ArtifactPart,
//   ArtifactPartData,
//   Backref,
//   BackrefDataDB,
//   Claim,
//   ClaimData,
//   ClaimLinkedListObservable,
//   ClaimPart,
//   ClaimPartDataDB,
//   DocumentReference,
//   getRolesFromRoleBase,
//   Query,
//   QueryableDb,
//   QueryableObj,
//   QueryObservable,
//   QuestDataDB,
//   OldBaseClass,
//   RoleBase,
//   RolesMap,
//   Transaction,
//   trxWrap,
// } from '../internal';

// export type LocalStatus = 'clean' | 'dirty';

// export abstract class Entity<
//   EntityData extends ArtifactDataDB | ClaimData | QuestDataDB = any,
//   EntityPart extends ArtifactPart | ClaimPart = any,
//   EntityPartData extends ArtifactPartData | ClaimPartDataDB = any
//   > extends OldBaseClass<EntityData> {
//   id?: string;
//   protected _key?: string;
//   protected _isCreating: Promise<void> | null = null;
//   protected abstract parts: QueryObservable<EntityPartData, EntityPart>;
//   protected _pendingTrx: Transaction[] = [];
//   protected _didSubscribeChildBackrefs = false;
//   protected constructor(args: any, protected _firebase: firebase.app.App = firebase.app()) {
//     super(_firebase);
//   }

//   localStatus: LocalStatus = 'clean';
//   activeOps: Record<string, { tally: number }> = {};

//   abstract awaitLookup(): Promise<void>;

//   setDoc(doc: DocumentReference<EntityData>) {
//     super.setDoc(doc);

//     // Inject all dependent queryies right away
//     if (!this.parts.isInjected) {
//       let query = doc.collection(`${this.type}Part`).where('status', '==', 'active') as Query<EntityPartData>;
//       this.parts.injectQuery(query);
//     }
//   }

//   // EXCLUSIVE means that we are getting the data JUST for this instance, we aren't expecting it to have already been fetched
//   getParts(/*{ expectInjectedIfDoc }: { expectInjectedIfDoc: boolean }*/): QueryObservable<EntityPartData, EntityPart> {
//     // If we have a doc, the doc should be injected
//     // otherwise we've goofed something in the loading
//     // if (expectInjectedIfDoc && this.isLoaded && !this._parts.isInjected && !this._parts.isLoaded) {
//     //   throw 'We are expecting that the parts observable was either injected OR LazyInjected';
//     // }
//     if (!this.parts.isInjected) {
//       // throw "_parts must be injected";
//     }

//     return this.parts;
//   }

//   // notifyPartWasSaved(part: EntityPart) {
//   //   this.parts.notifyWasSaved(part);
//   // }

//   // pretty prints parts
//   diagParts(tier = 0, postfix?: string): string {
//     let ident = this.prettyId();
//     let out = `${'\t'.repeat(tier)}${ident}${postfix ? ` [${postfix}] ` : ''}\n`;
//     const idLabelMap: Record<
//       string,
//       {
//         reference: OldBaseClass;
//         roles: Set<string>;
//       }
//     > = {};

//     this.parts.map((part) => {
//       if (part instanceof ClaimPart) {
//         const { target: reference, role, status } = part;
//         if (reference && reference !== this && status === 'active') {
//           const id = reference.prettyId();
//           const joinedRoles = `(${role.join(', ')})`;
//           if (!idLabelMap[id]) {
//             idLabelMap[id] = { reference, roles: new Set([joinedRoles]) };
//           } else {
//             idLabelMap[id].roles.add(joinedRoles);
//           }
//         }
//       } else if (part instanceof ArtifactPart) {
//         // do we wanna do anything here?
//       }
//     });

//     return (
//       out +
//       Object.values(idLabelMap)
//         .map(({ reference, roles }) => reference.diagParts(tier + 1, Array.from(roles).join(', ')))
//         .join('\n')
//     );
//   }
//   // pretty prints backrefs
//   async diag(load: boolean, tier = 0, postfix?: string): Promise<string> {

//     if (load) {
//       await this.awaitLookup()
//       await this.backrefs.awaitLoad()
//     }
//     // return 'TODO: Recurse and build a simple diagnostic readout of the parts. Remember to shorten the IDs, and omit the whole object';

//     // should print to the console something like:
//     // root
//     //   ID04 (head,item)
//     //   B (item,prev(A),tail)

//     let ident = this.prettyId(true);
//     let out = `${'\t'.repeat(tier)}${ident}${postfix ? ` [${postfix}] ` : ''}\n`;
//     const idLabelMap: Record<
//       string,
//       {
//         entity: OldBaseClass;
//         roles: Set<string>;
//       }
//     > = {};
//     this.backrefs.map((backref) => {
//       const { entity, role, status } = backref;
//       const id = backref.id?.substr(0, 4)
//       if (entity && entity !== this && status === 'active') {
//         const entityId = entity.prettyId();
//         const joinedRoles = `(${id}:${role.join(', ')})`;
//         if (!idLabelMap[entityId]) {
//           idLabelMap[entityId] = { entity, roles: new Set([joinedRoles]) };
//         } else {
//           idLabelMap[entityId].roles.add(joinedRoles);
//         }
//       }
//     });

//     let entity_diags = Object.values(idLabelMap).map(({ entity, roles }) => entity.diag(load, tier + 1, Array.from(roles).join(', ')));

//     return (
//       out +
//       (await Promise.all(entity_diags)).join('\n')
//     )
//   }
// }
