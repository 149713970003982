import { Quest, intersects, QueryObservable } from '@edvoapp/plm-common'
import { useEffect, useState } from 'preact/hooks'
import { RenderContext, Renderer } from './renderer/renderer'

export interface QuestComponentProps {
  quest: Quest
  renderer: Renderer
  renderContext: RenderContext
}

export function QuestComponent({
  quest,
  renderer,
  renderContext,
}: QuestComponentProps) {
  return (
    <div id={'quest__' + quest.key()} className="quest-component">
      {renderer.useRenderChildren(quest, renderContext)}
    </div>
  )
}
