import cx from 'classnames'
import { ComponentChildren } from 'preact'
import EventNav from '../../service/EventNav'
import './viewer.scss'

export interface ViewerProps {
  children: ComponentChildren
  keyNav: EventNav
  className?: string
}

export const Viewer = ({ children, keyNav, className }: ViewerProps) => {
  return (
    <div
      className={cx('viewer', className)}
      ref={(r: HTMLDivElement | null) => {
        keyNav.bindEventContainer(r)
      }}
    >
      {children}
    </div>
  )
}
