import { Claim, track, trxWrap, useObservable } from '@edvoapp/plm-common';
import {
  ArtifactComponent,
  EventNav,
  FormTextarea,
  FormTextareaProps,
  NodeMutator,
  StandardRenderer,
  Viewer,
  ViewerProps,
} from '@edvoapp/plm-ui';
import cx from 'classnames';
import { FunctionComponent, h, RefObject } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { usePhantom } from '../../hooks/usePhantom';
import { useOverlay, useScroll } from '../../providers';
import { publishMessageToOuter } from '../../services/pubsub';
import './styles.scss';

interface HighlightInputProps { }

export const HighlightInput: FunctionComponent<HighlightInputProps> = () => {
  const [divRef] = usePhantom<HTMLDivElement>('HIGHLIGHT_TEXTAREA_PARENT');
  const [textareaRef] = usePhantom<HTMLTextAreaElement>('HIGHLIGHT_TEXTAREA');

  const { scroll } = useScroll();
  const [divContainerDimensions, setDivContainerDimensions] = useState({ top: 0, left: 0 });

  // ANCHOR: highlight logic
  const [annotationText, setAnnotationText] = useState('');
  const { overlayManager } = useOverlay();
  const { activeHighlight } = overlayManager;
  useObservable(activeHighlight);
  const activeHl = activeHighlight.getValue();

  useEffect(() => {
    const contentAreaIndex = activeHl?.renderContext.getValue()?.contentAreaIndex;
    if (contentAreaIndex !== undefined) {
      const contentArea = overlayManager.getRegionByIndex(contentAreaIndex);
      setMaxWidth(Math.min(600, contentArea.contentRegion.getBoundingClientRect().width));
    }
  }, [activeHl, overlayManager]);

  const saveHighlight = useCallback(async () => {
    if (!annotationText || !activeHl) return;

    await trxWrap(async trx => {
      const newHighlightClaim = Claim.create({ trx });

      const highlightArtifact = await activeHl?.artifact.get(); // this is where we create the highlight artifact
      if (highlightArtifact) {
        await newHighlightClaim.attachMemberOf(trx, highlightArtifact, 'response-topic');
        await newHighlightClaim.setBodyText(trx, annotationText);
        setAnnotationText('');
        // dedupe
        // this will signal to the outer that the highlight was saved, and so should not be removed on blur.
        track('ext_highlight_new')
        publishMessageToOuter('HIGHLIGHT_SAVED_SUCCESS', {
          highlightAttributes: highlightArtifact.attributes,
        });
      }
    });
  }, [annotationText, activeHl]);

  // ANCHOR: rest
  const [maxWidth, setMaxWidth] = useState(0);

  const textareaFocus = useCallback(() => {
    publishMessageToOuter('INNER_FOCUS');
    // need the setTimeout to handle a concurrency issue with the outer handling the inner window focus
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 2);
  }, [textareaRef]);

  useEffect(() => {
    if (!activeHl) {
      return;
    }
    textareaFocus();
    const renderContext = activeHl?.renderContext.getValue();
    if (!renderContext) return;
    const { rect, contentAreaIndex } = renderContext;
    const region = overlayManager.getRegionByIndex(contentAreaIndex);
    const { contentRegion } = region;
    const { bottom } = rect;
    const contentRect = contentRegion.getBoundingClientRect();
    // center it
    const left = contentRect.left - scrollX + (contentRect.width - maxWidth) / 2;
    setDivContainerDimensions({
      left,
      top: bottom - scroll.y,
    });
  }, [setDivContainerDimensions, textareaFocus, scroll, overlayManager, activeHl, maxWidth]);

  const style = useMemo(() => {
    const y = divContainerDimensions.top;

    return {
      transform: `translate3d(0px, ${y}px, 0)`,
      left: divContainerDimensions.left,
      maxWidth,
      width: '100%',
    };
  }, [divContainerDimensions, maxWidth]);

  const [evtNav] = useState(() => new EventNav(new NodeMutator('response-topic')));
  const [renderer] = useState(() => new StandardRenderer('response-topic', true, 'artifact'));
  let viewer;
  if (evtNav) {
    const artifact = activeHl?.artifact.peek();
    viewer = (
      <Viewer keyNav={evtNav} className="threadviewer">
        {artifact ? (
          <ArtifactComponent
            artifact={artifact}
            renderContext={renderer.baseContext(evtNav)}
            renderer={renderer}
            omitBody
          />
        ) : null}
      </Viewer>
    );
  }

  return (
    <div ref={divRef} style={style} className={cx('highlight_input', 'border', activeHl ? 'active' : 'inactive')}>
      {viewer}
      {h(FormTextarea as FunctionComponent<FormTextareaProps & { ref: RefObject<HTMLTextAreaElement> }>, {
        ref: textareaRef,
        formCx: cx('w-full h-full', activeHl ? 'active' : 'inactive'),
        value: annotationText,
        autofocus: false,
        onInput: e => setAnnotationText(e.currentTarget.value),
        onSubmit: saveHighlight,
        placeholder: 'Make a note on this...',
      })}
    </div>
  );
};
