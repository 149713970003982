import { config, Quest, stringify, track, useObservableValue } from '@edvoapp/plm-common';
import { ContextBar, ContextBarProps } from '@edvoapp/plm-ui';
import cx from 'classnames';
import { FunctionalComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { usePhantom } from '../hooks/usePhantom';
import {
  AuthContextProvider,
  ModeProvider,
  OverlayProvider,
  ScrollProvider,
  PageProvider,
  usePageArtifact,
  NavigatorProvider,
  useAuth,
  useMode,
  useNavigator,
} from '../providers';
import { publishMessageToOuter, usePubSub } from '../services/pubsub';
import { HighlightInput } from './highlight-input/highlight-input';
import { HighlightNotes } from './highlight-notes/highlight-notes';
import { Sidebar } from './sidebar/sidebar';
import './app.scss';
import { useQuestManager } from '../services/quest-manager';

const Providers: FunctionalComponent = ({ children }) => {
  usePubSub();
  return (
    <>
      <ScrollProvider>
        <NavigatorProvider>
          <ModeProvider>
            <OverlayProvider>
              <AuthContextProvider>
                <PageProvider>{children}</PageProvider>
              </AuthContextProvider>
            </OverlayProvider>
          </ModeProvider>
        </NavigatorProvider>
      </ScrollProvider>
      {h(ToastContainer as FunctionalComponent<ToastContainerProps>, null)}
    </>
  );
};

function CtxBar() {
  const [innerRef] = usePhantom<HTMLDivElement>('CONTEXT_BAR');
  const { mode } = useMode();
  const contextBarOpen = mode === 'ACTIVE';
  // const { activeQuest, createQuest, activateQuest } = useActiveQuest();
  const questManager = useQuestManager();
  const activeQuest = useObservableValue(questManager.activeQuest);
  const { pageArtifact } = usePageArtifact();
  const { publishMessageToExtension } = useNavigator();

  const goHome = useCallback(() => {
    const WEBAPP_URL = config.webappUrl;
    const params: Record<string, string> = {};
    const questID = activeQuest?.id();
    const artifactID = pageArtifact?.id();
    if (questID) {
      params.questID = questID;
    }
    if (artifactID) {
      params.artifactID = artifactID;
    }

    const stringified = stringify(params);
    const url = `${WEBAPP_URL}${stringified ? `?${stringified}` : ''}`;
    track(`ext_goto_webapp_${contextBarOpen ? 'open' : 'closed'}`);
    publishMessageToExtension(
      'OPEN_IN_WEBAPP',
      { url },
      () => {},
      () => {
        if (typeof window !== 'undefined' && pageArtifact) {
          const win = window.open(url, '_blank');
          win?.focus();
          publishMessageToOuter('CLOSE_WINDOW');
        }
      },
    );
  }, [activeQuest, pageArtifact, publishMessageToExtension, contextBarOpen]);

  return h(ContextBar as FunctionalComponent<ContextBarProps>, {
    innerRef,
    open: contextBarOpen,
    autoCollapse: false,
    activeQuest: activeQuest || null,
    createQuest: (name: string) => questManager.createQuest(name),
    activateQuest: (quest: Quest) => questManager.activateQuest(quest),
    goHome,
  });
}

const RootApp = () => {
  const { mode } = useMode();
  const { status, currentUser } = useAuth();
  const contextBarHidden = mode === 'INACTIVE';

  // TODO: maybe do a loading screen
  if (status === 'LOADING') return null;

  return (
    <div id="edvo__app-annotator-inner" class={cx('app', mode === 'ACTIVE' && 'sidebar-open')}>
      <HighlightNotes />
      <Sidebar />
      {contextBarHidden || !currentUser ? null : <CtxBar />}
      <HighlightInput />
    </div>
  );
};

const App: FunctionalComponent = () => {
  return (
    <Providers>
      <RootApp />
    </Providers>
  );
};

export default App;
