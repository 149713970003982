import firebase from 'firebase/app';
import {
  Backref,
  BackrefDataDB,
  Claim,
  ClaimPart,
  ClaimPartDataDB,
  doSharedQuery,
  Entity,
  intersects,
  ObservableBase,
  QueryObservable,
  RolesMap,
} from '../internal';
import { LinkedListIterator } from '../utils/linked-list-iterator';
import { AsyncMutex } from './AsyncMutex';
import { ActivatableAsync, AwaitableValue } from './AwaitValue';
import { ClaimDataDB } from './Claim';
import { Referenceable, UnifiedId } from './Entity';
import { FilteredObservableList } from './FilteredObservableList';

type Item = {
  claim: Claim;
  nextList: FilteredObservableList<Backref>;
};

export class ClaimLinkedListObservable extends ObservableBase {
  heads: Set<string> = new Set();
  itemsById: Record<string, Item> = {};
  private _notify_mutex = new AsyncMutex();
  private _await_ops_mutex = new AsyncMutex();
  private _changed = false;
  private _pendingOps: Promise<void>[] = [];
  protected _signalLoaded!: () => void;

  constructor(
    readonly parent: Referenceable,
    readonly roles: RolesMap,
    readonly name: string,
    protected _firebase = firebase.app(),
  ) {
    super();
    this.bindParent();
  }

  // We might not stay "Loaded" if items are getting added which have non-resident "prev" reverse edges
  async awaitLoad() {

    await this._await_ops_mutex.run_locked_async(async () => {
      if (this._pendingOps.length === 0) return;
      // Wait for all pending ops
      while (this._pendingOps.length > 0) {
        const p = this._pendingOps.splice(0, this._pendingOps.length);
        await Promise.all(p);
        // These ops could have queued more pending ops
        // Continue checking until all is quiescent
      }
    })

  }


  notifyIfChanged() {
    // debounce
    this._notify_mutex.run_locked_async(async () => {
      await this.awaitLoad()

      if (this._changed) {
        this.notify();
        this._changed = false;
      }
    });
  }
  bindParent() {
    const { headRole, itemRole } = this.roles;

    this._pendingOps.push(this.parent.backrefs.activate())
    const backrefs = this.parent.backrefs.value();

    // parent backrefs determine membership in the set, but NOT ordering (except heads)
    backrefs.subscribe({
      ITEM_LISTENER: (backref, op) => {
        const role = backref.role.peek_or_throw('Should be hydrated inside ITEM_LISTENER');
        // console.log('CLLO', op, backref.prettyId(), role)

        if (!intersects(role, [headRole, itemRole])) return;

        if (role.includes(headRole)) this._changed = true; // Only head changes matter here. [un]registerNextRelation handles the rest

        if (op === 'ADD') {
          this.bindItem(backref);
        } else if (op === 'REMOVE') {
          const entityId = backref.targetId.peek_or_throw('Should be hydrated inside ITEM_LISTENER').toString();
          const item = this.itemsById[entityId];
          if (item) {
            // TODO - consider making role mutable, and observing that rather than having redundant backrefs
            if (role.includes(headRole)) this.heads.delete(entityId); // Might still be an item
            if (role.includes(itemRole)) {
              this.heads.delete(entityId); // Purge this from heads just in case we get the backref removals in the wrong order
              delete this.itemsById[entityId];
              item.nextList.destroy(); // unbind from item.backrefs
            }
          } else if (role.includes(itemRole)) {
            // This is only weird if it's the item role. We might remove the item role first, and the head second
            console.warn(`CLLO(${this.name}): Cannot find item to remove`, entityId);
          }
        }
      },
      CHANGE: () => {
        this.notifyIfChanged();
      },
    });

    return this.parent.backrefs.activate()
  }
  // This is an item of the parent's collection.
  // It might be a head item, or a non-head item.
  bindItem(backref: Backref) {
    // console.log(`CLLO(${this.name}).bindItem`, backref.prettyId())
    const { headRole, prevRole } = this.roles;
    const role = backref.role.peek_or_throw('Should be hydrated inside ITEM_LISTENER');
    const targetId = backref.targetId.peek_or_throw('should be hydrated').toString();

    this._pendingOps.push(backref.target.get_then((itemClaim) => {
      if (!(itemClaim instanceof Claim)) throw `Not a claim ${this.name}: ${backref.prettyId()}`;

      // Get the Filtered Observable list of backrefs without waiting for it to load
      const nextList: FilteredObservableList<Backref> = itemClaim.reverseEdgeObs([prevRole]);

      // activate the backrefs QO, but don't wait for it here
      this._pendingOps.push(itemClaim.backrefs.activate())
      // if (this.name === 'claim:GYFy.children') console.log(`CLLO(${this.name}).bindItem`, backref.prettyId(), role, '4')
      // console.log(`CLLO(${this.name}).bindItem ${backref.prettyId()} / ${itemClaim.prettyId()} nextList =`, nextList.get().map(part => part.role.peek()?.join(',') + ' -> ' + part.targetId.peek()))

      // Assume that either way we're a member - in case the backrefs are added in a sub-optimal order
      this.itemsById[targetId] = { claim: itemClaim, nextList };
      if (role.includes(headRole)) this.heads.add(targetId.toString());

      nextList.subscribe({
        ITEM_LISTENER: (nextPart, op) => {
          this._changed = true;
        },
        CHANGE: () => this.notifyIfChanged(),
      });
    }))

  }

  async acquireLock(): Promise<Function> {
    return this.parent.backrefs.value().acquireLock();
  }

  async _prefetchChildParts(parent: Claim, claimMap: Record<string, Claim>) {
    await doSharedQuery<Claim, ClaimPartDataDB, ClaimPart>(
      {
        list: Object.values(claimMap),
        propertyName: 'parts',
        collectionName: 'claimPart',
        wheres: [['status', '==', 'active']],
        ctor: (snapshot) => {
          const docRef = snapshot.ref;
          const data = snapshot.data();

          if (!data) throw new Error('Error constructing claim parts');

          // Attaches to the parent claim automatically
          const part = ClaimPart.hydrate({ docRef, data, parent }, this._firebase);
          return part;
        },
      },
      this._firebase,
    );
  }
  async _prefetchChildBackrefs(claimMap: Record<string, Claim>) {
    await doSharedQuery<Claim, BackrefDataDB, Backref>(
      {
        list: Object.values(claimMap),
        propertyName: 'backrefs',
        collectionName: 'claimBackref',
        wheres: [['status', '==', 'active']],
        ctor: (snapshot) => {
          const docRef = snapshot.ref;
          const data = snapshot.data();
          const parent = claimMap[docRef.parent.id];

          // YES we really do have the parent, because we are querying specifically for
          // backrefs contained within this claim's claimBackref collection

          if (!data) throw new Error('No data found when attempting to deserialize the query');
          if (!parent) throw new Error('Backref parent id not present in input claimMap');

          return Backref.hydrate({ docRef, data, parent }, this._firebase);
        },
      },
      this._firebase,
    );
  }

  isEmpty() {
    return this.heads.size === 0;
  }
  map<Ret>(f: (obj: Claim, index: number) => Ret) {
    // DEBUG
    // let tier = 0;
    // if (roles) {
    //   tier = this._my_entity.getTierNumber({ role: roles?.headRole });
    // }
    const iter = this.iter();
    let hopNumber = 0;

    const res: Ret[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const next = iter.next();
      if (!next) break;
      hopNumber += 1;
      res.push(f.call(undefined, next, hopNumber));
    }

    return res;
  }
  iter(): LinkedListIterator {
    return new LinkedListIterator(this);
  }
  async getIter(): Promise<LinkedListIterator> {
    await this.awaitLoad();
    return this.iter();
  }
  getHeads(): Set<Claim> {
    const out: Set<Claim> = new Set();
    this.heads.forEach((headId) => {
      const headItem = this.itemsById[headId];
      if (headItem) {
        out.add(headItem.claim);
      } else {
        console.error(`item ${headId} not registered in collection (1)`);
      }
    });

    return out;
  }
  getNextParts(id: string): Set<Claim> | null {
    const item = this.itemsById[id];
    if (!item) {
      throw 'getNextParts item not in list: ${id}';
    }
    const nexts = item.nextList.get();
    if (nexts.length === 0) return null;

    const out: Set<Claim> = new Set();
    nexts.forEach((edge) => {
      const nextId = edge.targetId.peek_or_throw('Should be hydrated').toString();
      const nextItem = this.itemsById[nextId];
      if (nextItem) {
        out.add(nextItem.claim);
      } else {
        // This is an interesting problem
        // we are using parent.backrefs filtered by category-item to populate the collection
        // but we're using category-prev backref for each item to determine the next item (reverse prev)
        // And it could easily be the case that the latter loads before the former.

        // There's nothing bad about this in theory, especially given that we should notify when the item eventually does get added to the collection
        // EXCEPT that we know there's an inbound change which we can't reflect in the rendered output AND there's nothing to await
        // unless we put a watcher of some kind on this collection which resolves only when it contains edge.targetId
        // debugger
        console.warn(`Next item ${nextId} not present in ${this.parent.prettyId()} CLLO collection (backref ${edge.prettyId()})`);
      }
    });

    return out;
  }
}
