import { Claim, ClaimPartKind, intersects } from '../internal';
import { getCurrentUserID } from '../utils';
import { Backref, BackrefDataDB } from './Backref';
import { Entity, Referenceable } from './Entity';
import { QueryObservable } from './QueryObservable';

export const getBackrefsByRoles = async (obj: Referenceable, role: string[]): Promise<Backref[]> => {
  const backrefs = await obj.backrefs.get();

  return backrefs.filter((backref) => {
    const roleValue = backref.role.peek_or_throw('backrefs should be hydrated');
    return intersects(roleValue, role);
  });
};

export const getBackrefByRoles = async (obj: Referenceable, role: string[]): Promise<Backref | null> => {
  const backrefs = await obj.backrefs.get();
  return (
    backrefs.find((backref) => {
      const roleValue = backref.role.peek_or_throw('backrefs should be hydrated');
      // console.log('backref!', backref);
      // const userIDValue = backref.data.peek_or_throw('backref data should be hydrated').userID;
      // const userID = getCurrentUserID();
      const isRole = intersects(roleValue, role);
      // const isCurrentUser = userIDValue === userID;
      // if (userID) return isCurrentUser && isRole;
      return isRole;
    }) || null
  );
};

export const getReverseEdgeTarget = async (
  obj: Referenceable,
  role: string[],
  matchType?: 'claim' | 'artifact' | 'quest',
): Promise<Claim | null> => {
  const backref = await getBackrefByRoles(obj, role);
  if (!backref) return null;

  if (matchType) {
    const kindValue: ClaimPartKind = await backref.kind.get();
    if (matchType + 'Ref' !== kindValue) return null;
  }
  return await backref.target.get();
};
