/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import firebase from 'firebase/app';

import { DocumentReference, intersects } from '../internal';
import { getSessionManager } from '../services';

// Rename to TrxOp?
type Op<Out> = (trx: Transaction) => Promise<Out> | Out;
type Op2 = (trx: firebase.firestore.Transaction) => Promise<void>;
type ApplyHookFn = () => (void | Promise<void>);

/**
 * Run the supplied Op `fn` in the context of a newly created Transaction.
 *
 * @param fn Op which will be called in the context of a Transaction.
 * @param name optional identifier to be assigned to the internally generated Transaction.
 * @returns whatever `fn` returns, but wrapped in a Promise.
 */
export async function trxWrap<Out>(fn: Op<Out>, name?: string): Promise<Out> {
  const trx = new Transaction(name);
  let out = await fn(trx);
  await trx.apply();
  return out;
}

/**
 * A basket of database-storable data which can have Transactions attached to it.
 */
export interface Transactable {
  getDoc(): Promise<DocumentReference>;
  transactions: Set<Transaction>;
}

// Internal data type used for debug printing.
type LogData = {
  op: string;
  collection: string;
  data: any;
};

// A counter used for assigning unique identifiers to Transactions.
let inc = 0;

/**
 * Model a group of operations on the Edvo datastore as a database transaction.
 */
export class Transaction {
  private batch: firebase.firestore.WriteBatch;
  protected state: 'pending' | 'preparing' | 'failed' | 'applied' | 'committing' | 'committed';
  protected ops: (void | Promise<void>)[] = [];
  protected entities = new Set<Transactable>();
  protected _dataToSet: LogData[] = [];
  protected _name: string;
  protected _post_apply_hooks: ApplyHookFn[] = [];
  protected _post_apply_hooks_uniq: Record<string, ApplyHookFn> = {};

  // TODO: If the trx is pending, there are several lists (parts/backrefs
  // for various claims) which we have changed, but not yet saved to the DB.
  //
  // TODO: Prevent any transaction from being applied which contains a record
  // in common with another transaction that's in progress.  IE Don't have two
  // commits in flight at the same time that share a record - because that's a
  // race condition, and it's not clear which write will win.  See
  // https://github.com/edvoapp/monorepo/pull/79

  /**
   * @param _name optional debugging identifier
   */
  constructor(_name = 'tx') {
    this._name = `${_name}-${inc}`;
    inc++;

    const db = firebase.firestore();
    this.state = 'pending';
    this.batch = db.batch();

    const sessionManager = getSessionManager();
    sessionManager.incrementWrites();
  }

  /**
    * Register a hook which must only be called _once_, even though it may be registered
    * times.
    *
    * The hook will be deferred until after the Transaction has been committed.
    *
    * @param key string identifying the hook
    * @param fn hook
    */
  defer_unique(key: string, fn: ApplyHookFn) {
    if (!['pending', 'preparing'].includes(this.state)) throw "defer_unique can only be used against pending/preparing transactions";
    this._post_apply_hooks_uniq[key] = fn;
  }

  registerUnlocks(unlocks: ApplyHookFn[]) {
    this._post_apply_hooks.push(...unlocks);
  }

  /**
   * Register an Op which will insert `entity` into the datastore.
   *
   * Any keys which are associated with `undefined` values in `data` will be discarded.
   *
   * There must be at least one non-`undefined` value in `data`.
   */
  insert(entity: Transactable, data: any) {
    this.prepareOp(entity, async () => {
      const doc = await entity.getDoc();
      data.id = doc.id;
      const filteredData = Object.fromEntries(
        Object.entries(data).reduce<[string, any][]>((acc, [k, v]) => {
          if (typeof v === 'undefined') {
            console.warn(`Key ${k} had an undefined value, this may not have been intentional`);
          } else {
            acc.push([k, v]);
          }
          return acc;
        }, []),
      );
      this._dataToSet.push({
        op: 'insert',
        collection: doc.parent.id,
        data: filteredData,
      });
      if (!data) throw '';
      this.batch.set(doc, filteredData);
    });
  }

  /**
   * Immediately set the document identified by `ref` to `data`.
   *
   * (Do not register a Op via prepareOp.)
   *
   * @param merge if true, merge in `data` rather than clobber
   */
  setForRef(ref: DocumentReference, data: any, merge = false) {
    this.batch.set(ref, data, { merge });
  }

  /**
   * Register an Op which will set `entity` to the supplied `data`.
   *
   * @param merge if true, merge in `data` rather than clobber
   */
  set(entity: Transactable, data: any, merge = false) {
    this.prepareOp(entity, async () => {
      const doc = await entity.getDoc();
      this._dataToSet.push({
        op: 'set',
        collection: doc.parent.id,
        data,
      });
      if (!data) throw '';
      this.batch.set(doc, data, { merge });
    });
  }

  /**
   * Register an Op which will update `entity` by merging in the supplied `data`.
   */
  update(entity: Transactable, data: any) {
    this.prepareOp(entity, async () => {
      const doc = await entity.getDoc();
      this._dataToSet.push({
        op: 'update',
        collection: doc.parent.id,
        data,
      });
      this.batch.set(doc, data, { merge: true });
    });
  }

  /**
   * Register an Op which will delete `entity`.
   */
  delete(entity: Transactable) {
    this.prepareOp(entity, async () => {
      const doc = await entity.getDoc();
      this._dataToSet.push({
        op: 'delete',
        collection: doc.parent.id,
        data: null,
      });
      this.batch.delete(doc);
    });
  }

  /**
   * Add an Op to the queue, which will be processed by apply().
   *
   * prepareOp is intended to allow application specific code to perform prepratory
   * steps within the context of a transaction, but before that transaction is
   * committed. Therefore it is essential to avoid commiting, or awaiting any output
   * of the transaction itself within any prepareOp.
   *
   * prepareOp may only be called when the Transaction is pending or preparing.
   */
  prepareOp(entity: Transactable, fn: Op<void>) {
    if (!intersects(['pending', 'preparing'], [this.state])) {
      console.error(
        'Failed to prepare transaction',
        this.state,
        this._dataToSet.map((i) => createLogStr(i)),
      );
      throw 'you can only add operations to a transaction that is pending or preparing';
    }
    entity.transactions.add(this);
    this.entities.add(entity);
    this.ops.push(fn(this));
  }

  /**
   * Process the Transaction.
   *
   * Process all Ops which have been added via prepareOps() calls (both explicit and
   * nested/recursive).  Commit when all of them are resolved.  Finally, invoke
   * deferred hooks.
   */
  async apply() {
    const sessionManager = getSessionManager()

    if (this.state !== 'pending') throw 'you can only apply a transaction that is pending';
    this.state = 'preparing';

    // Steps:

    // 1. Await all local ops ( which add to the batch )
    let opBatch: (void | Promise<void>)[] = [];
    const getPrepareOps = () => {
      opBatch = this.ops;
      this.ops = [];
      return opBatch.length > 0;
    };

    // Process ops until we are quiescent
    while (getPrepareOps()) {
      await Promise.all(opBatch);
    }
    this.state = 'committing';
    // 2. Commit the batch

    const commitOps: (void | Promise<void>)[] = [this.batch.commit().then(() => {
      sessionManager.decrementWrites()
      this.state = 'committed';
    })];


    commitOps.push(...this._post_apply_hooks.map(fn => fn()))
    commitOps.push(...Object.values(this._post_apply_hooks_uniq).map(fn => fn()))

    await Promise.all(commitOps)
  }
}

// Debugging print aid.
function createLogStr({ op, collection, data }: LogData): string {
  return `${collection}.${op} id:${data.id ? data.id.substr(0, 4) : 'no-id'}, payload:${data.payload}`;
}
