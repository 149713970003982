// single source of truth for all modules to prevent circular dependencies
export * from './analytics';
export * from './utils/firebase';
export * from './utils/tools';
export * from './utils/config';
export * from './utils/cookie';
export * from './utils/local-storage';
export * from './utils/linked-list-iterator';
export * from './utils/querystring';
// export * from './utils/shadow-iterator';
// DONT MESS WITH THE ORDERING
// TODO: figure out how to make it so we can mess with the ordering
export * from './entities/Observable';
export * from './entities/AwaitValue';
export * from './entities/Entity';
export * from './entities/ArtifactPart';
export * from './entities/ClaimPart';
export * from './entities/ObservableList';
export * from './entities/QueryObservable';
export * from './entities/Backref';
export * from './entities/Claim';
export * from './entities/Artifact';
export * from './entities/EntitySet';
export * from './entities/CommonQuery';
export * from './entities/Quest';
export * from './entities/ClaimLinkedListObservable';
export * from './entities/Transaction';
export * from './entities/Injections';
export * from './paintable-regions/api';
export * from './paintable-regions';
export * from './helpers/entity-helpers';
export * from './entities/Traversal';
export { getSessionManager as useSessionManager } from './services/session-manager';

export const OFFLINE_MODE = false;
export const DEBUG = false;
