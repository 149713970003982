import { Artifact, AwaitableValue, LazyGetterAsync, NowValue, Observable, trxWrap } from '@edvoapp/plm-common';

export interface HighlightParams {
  selectorSet: string;
  parentArtifact: Artifact;
  text: string;
  leadingText: string;
  trailingText: string;
}

export interface HighlightRenderContext {
  rect: DOMRectReadOnly;
  highlightIndex: number;
  contentAreaIndex: number;
}

export interface HighlightConstructorArgs {
  artifact?: Artifact;
  params?: HighlightParams;
  renderContext?: HighlightRenderContext;
}

export class Highlight {
  readonly key: string = Math.random()
    .toString()
    .substring(2);
  artifact: AwaitableValue<Artifact>;
  readonly renderContext: Observable<HighlightRenderContext | null>;

  constructor(args: HighlightConstructorArgs) {
    this.renderContext = new Observable<HighlightRenderContext | null>(args.renderContext || null);

    if (args.artifact) {
      // artifact already exists and is being loaded from the DB
      this.artifact = new NowValue(args.artifact);
    } else if (args.params) {
      // new artifact
      this.artifact = this.makeLazyArtifact(args.params);
    } else {
      throw 'must provide artifact OR params';
    }
  }

  public setRenderContext(ctx: HighlightRenderContext) {
    this.renderContext.setValue(ctx);
  }

  private makeLazyArtifact(params: HighlightParams): LazyGetterAsync<Artifact> {
    const { selectorSet, text, leadingText, trailingText, parentArtifact } = params;
    return new LazyGetterAsync(async () => {
      return trxWrap(trx => {
        const artifact = Artifact.create({
          trx,
          parent: parentArtifact,
          attributes: { highlightSelectorSet: selectorSet },
          kind: 'highlight',
        });

        artifact.createEdge({
          trx,
          role: ['highlight_text'],
          content: text,
          contentType: 'text/plain',
        });

        if (leadingText.length)
          artifact.createEdge({
            trx,
            role: ['highlight_leading_text'],
            content: leadingText,
            contentType: 'text/plain',
          });
        if (trailingText.length)
          artifact.createEdge({
            trx,
            role: ['highlight_trailing_text'],
            content: trailingText,
            contentType: 'text/plain',
          });

        return artifact;
      });
    });
  }
}
