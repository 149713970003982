import {
  applyInjections,
  Claim,
  config,
  track,
  trxWrap,
  useAwaitable,
  useObservable,
} from '@edvoapp/plm-common'
import cx from 'classnames'
import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'
import { MenuItem, useActionMenu } from '../../hooks/useActionMenu'
import useDebounce from '../../hooks/useDebounce'
import { TextareaComponent } from './textarea-component'

export interface BodyComponentProps {
  claim: Claim
  readonly?: boolean
  rootType: 'quest' | 'artifact'
  // bodyRef: RefObject<HTMLTextAreaElement>
}

export const BodyComponent: FunctionComponent<BodyComponentProps> = ({
  claim,
  readonly,
  rootType,
}) => {
  const data = useAwaitable(claim.data)
  const bodyTextObs = claim.getBodyTextObs()
  useObservable(bodyTextObs)
  const bodyText = bodyTextObs.getValue()

  const [unsavedText, setUnsavedText] = useState<string | null>(null)

  const actionMenuItems: MenuItem[] = [
    {
      id: 'addMentalModel',
      label: 'Add Mental Model:',
      tier: 0,
    },
    {
      id: 'comprehension',
      label: 'Comprehension',
      tier: 1,
      handleSelect: async () => {
        const entity = await claim.getEdgeTarget(['category-item'])
        if (entity) {
          await trxWrap(async (trx) => {
            await claim.departAndCleanupNeighbors(trx, 'category')
            claim.archive(trx)
          })
          await applyInjections({
            entity,
            renderMode: 'category',
            blank: true,
            injections: [
              {
                claim: Claim.getById({ id: 't4xXrTsWhI6Wv4vHv9dw' }),
                // behaves like an item, but is a "template"
                role: 'category-embed',
              },
            ],
          })
          track(`${rootType}_mm_comprehension`)
        }
      },
    },
  ]

  useDebounce(
    async () => {
      if (unsavedText !== null) {
        if (bodyText === null) {
          // TODO: this might be faulty logic
          const prefix =
            window.location.origin === config.webappUrl ? 'webapp' : 'ext'
          const type = rootType === 'quest' ? 'quest' : 'page'
          track(`${prefix}_${type}_bullet`)
        }

        await trxWrap(async (trx) => {
          // TODO: await quiescence of other saves
          // and then compare await claim.getBodyText to unsavedText
          await claim.setBodyText(trx, unsavedText)
        })
        setUnsavedText(null)
        console.log('NEW BODY TEXT IS', bodyText)
      }
    },
    1000,
    [unsavedText],
  )

  // Don't render the textarea until we have the claim data and the bodyText (null or otherwise) loaded
  const { handleKeyDown, focusIndex, setFocusIndex } = useActionMenu(
    unsavedText || bodyText || '',
    actionMenuItems,
  )
  if (!data || typeof bodyText === 'undefined') return <></>

  const value = unsavedText !== null ? unsavedText : bodyText || ''

  return (
    <>
      <TextareaComponent
        onKeyDown={handleKeyDown}
        onChange={(e) => setUnsavedText(e.currentTarget.value)}
        value={value}
        placeholder={
          // TODO: ideally we would not have this, and data.meta.placeholderText will only be set when we actually want it
          data.meta?.customClass === 'outline-template-empty-text'
            ? 'Start typing'
            : data.meta?.placeholderText || 'Start typing'
        }
        readonly={readonly}
        onBlur={() => setFocusIndex(-1)}
      />
      <div class={cx(['action-menu'], focusIndex > -1 && ['action-menu_open'])}>
        {actionMenuItems.map((menuItem, index) => {
          return (
            <div
              className={cx('action-menu--item', {
                focused: focusIndex === index,
                clickable: !!menuItem.handleSelect,
              })}
              onMouseOver={() => {
                if (menuItem.handleSelect) {
                  setFocusIndex(index)
                }
              }}
              key={menuItem.id}
              style={{
                paddingLeft: 8 + menuItem.tier * 12,
              }}
              onClick={() => menuItem.handleSelect?.()}
            >
              {menuItem.label}
            </div>
          )
        })}
      </div>
    </>
  )
}
