import { createContext, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import { useSubscribeOnMount } from '../services/pubsub';
import { useProvider } from './common';

interface ScrollDims {
  x: number;
  y: number;
}

interface ScrollContextParams {
  scroll: ScrollDims;
  isScrolling: boolean;
}

const ScrollContext = createContext<ScrollContextParams | null>(null);

export const ScrollProvider: FunctionComponent = ({ children }) => {
  const [scroll, setScroll] = useState<ScrollDims>({ x: 0, y: 0 });
  const [isScrolling, setIsScrolling] = useState(false);
  useSubscribeOnMount<ScrollDims>(
    'SCROLL',
    scrollDims => {
      setScroll(scrollDims);
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    },
    [setScroll],
  );
  return <ScrollContext.Provider value={{ scroll, isScrolling }}>{children}</ScrollContext.Provider>;
};

export const useScroll = () => useProvider(ScrollContext, 'scroll');
