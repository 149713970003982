import { init } from '@edvoapp/plm-common';
import 'preact/debug';
import App from './components/app.tsx';
import { env, nodeEnv } from './utils/config';
import './styles/index.scss';

// Initialize the API
// TODO: maybe only do this in dev mode
const useEmulators = nodeEnv === 'development';
console.log('ENV!', env, nodeEnv);
init({ env, useEmulators });

// const extensionId = 'gacclipfgcohhclcidkknbccifkiihip';
// this isn't working right now
// if (chrome && chrome.runtime) {
//   if (chrome.runtime.sendMessage) {
//     chrome.runtime.sendMessage(extensionId, { foo: 'bar' });
//   }
// }

export default App;
