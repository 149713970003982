import { useEffect } from 'preact/hooks'
import useTimeoutFn from './useTimeoutFn'

export type UseDebounceReturn = [() => boolean | null, () => void]

export default function useDebounce(
  fn: Function,
  ms: number = 0,
  deps: ReadonlyArray<unknown> = [],
): UseDebounceReturn {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms)
  useEffect(reset, deps)
  return [isReady, cancel]
}
