export function injectAnalytics() {
  /* eslint-disable */
  // @ts-ignore
  !(function () {
    let analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
    // @ts-ignore
      if (analytics.invoked)
        // @ts-ignore
        window.console && console.error && console.error('Segment snippet included twice.');
      else {
        // @ts-ignore
        analytics.invoked = !0;
        // @ts-ignore
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        // @ts-ignore
        analytics.factory = function (e: any) {
          return function () {
            let t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            // @ts-ignore
            analytics.push(t);
            return analytics;
          };
        };
        // @ts-ignore
        for (let e = 0; e < analytics.methods.length; e++) {
          // @ts-ignore
          let key = analytics.methods[e];
          // @ts-ignore
          analytics[key] = analytics.factory(key);
        }
        // @ts-ignore
        analytics.load = function (key: string, e: any) {
          let t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
          let n = document.getElementsByTagName('script')[0];
          n?.parentNode?.insertBefore(t, n);
          // @ts-ignore
          analytics._loadOptions = e;
        };
        // @ts-ignore
        analytics._writeKey = 'Os9yjq9nYwupXJAHRB3YrYcMJIdyWavq';
        // @ts-ignore
        analytics.SNIPPET_VERSION = '4.13.2';
        // @ts-ignore
        analytics.load('Os9yjq9nYwupXJAHRB3YrYcMJIdyWavq');
        analytics.page();
      }
  })();
  /* eslint-enable */
}
