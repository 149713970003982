import { Navigator } from '@edvoapp/plm-ui';
import { createContext, FunctionComponent } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { useSubscribeOnMount } from '../services/pubsub';
import { useProvider } from './common';

export type NavigatorContextType = {
  navigator: Navigator;
  publishMessageToExtension: (type: string, payload?: unknown, cb?: () => void, fallback?: () => void) => void;
};
export const NavigatorContext = createContext<NavigatorContextType | null>(null);

// TODO: add default build flag to default it to inactive for extension, but active for using the Viewer Proxy
export const NavigatorProvider: FunctionComponent = ({ children }) => {
  const [navigator] = useState(() => new Navigator());

  useSubscribeOnMount<{ id: string }>(
    'NOTIFY/EXTENSION_ID',
    ({ id }) => {
      navigator.setExtensionID(id);
    },
    [navigator],
  );

  const publishMessageToExtension = useCallback(
    (type: string, payload?: unknown, cb = () => { }, fallback = () => { }) => {
      navigator.sendExtensionMessage(type, payload, cb, fallback);
    },
    [navigator],
  );

  return (
    <NavigatorContext.Provider
      value={{
        navigator,
        publishMessageToExtension,
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};

export const NavigatorConsumer = NavigatorContext.Consumer;
export const useNavigator = () => useProvider(NavigatorContext, 'navigator');
