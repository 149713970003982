import firebase from 'firebase/app';
import {
  Artifact,
  Backref,
  BackrefDataDB,
  Claim,
  DocumentReference,
  firebaseNow,
  getCurrentUserID,
  Quest,
  Referenceable,
  Timestamp,
  Transaction,
  UnifiedId,
} from '../internal';
import { AwaitableValue, AwaitValue, LazyGetterAsync, NowValue } from './AwaitValue';
import { Base, BaseConstructorArgs, BaseCreateArgs, BaseDataRecipient, BaseHydrateArgs, BaseMeta } from './Base';
import { Registry } from './Registry';
import canonical_json from 'canonicalize';
import { UnifiedIdStruct } from './Entity';

const OFFLINE_MODE = false;

export type ClaimPartKind =
  | 'artifactRef'
  | 'weakArtifactRef'
  | 'claimRef'
  | 'weakClaimRef'
  | 'questRef'
  | 'weakQuestRef'
  | 'questReference'
  | 'visit'
  | 'saveForLater';

// What's actually stored in the DB
export interface ClaimPartDataDB extends BaseDataRecipient {
  parentID: string;
  role: string[];
  kind: ClaimPartKind;
  ref: UnifiedIdStruct;
  payload: string;
}

interface ClaimPartConstructorArgs extends BaseConstructorArgs<ClaimPartDataDB> {
  docRef: DocumentReference<ClaimPartDataDB>; // Base considers this optional
  data?: ClaimPartDataDB;
  parent: Claim;
  target?: Referenceable;
  backref?: Backref;
  role?: string[];
  kind?: ClaimPartKind;
  backrefPath?: string;
}

export interface ClaimPartCreateArgs extends BaseCreateArgs {
  parent: Claim;
  target: Referenceable;
  role: string[];
  kind: ClaimPartKind;
}

export interface ClaimPartHydrateArgs extends BaseHydrateArgs<ClaimPartDataDB> {
  parent?: Claim;
}

export class ClaimPart extends Base<ClaimPartDataDB> {
  readonly type = 'claimPart';
  readonly backref: AwaitableValue<Backref>;
  readonly role: AwaitableValue<string[]>;
  readonly kind: AwaitableValue<ClaimPartKind>;
  readonly parent: AwaitableValue<Claim>;
  readonly targetId: AwaitableValue<UnifiedId>;
  readonly backrefPath: AwaitableValue<string>;
  readonly target: AwaitableValue<Referenceable>;
  private static registry = new Registry<ClaimPart>();

  private constructor(args: ClaimPartConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);
    ClaimPart.registry.add_or_throw(args.docRef.id, this, 'Attempt to register duplicate ClaimPart');

    this.parent = args.parent ? new NowValue(args.parent) : new AwaitValue(); // TODO: make this Activatable
    this.kind = args.kind ? new NowValue(args.kind) : new AwaitValue();
    this.role = args.role ? new NowValue(args.role) : new AwaitValue();
    this.backrefPath = args.backrefPath ? new NowValue(args.backrefPath) : new AwaitValue();

    this.backref = args.backref ? new NowValue(args.backref) : new AwaitValue();
    // : new LazyGetterAsync(async () => {
    //   const backrefPath = await this.backrefPath.get();
    //   const backrefDocRef = firebase.firestore().doc(backrefPath) as DocumentReference<BackrefDataDB>;
    //   return Backref.get({ docRef: backrefDocRef }, this._firebase);
    // });

    const targetId = args.target?.unifiedId();
    this.targetId = targetId ? new NowValue(targetId) : new AwaitValue();
    this.target = args.target
      ? new NowValue(args.target)
      : new LazyGetterAsync(async () => {
          const { payload } = await this.data.get();
          const { claimID, artifactID, questID } = JSON.parse(payload) as {
            claimID?: string;
            artifactID?: string;
            questID?: string;
          };
          // Consider consolidating this with .targetId rather than reparsing

          if (claimID) return Claim.getById({ id: claimID }, this._firebase);
          if (artifactID) return Artifact.getById({ id: artifactID }, this._firebase);
          if (questID) return Quest.getById({ id: questID }, this._firebase);
          throw 'invalid payload';
        });

    this.constructorFinish();
  }

  static create(args: ClaimPartCreateArgs, _firebase: firebase.app.App = firebase.app()): ClaimPart {
    const { trx, parent, target, role, kind, meta } = args;
    const docRef = parent.docRef().collection('claimPart').doc() as DocumentReference<ClaimPartDataDB>;
    const part = new ClaimPart({ docRef, parent, target, role, kind, meta }, _firebase);

    // Register this with the parent object - only on create
    // The presumption is that rehydrate is being called by QueryObservable.mergeDocs or similar
    // Attach the part to our parent.parts so they don't have to wait to hear it from the server
    // No need to force them to load for this, so use value() rather than get
    parent.parts.value().insert(part, args.trx);

    void trx.prepareOp(part, async () => {
      let targetDocRef = await target.docReference.get();
      // // Make the backref because we need to mirror ourself
      let backref = Backref.create({ trx, role, kind, target: parent, parent: target, edgePath: docRef.path, meta });
      //                                                  ^ backref is inverse ^

      const userID = getCurrentUserID();

      const backrefDocRef = await backref.docReference.get();
      const backrefPath = backrefDocRef.path;
      const data: ClaimPartDataDB = {
        id: docRef.id,
        parentID: parent.id(),
        status: 'active',
        keyID: '',
        cipher: '',
        userID,
        recipientID: [userID],
        createdAt: firebaseNow(),
        updatedAt: firebaseNow(),
        role,
        kind,
        ref: { [`${target.type}ID`]: targetDocRef.id },
        payload: canonical_json({ [`${target.type}ID`]: targetDocRef.id, backrefPath })!,
        meta: args.meta || null,
      };
      // Save it locally
      part.applyData(data);
      // Save it to the DB
      args.trx.insert(part, data);
      // Attach the backref to the part, because we have it handy
      part.backref.set(backref);
      // NOTE! the Backref.create inserts itself into its own parent.backrefs - this is redundant!
      // We want to insert it into the target backrefs because we have it
      // But if the target doesn't have its backrefs set up yet, then we can skip it
      // And the target can load their own backrefs later
      // target.backrefs.value().insert(backref);
    });

    return part;
  }

  static hydrate(args: ClaimPartHydrateArgs, _firebase: firebase.app.App = firebase.app()): ClaimPart {
    let { docRef, parent, data } = args;
    let part = ClaimPart.registry.get(docRef.id);
    if (part) {
      part.applyData(data);
      return part;
    }

    if (!parent) {
      parent = Claim.getById({ id: data.parentID });
    }

    part = new ClaimPart({ docRef, parent, data }, _firebase);
    return part;
  }

  id(): string {
    return this.docReference.peek()!.id;
  }
  applyData(data: ClaimPartDataDB) {
    super.applyData(data);
    if (data.kind) this.kind.set(data.kind);
    if (data.role) this.role.set(data.role);

    if (data.payload) {
      const payload = JSON.parse(data.payload);
      if (payload.backrefPath) this.backrefPath.set(payload.backrefPath);
      const targetId = UnifiedId.fromStruct(payload);
      if (!targetId) throw `invalid payload for ${this.unifiedId() || ''}, (${data.payload})`;
      this.targetId.set(targetId);
    }
  }
  async archive(trx: Transaction) {
    const backrefPath = await this.backrefPath.get();
    this.parent.peek()?.parts.value().remove(this, trx);
    super.archive(trx);

    const backrefDocRef = firebase.firestore().doc(backrefPath) as DocumentReference<BackrefDataDB>;
    // The backref must already exist. We have to fetch it and then archive it
    Backref.archiveDocRef({ trx, docRef: backrefDocRef });
    const target = this.target.peek();
    if (target) {
      target.backrefs.value().removeWhere((backref) => {
        return backref.id() === backrefDocRef.id;
      }, trx);
    } // else we did not add it in the first place
  }

  async diag(load: boolean, tier = 0) {
    if (load) {
      await this.target.get();
    }
    const tab = '\t'.repeat(tier);
    const ident = this.prettyId();
    const claimEntityId = this.parent.peek()?.prettyId() ?? '';
    const roles = this.role.peek()?.join(', ') || '';
    const referentDiag = (await this.target.peek()?.diag(load, tier + 1)) || '';
    return `${tab}${ident} ${claimEntityId} (${roles}) => \n${referentDiag}`;
  }
}

// Parts bin:

// attachToClaim(claim: Claim) {
//   if (this.claim && this.claim !== claim) {
//     throw new Error('Claim part has already been attached to a Claim');
//   }
//   if (this.reference && this.reference === claim) {
//     throw new Error('This part references itself, which will cause an infinite loop');
//   }
//   this.reattachToNewClaim(claim);
// }

// reattachToNewClaim(claim: Claim) {
//   this.claim = claim;
//   if (this.backref) {
//     this.backref.entity = claim;
//   }
// }

// // payload, ID, and createdAt should be immutable; everything else is fair game (though if payload is immutable then one can wonder when updatedAt could ever change)
// async update(partData: Partial<Omit<ClaimPartDataDB, 'payload' | 'id'>>, trx: Transaction) {
//   if (!this.claim) {
//     throw new Error('Cannot update a part with no claim entity');
//   }
//   const entrySeq = Object.entries(partData);
//   entrySeq.forEach(([key, value]) => {
//     if (!value) return;
//     (this as any)[key] = value;
//     if (this.backref) {
//       (this.backref as any)[key] = value;
//     }
//   });
//   if (entrySeq.length !== 0) {
//     this.updatedAt = firebaseNow();
//     partData.updatedAt = this.updatedAt;
//     if (this.backref) {
//       this.backref.updatedAt = this.updatedAt;
//     }
//   }
//   if (this.isCreated && this.claim.isCreated) {
//     // only update in the DB if it is created. If it is not created, then just update locally and the creation of the claim will automatically create this part with the necessary data
//     await this.updateToDb(partData, trx);
//   }
// }

// async updateToDb(partData: Partial<Omit<ClaimPartDataDB, 'payload' | 'id'>>, trx: Transaction) {
//   if (!this.claim) {
//     throw new Error('Cannot update a part with no claim entity');
//   }
//   trx.update(this, partData);
//   if (this.backref) {
//     await this.reference.updateBackref(this.backref, trx);
//   }
// }

// async save(trx: Transaction) {
//   if (this.isCreated) {
//     return;
//   }
//   if (!this.claim) {
//     throw 'Must call attachToClaim before creating';
//   }

//   if (OFFLINE_MODE) return;

//   await this.getId();
//   const referentEntity = this.target;
//   const referentID = await referentEntity.getId();
//   this.isCreated = true;

//   const keyID = '';
//   const cipher = 'cleartext';

//   this.keyID = keyID;
//   this.cipher = cipher;
//   this.createdAt = firebaseNow();
//   this.updatedAt = firebaseNow();
//   this.status = 'active';

//   const parentID = await this.claim.getId();

//   const partData: ClaimPartDataDB = {
//     parentID,
//     userID: this.userID,
//     keyID: this.keyID,
//     cipher: this.cipher,
//     role: this.role,
//     kind: this.kind,
//     createdAt: this.createdAt,
//     updatedAt: this.updatedAt,
//     status: 'active',
//     payload: JSON.stringify({ [`${referentEntity.type}ID`]: referentID }),
//   };

//   // Notify the QueryObservable that we are saved (saving) BEFORE the resultant onSnapshot gets triggered by the below
//   // otherwise we will have a duplicate entry created in the queryobservable set
//   // this.claimEntity.notifyPartWasSaved(this);
//   trx.insert(this, partData);

//   if (this.backref) {
//     await referentEntity.saveBackref(trx, this.backref);
//   }
//   // if (!this.claimEntity.isCreated) {
//   //   await this.claimEntity.save(trx);
//   // }
//   this.localStatus = 'saved';
// }
