import { ClaimDataDB } from '@edvoapp/plm-common'
import { useCallback, useState } from 'preact/hooks'

export interface MenuItem {
  id: string
  label: string
  tier: number
  handleSelect?: Function
}

function wrapMenu(index: number, length: number, direction: -1 | 1): number {
  if (direction === -1) {
    return index <= 0 ? length - 1 : index - 1
  } else if (direction === 1) {
    return index >= length - 1 ? 0 : index + 1
  }
  throw new Error(`Invalid direction: ${direction}`)
}

export function useActionMenu(value: string, menuItems: MenuItem[]) {
  const [focusIndex, setFocusIndex] = useState(-1)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (value === '') {
        switch (event.key) {
          case 'ArrowUp':
          case 'Up':
            if (focusIndex > -1) {
              event.preventDefault()
              event.stopPropagation()
              event.stopImmediatePropagation()
              setFocusIndex((focus) => {
                let next = wrapMenu(focus, menuItems.length, -1)
                let nextItem = menuItems[next]
                while (!nextItem.handleSelect) {
                  next = wrapMenu(next, menuItems.length, -1)
                  nextItem = menuItems[next]
                }
                return next
              })
            }
            break
          case 'ArrowDown':
          case 'Down':
            if (focusIndex > -1) {
              event.preventDefault()
              event.stopPropagation()
              event.stopImmediatePropagation()
              setFocusIndex((focus) => {
                let next = wrapMenu(focus, menuItems.length, 1)
                let nextItem = menuItems[next]
                while (!nextItem.handleSelect) {
                  next = wrapMenu(next, menuItems.length, 1)
                  nextItem = menuItems[next]
                }
                return next
              })
            }
            break
          case 'Enter':
            if (focusIndex > -1) {
              if (focusIndex !== -1) {
                event.preventDefault()
                event.stopPropagation()
                event.stopImmediatePropagation()
                const item = menuItems[focusIndex]
                if (item.handleSelect) {
                  item.handleSelect()
                  setFocusIndex(-1)
                }
              }
            }
            break
          case 'Backspace':
            if (focusIndex > -1) {
              event.preventDefault()
              event.stopPropagation()
              event.stopImmediatePropagation()
            }
            break
          case '/':
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
            // hard coding this for now -- ideally it'd be smart enough
            let next = wrapMenu(0, menuItems.length, 1)
            let nextItem = menuItems[next]
            while (!nextItem.handleSelect) {
              next = wrapMenu(next, menuItems.length, 1)
              nextItem = menuItems[next]
            }
            setFocusIndex(next)
            break
          case 'Escape':
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
            setFocusIndex(-1)
            break
          default:
            break
        }
      }
    },
    [value, focusIndex, menuItems],
  )

  return { handleKeyDown, focusIndex, setFocusIndex }
}
