import { Claim, Entity, Observable, Referenceable } from '@edvoapp/plm-common'
import { RenderContext } from '..'
import { RenderedClaim } from '../components/viewer/claim-component'
import {
  findFirstChildBySelector,
  findNextCousinOnceRemoved,
  findNextSiblingBySelector,
  findParentBySelector,
  findPrevCousinNthRemoved,
  findPreviousSiblingBySelector,
} from '../utils'
import { NodeMutator } from './NodeMutator'

const FOCUSABLE_CLASS = 'focusable'

export interface BindComponentParams {
  entity: Entity
  renderCtx: RenderContext
  isFocused?: Observable<boolean>
}
export interface ElementBinding extends BindComponentParams {
  evtNav: EventNav
}
export interface BoundElement extends HTMLElement {
  boundTo: ElementBinding
}

export class EventNav {
  currentElement: BoundElement | null = null
  currentClaim: Claim | null = null;
  // elementSubscriptions: {[string] } = {};
  // claimMap: Record<string,Element>
  pendingFocusClaim: Claim | null = null;
  constructor(protected mutator: NodeMutator) { }
  focusElement(newElement: HTMLElement | null) {
    const change = this.currentElement !== newElement;
    if (this.currentElement && change) {
      // HACK - we seem to be going into a rendering busyloop without this
      if (this.currentElement.boundTo.entity !== (newElement as BoundElement | null)?.boundTo?.entity) {

        // defocus old current
        console.log('Defocus previous element', this.currentElement);
        this.currentElement.boundTo.isFocused?.setValue(false)
      }
    }

    if (!newElement) {
      this.currentElement = null;
      this.currentClaim = null
      return;
    }
    if (!(newElement as any)?.boundTo) throw 'attempt to focus element with no binding'
    const boundTo = (newElement as BoundElement).boundTo;

    this.currentElement = newElement as BoundElement;
    this.currentClaim = boundTo.entity as Claim;

    this.assertFocus()
  }
  assertFocus() {
    if (!this.currentElement) return;
    const boundTo = this.currentElement.boundTo;
    const focusTarget = (this.currentElement.querySelector('.focus-target') || this.currentElement) as HTMLElement;

    focusTarget.focus();
    if (boundTo.isFocused) boundTo.isFocused.setValue(true)
  }
  focusClaim(claim: Claim) {
    this.pendingFocusClaim = claim;
  }
  isClaimFocused(claim: Claim): boolean {
    if (this.pendingFocusClaim === claim) return true;
    return this.currentElement?.boundTo.entity === claim;
  }
  blur() {
    this.currentElement = null
  }
  // Capture the events from a parent container
  bindEventContainer(element: HTMLDivElement | null) {
    if (element) {
      element.onkeydown = (e: KeyboardEvent) => this.onKeyDown(e)
      element.onmousedown = (e: MouseEvent) => this.onClick(e)
      element.onfocus = (e: FocusEvent) => this.onFocus(e)
    }

    return null
  }
  bindComponent(element: HTMLDivElement | null, bindTo: BindComponentParams) {
    if (!element) return
    let el = (element as any) as BoundElement
    (el.boundTo as ElementBinding) = { ...bindTo, evtNav: this };

    // Waiting to focus something new
    if (this.pendingFocusClaim) {
      // is it me?
      if (this.pendingFocusClaim === bindTo.entity) {
        this.pendingFocusClaim = null;
        this.focusElement(element);
      }
    } else if (this.currentElement === el) {
      // are we already focused, but the element got removed and readded to the dom?
      // debugger
      this.assertFocus()
    } else if (this.currentClaim == bindTo.entity as Claim) {
      this.focusElement(element)
    }
  }
  currentBound(): ElementBinding | null {
    if (!this.currentElement) return null
    const bound = (this.currentElement as any).boundTo as ElementBinding | null
    if (bound) {
      return bound
    }
    return null
  }
  onFocus(e: FocusEvent) {
    if (!e.target) return
    let focusableElement = (e.target as Element).closest('.focusable') as HTMLElement

    if (!focusableElement) return
    this.focusElement(focusableElement)
  }
  onClick(e: MouseEvent) {
    if (!e.target) return
    let focusableElement = (e.target as Element).closest('.focusable') as HTMLElement
    console.log('onClick')
    if (!focusableElement) return

    e.stopPropagation();
    const clickedOnFocusTarget = (e.target as Element)?.classList.contains('focus-target');
    if (clickedOnFocusTarget) {
      console.log('Clicked on focus-target', e.target)
    } else {
      // Only allow the default if they clicked directly on the focus-target element
      e.preventDefault();
    }
    this.focusElement(focusableElement)

  }
  onKeyDown(e: KeyboardEvent) {
    if (!e.target) return
    let keynavElement = (e.target as Element).closest('.focusable') as HTMLElement

    if (!keynavElement) return
    const currentBound = this.currentBound()

    if (!currentBound) return

    const handled = this.handleKey(e, keynavElement, currentBound)
    if (handled) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  handleKey(
    e: KeyboardEvent,
    element: HTMLElement,
    currentBound: ElementBinding,
  ): boolean {
    const nextSibling = findNextSiblingBySelector(
      element,
      `.${FOCUSABLE_CLASS}`,
    ) as BoundElement | null;
    const prevSibling = findPreviousSiblingBySelector(
      element,
      `.${FOCUSABLE_CLASS}`,
    ) as BoundElement | null;
    const parent = findParentBySelector(element, `.${FOCUSABLE_CLASS}`) as BoundElement | null;;
    const firstChild = findFirstChildBySelector(element, `.${FOCUSABLE_CLASS}`) as BoundElement | null;;
    const nextCousinOnceRemoved = findNextCousinOnceRemoved(
      element,
      `.${FOCUSABLE_CLASS}`,
    ) as BoundElement | null;

    const prevCousinNthRemoved = findPrevCousinNthRemoved(
      element,
      `.${FOCUSABLE_CLASS}`,
    ) as BoundElement | null;
    const { key } = e
    const textareaElement = element.querySelector(':scope textarea')
    switch (key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown': {
        /*
        On down-arrow press:
        - Move user focus to the first child of the current claim.
        - If none, then move user focus to the next sibling.
        - If none, then move to the next sibling of the first "ancestor" that has a next sibling.
        */
        let ret = false
        if (
          textareaElement instanceof HTMLTextAreaElement
          //  && textareaElement.selectionStart === textareaElement.value.length && textareaElement.selectionEnd === textareaElement.value.length
        ) {
          ret = true
          if (firstChild) {
            this.focusElement(firstChild)
          } else if (nextSibling) {
            this.focusElement(nextSibling)
          } else if (nextCousinOnceRemoved) {
            this.focusElement(nextCousinOnceRemoved)
          }
        }
        return ret
      }
      case 'Up': // IE/Edge specific value
      case 'ArrowUp': {
        /*
                        On up-arrow press:
                        - Move user focus to the previous sibling's LAST child's last child's last child..... etc
                        - If previous sibling has no children, then move to previous sibling
                        - If there is no previous sibling, then move to "parent".
                        */
        let ret = false
        if (
          textareaElement instanceof HTMLTextAreaElement
          // && textareaElement.selectionStart === 0 && textareaElement.selectionEnd === 0
        ) {
          ret = true
          if (prevCousinNthRemoved) {
            this.focusElement(prevCousinNthRemoved)
          } else if (prevSibling) {
            this.focusElement(prevSibling)
          } else if (parent) {
            this.focusElement(parent)
          }
        }
        return ret
      }
      case 'Left': // IE/Edge specific value
      case 'ArrowLeft': {
        /*
                        On left-arrow press:
                        If the user's selection context is at the beginning, then go to the end of the immediately previous textarea.
                        
                        This may be the "parent" (if this node is the first node of that "parent") or it could be the previous sibling.
                
                        If the user's selection context is NOT at the beginning, simply do the default behavior (e.g., previous character)
                      */
        // Do something for "left arrow" key press.
        let ret = false
        if (
          textareaElement instanceof HTMLTextAreaElement &&
          textareaElement.selectionStart === 0 &&
          textareaElement.selectionEnd === 0
        ) {
          // we are at the beginning of the line, so we should go up
          ret = true
          if (prevCousinNthRemoved) {
            this.focusElement(prevCousinNthRemoved)
          } else if (prevSibling) {
            this.focusElement(prevSibling)
          } else if (parent) {
            this.focusElement(parent)
          }
        }

        return ret
      }
      case 'Right': // IE/Edge specific value
      case 'ArrowRight': {
        /*
                        On right-arrow press:
                        If the user's selection context is at the end of the line, then go to the beginning of the immediately next textarea.
                
                        This may be the next sibling, or if there is no next sibling, then move to the next sibling of the first "ancestor" that has a next sibling.
                
                        If the user's selection context is NOT at the end of the line, simply do the default behavior (e.g., next character)
                        */
        let ret = false
        if (
          textareaElement instanceof HTMLTextAreaElement &&
          textareaElement.selectionStart === textareaElement.value.length &&
          textareaElement.selectionEnd === textareaElement.value.length
        ) {
          // we are at the end of the line, so we should go to the next element "down"
          ret = true
          if (firstChild) {
            this.focusElement(firstChild)
          } else if (nextSibling) {
            this.focusElement(nextSibling)
          } else if (nextCousinOnceRemoved) {
            this.focusElement(nextCousinOnceRemoved)
          }
        }
        // setActiveClaimRef(firstChild as ActiveClaimElement)
        return ret
      }
      case 'Enter': {
        // on enter, we should handle submission
        e.preventDefault()
        void this.mutator.handleEnter(this, currentBound);

        return true
      }
      case 'Tab':
        e.preventDefault()
        e.stopPropagation()
        if (e.shiftKey) {
          // shift-tab
          this.mutator.handleShiftTab(currentBound, parent?.boundTo)
        } else {
          this.mutator.handleTab(this, currentBound, prevSibling?.boundTo)
        }
        return true
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        // Do something for "esc" key press.
        this.blur()
        return true
      case 'Backspace': {
        let ret = false
        if (
          textareaElement instanceof HTMLTextAreaElement &&
          textareaElement.value === ''
        ) {
          e.preventDefault()
          if (firstChild) return true;

          this.mutator.handleDelete(currentBound);

          if (prevCousinNthRemoved) {
            this.focusElement(prevCousinNthRemoved)
          } else if (prevSibling) {
            this.focusElement(prevSibling)
          } else if (parent) {
            this.focusElement(parent)
          }

        }
        return ret
      }
      default:
        return false
    }
  }
}

// TODO2 - determine if this is duplicative of the above handleEnter
// handleKeypress={(evt: any) => {
//     if (evt.key === 'Enter') {
//       evt.preventDefault()
//       trxWrap(async (trx) => {
//         const claim = new Claim({})
//         await claim.attachMemberOf(trx, rootEntity, viewer.mode)
//         await claim.setBodyText(trx, textValue)
//         setTextValue('')
//         await claim.save(trx)
//       })
//     }
//   }}

// TODO2 - check this one as well
// const handleEnter = () => {
//     trxWrap(async (trx) => {
//       const claim = new Claim({})
//       // TODO - implement focus manager and fix the focusing behavior so that emptyClaim is automatically focused
//       // focusManager.focus(emptyClaim)
//       await claim.attachMemberOf(trx, entity, "category")
//       if (renderEmptyComponent) {
//         await claim.setBodyText(trx, textValue)
//         setTextValue('')
//       }
//       await claim.save(trx)
//     })
//   }

export default EventNav
