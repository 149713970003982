import { Artifact, config, Quest } from '@edvoapp/plm-common'
import { useCallback, useEffect, useState } from 'preact/hooks'
export class Navigator {
  // @ts-ignore
  extensionID?: string = window?.chrome?.runtime?.id
  constructor() {
    this.sendExtensionMessage('PING', null)
  }

  async open(artifact: Artifact, activeQuest?: Quest | null): Promise<void> {
    const parts = artifact.parts.value()
    const uriPart = parts.find(
      (part) => part.contentType.peek_or_throw('hydrated') === 'text/x-uri',
    )

    if (uriPart) {
      let uri = uriPart.content.peek_or_throw('hydrated')
      this.sendExtensionMessage(
        'NAVIGATE_NEW_TAB',
        { uri, activeQuestID: await activeQuest?.getId() },
        () => { },
        () => {
          const viewerUrl = `${config.viewerUrl}/${uri}`
          window.open(viewerUrl, '_blank')
        },
      )
    } else {
      // TODO - handle cached pages and PDFs
      alert('Unhandled link')
    }
  }

  setExtensionID(id: string) {
    this.extensionID = id
  }

  sendExtensionMessage(
    type: string,
    payload?: any,
    cb = () => { },
    fallback = () => { },
  ) {
    // no need to do any checks for extensionID here -- if chrome.runtime is undefined, then sendMessage will never be called
    if (this.extensionID) {
      // @ts-ignore
      chrome?.runtime?.sendMessage(this.extensionID, { type, payload }, cb)
    } else {
      fallback()
    }
  }
}

const nav = new Navigator()

// TODO: move event binding to Navigator constructor

function handleNotifyExtensionID(
  event: CustomEvent<{ extensionID: string }>,
) {

  const { extensionID } = event.detail

  console.log('Navigator: Got message from extension', extensionID);
  nav.setExtensionID(extensionID)
}

document.addEventListener('NOTIFY/EXTENSION_ID', ((
  e: CustomEvent<{ extensionID: string }>,
) => handleNotifyExtensionID(e)) as EventListener)

export function useNavigator() {
  return nav
}
