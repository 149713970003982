import autosize from 'autosize'
import { JSX } from 'preact'
import { forwardRef } from 'preact/compat'
import { useEffect, useRef } from 'preact/hooks'

export type TextareaAutosizeProps = JSX.HTMLAttributes<HTMLTextAreaElement>

export const TextareaAutosize = forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>((props, userRef) => {
  const ref = useRef<HTMLTextAreaElement>()
  useEffect(() => {
    autosize((userRef || ref).current)
  }, [userRef])
  return <textarea ref={userRef || ref} rows={1} {...props} />
})

export default TextareaAutosize
