import { Artifact, ArtifactDataDB, QueryObservable, useObservable, useQueryObservable } from '@edvoapp/plm-common';
import { MarginNoteSet } from '@edvoapp/plm-ui';
import cx from 'classnames';
import { h, Fragment, FunctionComponent } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { usePhantom } from '../../hooks/usePhantom';
import { HighlightRenderContext } from '../../model/highlight';
import { useMode, useOverlay, useScroll } from '../../providers';
import './styles.scss';

interface HighlightNotesProps {}
interface HighlightNoteProps {
  highlight: Artifact;
}

interface Dimensions {
  top: number;
  left: number;
  width: number;
}

export const HighlightNoteRenderer: FunctionComponent<HighlightNoteProps> = ({ highlight }) => {
  const { scroll, isScrolling } = useScroll();
  const { modeDebounce } = useMode();
  const [renderContext, setRenderContext] = useState<HighlightRenderContext | null>(null);
  const [divRef, divContainerRect] = usePhantom<HTMLDivElement>(`HIGHLIGHT_NOTE_${highlight.key()}`);
  const [divContainerDimensions, setDivContainerDimensions] = useState<Dimensions | null>(null);
  const [horizontalLineDimensions, setHorizontalLineDimensions] = useState<Dimensions | null>(null);
  const [rendered, setRendered] = useState(false);
  const { overlayManager, updateKey } = useOverlay();
  const { activeHighlight } = overlayManager;
  useObservable(activeHighlight);
  const activeHl = activeHighlight.getValue();
  const [activeHlArtifact, setActiveHlArtifact] = useState<Artifact | null>(null);

  useEffect(() => {
    if (activeHl) {
      void activeHl?.artifact.passive_then(artifact => {
        setActiveHlArtifact(artifact);
      });
    } else {
      setActiveHlArtifact(null);
    }
  }, [activeHl]);

  useEffect(() => {
    const activeHighlightArtifactID = activeHlArtifact?.id();
    const artifactID = highlight.id();
    if (!artifactID || activeHighlightArtifactID === artifactID) return;
    const hl = overlayManager.highlightsByArtifactId[artifactID];
    if (!hl) return;
    const ctx = hl.renderContext.getValue();
    if (!ctx) return;
    setRenderContext(ctx);
    const { rect } = ctx;
    const { bottom } = rect;
    const paintableRegion = overlayManager.getContainingPaintableRegion(hl);
    if (!paintableRegion) return;
    const regionRect = paintableRegion.getBoundingClientRect();
    const containerDims = {
      left: regionRect.left - scroll.x,
      top: bottom - scroll.y,
      width: regionRect.width,
    };
    setDivContainerDimensions(containerDims);
    const lineDims = {
      left: rect.left - scroll.x,
      top: bottom - scroll.y,
      width: regionRect.left - rect.left,
    };
    setHorizontalLineDimensions(lineDims);
    setRendered(true);
  }, [
    setDivContainerDimensions,
    setHorizontalLineDimensions,
    setRendered,
    highlight,
    scroll,
    overlayManager.highlightsByArtifactId,
    overlayManager,
    activeHlArtifact,
    updateKey,
  ]);

  const style = useMemo(() => {
    if (!rendered || !renderContext || !divContainerDimensions) return {};
    const left = divContainerDimensions.left;
    const top = divContainerDimensions.top;

    return {
      left,
      top,
      maxWidth: divContainerDimensions.width,
      transform: `translate3d(0, -100%, 0)`,
    };
  }, [divContainerDimensions, renderContext, rendered]);

  const lineStyle = useMemo(() => {
    if (!rendered || !renderContext || !horizontalLineDimensions) return {};
    const left = horizontalLineDimensions.left;
    const top = horizontalLineDimensions.top;

    return {
      left,
      top,
      width: horizontalLineDimensions.width + divContainerRect.rect.width,
    };
  }, [rendered, renderContext, horizontalLineDimensions, divContainerRect.rect.width]);

  // const [measurer] = useState(() => new DomElementMeasurer())

  const component = h(MarginNoteSet, {
    entity: highlight,
  });

  const updateActiveArtifact = useCallback(() => {
    overlayManager.setActiveHighlightArtifactID(highlight.id() || null);
  }, [highlight, overlayManager]);

  const isActiveHighlight = activeHlArtifact === highlight;

  return (
    <div
      className={cx('highlight_note_container', {
        scrolling: isScrolling || modeDebounce,
        inactive: isActiveHighlight,
        active: !isActiveHighlight,
        rendered,
      })}
      onClick={() => updateActiveArtifact()}
    >
      <div ref={divRef} className={cx('highlight_note', `center-of-gravity-right`)} style={style}>
        {component}
      </div>
      <div
        className={cx('highlight_note__underline')}
        style={{
          backgroundColor: '#FFD12D',
          top: -2,
          left: 0,
          height: 2,
          ...lineStyle,
        }}
      />
    </div>
  );
};

export const HighlightNotes: FunctionComponent<HighlightNotesProps> = () => {
  const { overlayManager } = useOverlay();
  const [highlightArtifacts, setHighlightArtifacts] = useState<QueryObservable<ArtifactDataDB, Artifact> | null>(null);
  useEffect(() => {
    void overlayManager.highlightArtifacts.passive_then(hl => {
      setHighlightArtifacts(hl);
    });
  }, [overlayManager]);
  useQueryObservable(highlightArtifacts);
  return (
    <Fragment>
      {highlightArtifacts?.map(highlight => (
        <HighlightNoteRenderer key={highlight.key()} highlight={highlight} />
      ))}
    </Fragment>
  );
};
