/**
 * Indicate whether all values of one array are present as values
 * in another array.
 */
export function isSubsetOf<T>(subset: T[], superset: T[]) {
  // every value of the subset is included in the superset
  return subset.every((value) => superset.includes(value));
}

/**
 * Return an array with all values common to all input arrays.
 *
 * The order of element in the returned intersection array is unspecified.
 */
export function intersection<T>(...arrs: T[][]) {
  return arrs.reduce((a, b) => a.filter((c) => b.includes(c)));
}

/**
 * Indicate whether at least one common value is present in all
 * supplied arrays.
 */
export function intersects<T>(...arrs: T[][]): boolean {
  return intersection(...arrs).length > 0;
}

/**
 * Return an array containing all unique values present in the
 * supplied array.  The order of elements is unspecified.
 */
export function uniq<T>(vals: T[]): T[] {
  return Array.from(new Set<T>(vals));
}

type ValueIteratee<T> = (value: T) => unknown;

/**
 * Uniquify elements based on some characteristic, such as a property.
 * @param vals The elements to be uniquified.
 * @param iteratee function which extracts the characteristic from an element
 * @returns Array, order of elements is unspecified.
 */
export function uniqBy<T>(vals: T[], iteratee: ValueIteratee<T>): T[] {
  const s = new Set();
  const res = new Set<T>();
  for (let idx = 0; idx < vals.length; idx++) {
    const curr = vals[idx];
    const v = iteratee(curr);
    if (!s.has(v)) {
      s.add(v);
      res.add(curr);
    }
  }
  return Array.from(res);
}

/**
 * Return a duplicate string with the first char upper-cased.
 *
 * An empty string input yields an empty string output.
 */
export function capitalize(str: string) {
  if (str === '') return '';
  return `${str[0].toUpperCase()}${str.substring(1)}`;
}

/**
 * Sleep for at least `ms` milliseconds.
 *
 * Usage:
 *
 * ```sleep(500).then(() => doStuff())```
 *
 * @param ms Milliseconds, default of 1000
 * @returns a Promise
 */
export function sleep(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
