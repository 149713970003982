import {
  Claim,
  getRolesFromRoleBase,
  RoleBase,
  RolesMap,
  trxWrap,
} from '@edvoapp/plm-common'
// import { isShadowRenderContext, ShadowRenderContext } from "../components";
import EventNav, {
  BindComponentParams,
  BoundElement,
  ElementBinding,
} from './EventNav'

// Consider making this an interface or abstract class so we can have multiple types of NodeMutators
export class NodeMutator {
  roles: RolesMap
  constructor(protected roleBase: RoleBase) {
    this.roles = getRolesFromRoleBase(roleBase)
  }
  async handleTab(
    evtNav: EventNav,
    bound: ElementBinding,
    prevSibling: ElementBinding | undefined,
  ): Promise<void> {
    if (!(bound.entity instanceof Claim)) return
    const claim = bound.entity as Claim

    if (!prevSibling) return
    await trxWrap(async (trx) => {
      await claim.attachMemberOf(
        trx,
        prevSibling.entity as Claim,
        this.roleBase,
      )
      // debugger
      // evtNav.focusClaim(claim)
    })
  }

  // this will transition the claim from being a "child" claim of another entity to being a "child" claim of that entity's parent
  // this will also create a "sibling" relationship, so we will need to point this claim to its former "parent" claim with a "prev"
  // relationship. We will also need to repoint whatever pointed to the former "parent" with a "prev" relationship to point to this claim
  async handleShiftTab(
    bound: BindComponentParams,
    parent: ElementBinding | undefined,
  ): Promise<void> {
    if (!(bound.entity instanceof Claim)) return
    const claim = bound.entity as Claim
    if (!parent) return

    const { headRole, itemRole } = this.roles
    await trxWrap(async (trx) => {
      // first, we get the item that this claim's "parent".
      const formerParent = await claim.getEdgeTarget([headRole, itemRole])
      // if no former parent, or if the former parent is an artifact, then don't do anything.;
      if (formerParent instanceof Claim) {
        await claim.attachNextOf(trx, parent.entity as Claim, this.roleBase)
      }
    })
  }

  async handleDelete(bound: BindComponentParams) {
    if (!(bound.entity instanceof Claim)) return false

    const claim = bound.entity

    const { headRole, itemRole, prevRole, allRoles, roleBase } = this.roles
    // const childrenHead = await claim.getReverseEdgeTarget([headRole], "claim")

    // // Won't someone think of the children!?
    // if (childrenHead) return false;

    const prev = await claim.getEdgeTarget([prevRole], 'claim')
    const parent = await claim.getEdgeTarget([headRole, itemRole], 'claim')

    // // If we don't have a previous claim, or don't have a parent claim, then you don't get to archive this
    if (!prev && !parent) return

    await trxWrap(async (trx) => {
      // TODO: should we move this logic over to claim.archive? Should we depart and cleanup neighbors for all roles of the claim?
      await claim.departAndCleanupNeighbors(trx, roleBase)
      await claim.removeEdge(trx, allRoles)
      await claim.archive(trx)
    })
  }

  async handleEnter(
    evtNav: EventNav,
    bound: BindComponentParams,
  ): Promise<void> {
    if (!(bound.entity instanceof Claim)) return

    await trxWrap(async (trx) => {
      let precedent: Claim

      if (!(bound.entity instanceof Claim)) return
      // TODO - ACL: is this MY claim? Am I allowed to edit this?
      // This should be checked independently of shadowed-ness

      // if ((isShadowRenderContext(bound.renderCtx))) {// && bound.renderCtx.shadowParent) {
      //     throw "unimplemented"
      //     // TODO - replace this with precedent = ShadowOnWriteClaim.shadow_recursively_or_noop_if_regular_claim()
      //     // Suggest creating ClaimOid interface for this so it works with regular claims

      //     // Moved contents to ShadowOnWriteCLaim
      // } else {
      precedent = bound.entity
      // }

      // create a new next bullet
      const newChild = Claim.create({ trx })
      await newChild.attachNextOf(trx, precedent, this.roleBase)
      evtNav.focusClaim(newChild)
    })
  }
}
