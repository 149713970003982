import { config, eraseAuthToken, firebase, getAuthToken, identify, User } from '@edvoapp/plm-common';
import axios from 'axios';
import { createContext, FunctionComponent } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useSubscribeOnMount } from '../services/pubsub';
import { useProvider } from './common';
import { useQuestManager } from '../services/quest-manager';
interface AuthContextParams {
  currentUser: User | null;
  status: string;
}

export const AuthContext = createContext<AuthContextParams | null>(null);

export const AuthContextProvider: FunctionComponent = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [status, setStatus] = useState<string>('LOADING');
  const questManager = useQuestManager();

  const checkStatus = useCallback(async () => {
    const token = getAuthToken();
    if (token) {
      const checkStatusRes = await axios
        .post<{
          ok: boolean;
          authToken?: string;
        }>(
          `${config.apiEndpointUrl}/api/auth/checkStatus`,
          { token },
          {
            withCredentials: true,
          },
        )
        .catch(error => {
          setStatus('READY');
          return { data: { ok: false, authToken: undefined } };
        });
      const data = checkStatusRes.data;
      if (data.ok && data.authToken) {
        const auth = firebase.auth();
        console.log('authToken!', data.authToken);
        const { user } = await auth.signInWithCustomToken(data.authToken).catch(err => {
          eraseAuthToken();
          return { user: null };
        });
        setCurrentUser(user);
        if (user) {
          identify(user.uid, {
            email: user.email,
          });
          questManager.init();
        }
      } else {
        setCurrentUser(null);
      }
    } else {
      setCurrentUser(null);
    }
    setStatus('READY');
  }, [setStatus]);

  useSubscribeOnMount('COMMAND/TABS_CHECK_AUTH', () => {
    void checkStatus();
  });

  useEffect(() => {
    void checkStatus();
  }, [checkStatus]);

  console.log('current user', currentUser);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        status,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useProvider(AuthContext, 'auth');
