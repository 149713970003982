import firebase from 'firebase/app';
import {
  Claim,
  ClaimPartKind,
  DocumentReference,
  Entity,
  firebaseNow,
  getCurrentUserID,
  Query,
  QueryObservable,
  Quest,
  Timestamp,
  UnifiedId,
} from '../internal';
import { ActivatableAsync, AwaitableValue, AwaitValue, LazyGetterAsync, NowValue } from './AwaitValue';
import {
  Base,
  BaseArchiveDocRefArgs,
  BaseConstructorArgs,
  BaseCreateArgs,
  BaseDataRecipient,
  BaseGetArgs,
  BaseHydrateArgs,
} from './Base';
import { Referenceable } from './Entity';
import { Registry } from './Registry';

export interface BackrefDataDB extends BaseDataRecipient {
  parentID: string;
  payload: string;
  role: string[];
  kind: ClaimPartKind;
}

interface BackrefConstructorArgs extends BaseConstructorArgs<BackrefDataDB> {
  parent: Referenceable;
  target?: Claim;
  data?: BackrefDataDB;
  role?: string[];
  kind?: ClaimPartKind;
}

export interface BackrefCreateArgs extends BaseCreateArgs {
  role: string[];
  kind: ClaimPartKind;
  target: Claim;
  parent: Referenceable;
  edgePath: string;
}

export interface BackrefHydrateArgs extends BaseHydrateArgs<BackrefDataDB> {
  parent: Referenceable;
}
export interface BackrefGetArgs extends BaseGetArgs<BackrefDataDB> {}

export class Backref extends Base<BackrefDataDB> {
  readonly type = 'backref';
  readonly target: AwaitableValue<Claim>;
  readonly targetId: AwaitableValue<UnifiedId>;
  readonly parent: Entity;
  readonly role: AwaitableValue<string[]>;
  readonly kind: AwaitableValue<ClaimPartKind>;
  private static registry = new Registry<Backref>();

  private constructor(args: BackrefConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);

    this.parent = args.parent;
    this.kind = args.kind ? new NowValue(args.kind) : new AwaitValue();
    this.role = args.role ? new NowValue(args.role) : new AwaitValue();

    const targetId = args.target?.unifiedId();
    this.targetId = targetId ? new NowValue(targetId) : new AwaitValue();
    this.target = args.target
      ? new NowValue(args.target)
      : new LazyGetterAsync(async () => {
          const { payload } = await this.data.get();
          const { claimID, artifactID, questID } = JSON.parse(payload) as {
            claimID?: string;
            artifactID?: string;
            questID?: string;
          };
          // Consider consolidating this with .targetId rather than reparsing
          if (claimID) return Claim.getById({ id: claimID }, this._firebase);
          if (questID) throw 'Quest backref targets not currently supported';
          if (artifactID) throw 'Artifact backref targets not currently supported';
          throw 'invalid payload';
        });

    // Have to do deferred registration, which means no dedup, but that's ok
    this.docReference.passive_then((docRef) => {
      // Might already be registered. have to live with duplicates for anything with an AwaitValue docRef
      Backref.registry.add(docRef.id, this);
    });
    this.constructorFinish();
  }
  static create(args: BackrefCreateArgs, _firebase: firebase.app.App = firebase.app()): Backref {
    const { target, parent, role, kind } = args;

    const backref = new Backref({ target, parent, role, kind }, _firebase);

    // Register this with the parent object - only on create
    // The presumption is that rehydrate is being called by QueryObservable.mergeDocs or similar
    parent.backrefs.value().insert(backref, args.trx);

    void args.trx.prepareOp(backref, async () => {
      // Parallelize
      let [parentDocRef, targetDocRef] = await Promise.all([parent.docReference.get(), target.docReference.get()]);

      const docRef = parentDocRef.collection('claimBackref').doc() as DocumentReference<BackrefDataDB>;
      backref.docReference.set(docRef);
      const userID = getCurrentUserID();
      const claimID = targetDocRef.id;
      const parentID = parentDocRef.id;

      let data: BackrefDataDB = {
        id: docRef.id,
        parentID,
        status: 'active',
        keyID: '',
        cipher: '',
        userID,
        recipientID: [userID],
        createdAt: firebaseNow(),
        updatedAt: firebaseNow(),
        role,
        kind,
        payload: JSON.stringify({ claimID }),
        meta: args.meta || null,
      };
      backref.applyData(data);
      args.trx.insert(backref, data);
    });

    return backref;
  }

  static hydrate(args: BackrefHydrateArgs, _firebase: firebase.app.App = firebase.app()): Backref {
    // NOTE: it is unlikely we will ever query backrefs by something other than ParentId
    // Because everything else will be opaque/encrypted. Such a query would be done via parts instead
    // Thus, hydration should be able to safely require the parent both here and for ClaimPart

    const { docRef, parent, data } = args;
    let backref = Backref.registry.get(docRef.id);
    if (backref) {
      backref.applyData(data);
      return backref;
    }

    backref = new Backref({ docRef, parent, data }, _firebase);
    // Base.constructor should cause onSnapshot to immediately fire, which should applyData so we don't have to
    // TODO - TEST THIS

    return backref;
  }
  // static get(args: BackrefGetArgs, _firebase: firebase.app.App = firebase.app()): Backref {
  //   let backref = Backref.registry.get(args.docRef.id);
  //   if (backref) return backref;

  //   backref = new Backref({ docRef: args.docRef }, _firebase);
  //   return backref;
  // }

  static archiveDocRef({ trx, docRef }: BaseArchiveDocRefArgs<BackrefDataDB>) {
    const backref = Backref.registry.get(docRef.id);
    if (backref) return backref.archive(trx);
    const uid = firebase.auth().currentUser?.uid || 'UNKNOWN';

    trx.setForRef(
      docRef,
      {
        status: 'archived',
        deletedBy: uid,
        deletedAt: firebaseNow(),
      },
      true,
    );
  }

  applyData(data: BackrefDataDB) {
    super.applyData(data);
    if (data.kind) this.kind.set(data.kind);
    if (data.role) this.role.set(data.role);

    if (data.payload) {
      const targetId = UnifiedId.fromStruct(JSON.parse(data.payload));
      if (!targetId) throw `invalid payload for ${this.unifiedId() || ''}`;
      this.targetId.set(targetId);
    }
  }

  async diag(load: boolean, tier = 0): Promise<string> {
    const target = load ? await this.target.get() : this.target.peek();

    const tab = '\t'.repeat(tier);
    const ident = this.prettyId();
    const parentEntityID = this.parent.prettyId() ?? '*';
    const claimEntityId = `claim-${parentEntityID.substr(0, 4)}`;
    const roles = this.role.peek()?.join(', ') || '';
    const referentDiag = (await target?.diag(load, tier + 1)) ?? '';
    return `${tab}${ident} ${claimEntityId} (${roles}) <= \n${referentDiag}`;
  }
}

export function makeActivatableBackrefs(
  entity: Referenceable,
): ActivatableAsync<QueryObservable<BackrefDataDB, Backref>> {
  const backrefs = new QueryObservable<BackrefDataDB, Backref>({
    val: [],
    ctor: (snapshot) => {
      const data = snapshot.data();
      const docRef = snapshot.ref;
      if (!data) throw new Error('No data found when attempting to deserialize the query');
      return Backref.hydrate({ docRef, data, parent: entity }, entity._firebase);
    },
    name: `${entity.prettyId()}-Backref`,
  });

  const activator = async (backrefs: QueryObservable<BackrefDataDB, Backref>) => {
    if (!backrefs.isInjected) {
      const docRef = await entity.docReference.get();
      let query = docRef
        .collection('claimBackref')
        .where('recipientID', 'array-contains-any', [getCurrentUserID(), 'PUBLIC'])
        .where('status', '==', 'active') as Query<BackrefDataDB>;
      backrefs.injectQuery(query);
    }
    backrefs.execute();

    await backrefs.awaitLoad();
  };
  return new ActivatableAsync(backrefs, activator);
}

// parts bin

// toJSON() {
//   const obj: BackrefData = {
//     id: this.id,
//     userID: this.userID,
//     keyID: this.keyID,
//     cipher: this.cipher,
//     role: this.role,
//     kind: this.kind,
//     entity: this.entity,
//     parentEntity: this.parentEntity,
//     status: this.status,
//     createdAt: this.createdAt,
//     updatedAt: this.updatedAt,
//     deletedBy: this.deletedBy,
//     deletedAt: this.deletedAt,
//   };
//   return Object.entries(obj).reduce<any>((acc, [key, val]) => {
//     if (val) acc[key] = val;
//     return acc;
//   }, {});
// }

// // this assumes that any backref with the same roles and entity are equal, may not necessarily be true
// equals(backref: Backref) {
//   const entitySame = this.entity === backref.entity;
//   const rolesSame = intersection(this.role, backref.role).length === this.role.length;
//   return entitySame && rolesSame;
// }
