import firebase from 'firebase/app';
import { Observable } from '../entities/Observable';

export type SessionStatus = 'clean' | 'dirty';

export class SessionManager {
    readonly status: Observable<SessionStatus>;
    private pendingWrites = 0;
    constructor() {
        this.status = new Observable('clean');
    }
    incrementWrites() {
        this.pendingWrites++;
        this.status.setValue('dirty');
    }
    decrementWrites() {
        this.pendingWrites--;

        this.status.setValue(this.pendingWrites === 0 ? 'clean' : 'dirty');
    }
}

let global_sm: SessionManager | null = null;
export const getSessionManager = () => {
  global_sm = global_sm || new SessionManager();
  return global_sm;
};
