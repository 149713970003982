import { SegmentAnalytics } from '@segment/analytics.js-core';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export function track(event: string, properties?: unknown, options?: unknown, fn?: unknown) {
  return window.analytics?.track?.(event, properties, options, fn);
}

export function identify(
  id?: string,
  traits?: unknown,
  options?: SegmentAnalytics.SegmentOpts,
  fn?: Function | SegmentAnalytics.SegmentOpts,
) {
  return window.analytics?.identify?.(id, traits, options, fn);
}
