import { Artifact, Claim, Quest, ResourceAttributes, track, trxWrap, useObservableValue } from '@edvoapp/plm-common';
import { Component, ComponentType, createContext, FunctionComponent, VNode } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { toast } from 'react-toastify';

import { useAuth, useMode } from '.';
import { useSubscribeOnMount } from '../services/pubsub';
import { useProvider } from './common';
import { useOverlay } from './overlay-provider';
import { useQuestManager } from '../services/quest-manager';

type Status = 'LOADING' | 'ERROR' | 'READY';

export interface PageArtifactContext {
  pageArtifact: Artifact | null;
  status: Status;
}

export const PageContext = createContext<PageArtifactContext | null>(null);

export const PageProvider: FunctionComponent = ({ children }) => {
  const [status, setStatus] = useState<Status>('LOADING');
  const [pageArtifact, setPageArtifact] = useState<Artifact | null>(null);
  const { mode } = useMode();
  const questManager = useQuestManager();
  const [existingVisit, setExistingVisit] = useState<Claim | null>(null);
  const { currentUser } = useAuth();
  const [pageAttributes, setPageAttributes] = useState<ResourceAttributes | null>(null);
  const { overlayManager } = useOverlay();
  const activeQuest = useObservableValue(questManager.activeQuest);

  let previousQuest: Quest | null;
  useEffect(() => {
    if (pageArtifact) {
      void pageArtifact.docReference.passive_then(docRef => console.log(`Page artifact: ${docRef.id}`));
    }
    // TODO -- this is being run twice on every page load. I imagine it's because of the active quest being updated?
    if (mode === 'ACTIVE' && pageArtifact && currentUser) {
      void trxWrap(async trx => {
        const visit: Claim = existingVisit || Claim.create({ trx });
        if (!existingVisit) {
          visit.createEdge({ trx, target: pageArtifact, role: ['visit-target'] });
          setExistingVisit(visit);
        }

        if (previousQuest && previousQuest !== activeQuest) {
          // undo!
          await previousQuest.uncacheArtifactVisit(trx, visit);
        }

        if (activeQuest) {
          // we have an active quest or a CHANGED active quest because this is a useEffect
          await visit.replaceEdge({ trx, target: activeQuest, role: ['visit-context'] });
          await activeQuest.cacheArtifactVisit(trx, pageArtifact, visit);
          await pageArtifact.cacheQuestVisit(trx, activeQuest, visit);
          if (existingVisit) {
            toast(`Page linked to ${activeQuest.name.peek_or_throw('Quest name should exist')}`, {
              type: 'info',
              autoClose: 2000,
            });
          }
          previousQuest = activeQuest;
        }
      }, 'visit');
    }
  }, [mode, pageArtifact, activeQuest, existingVisit, currentUser]);

  useEffect(() => {
    if (pageAttributes && currentUser && !pageArtifact) {
      track('ext_article_new', { url: pageAttributes.url });
      void trxWrap(async trx => {
        const artifact = Artifact.upsert({
          trx,
          kind: 'resource',
          parent: null,
          attributes: pageAttributes,
          onCreate: artifact => {
            Object.entries(pageAttributes).forEach(([key, content]) => {
              if (content) {
                const contentType = (() => {
                  switch (key) {
                    case 'url':
                      return 'text/x-uri';
                    case 'img':
                      return 'image';
                    case 'title':
                    case 'highlightSelectorSet':
                    default:
                      return 'text/plain';
                  }
                })();
                void artifact.createEdge({
                  trx,
                  role: ['reference', `${key}Reference`],
                  contentType,
                  content,
                });
              }
            });
          },
        });

        setPageArtifact(artifact);
        await overlayManager.setPageArtifact(artifact);
        setStatus('READY');
      });
    }
  }, [pageAttributes, pageArtifact, currentUser, overlayManager]);

  useSubscribeOnMount<ResourceAttributes>(
    'LOCATION_UPDATE',
    attributes => {
      setPageAttributes(attributes);
    },
    [pageArtifact],
  );

  return (
    <PageContext.Provider
      value={{
        pageArtifact,
        status,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageArtifact = () => useProvider(PageContext, 'pageArtifact');

export function PageArtifactConsumer({ children }: { children: (context: PageArtifactContext) => VNode<any> | null }) {
  return (
    <PageContext.Consumer>
      {context => {
        if (context === null) {
          throw 'PageArtifactConsumer must be used within a PageProvider';
        }
        return children(context);
      }}
    </PageContext.Consumer>
  );
}

interface PageHOCProps {}
interface PageHOCState {
  pageArtifact: Artifact | null;
}

export function wrapPageContext<P extends PageHOCProps>(Comp: ComponentType<P & PageArtifactContext>) {
  return class extends Component<P, PageHOCState> {
    displayName = 'PageContextWrapper';
    render() {
      return <PageArtifactConsumer>{pageContext => <Comp {...this.props} {...pageContext} />}</PageArtifactConsumer>;
    }
  };
}
