import { Referenceable, useObservable } from '@edvoapp/plm-common'
import { JSX } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { ClaimComponent } from '..'
import EventNav from '../../../service/EventNav'
import { RenderContext, Renderer } from './renderer'

export class StandardRenderer extends Renderer {
  static childClass = 'outline'

  baseContext(evtNav: EventNav): RenderContext {
    return {
      evtNav,
      renderTier: 0,
      extraClass: [],
    }
  }

  useRenderChildren(
    entity: Referenceable,
    renderContext: RenderContext,
  ): JSX.Element | null {
    // This flag stays the same for the life of the renderer
    if (!this.recurse) return <></>

    const childClaims = entity.children(this.roles)
    useObservable(childClaims)

    // Make a new render context for this tier
    let childRenderContext: RenderContext = {
      ...renderContext,
      renderTier: (renderContext.renderTier += 1),
      extraClass: [],
    }

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      childClaims.awaitLoad().then(() => {
        setLoaded(true)
      })
    }, [childClaims])

    if (!loaded) return <></>

    let out = childClaims.map((child) => (
      <ClaimComponent
        key={child.key()}
        renderer={this}
        renderContext={childRenderContext}
        claim={child}
        roles={this.roles}
        rootType={this.rootType}
      />
    ))

    if (out.length) {
      return <div className={this.relationClass}>{out}</div>
    } else {
      return <></>
    }
  }
}
