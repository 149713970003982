import {
  Ref,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'preact/hooks'

export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>

export type UseMeasureRef<E extends HTMLElement = HTMLElement> = Ref<E>
export type UseMeasureResult<E extends HTMLElement = HTMLElement> = [
  UseMeasureRef<E>,
  UseMeasureRect,
]

const defaultState: UseMeasureRect = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

interface UseMeasureArgs {
  disableResize?: boolean
  disableMutation?: boolean
  disableTransition?: boolean
}

export const useMeasure = <E extends HTMLElement = HTMLElement>(
  opts: UseMeasureArgs = {},
): UseMeasureResult<E> => {
  const { disableMutation, disableResize, disableTransition } = opts
  const ref = useRef<E>()
  const [rect, setRect] = useState<UseMeasureRect>(defaultState)

  const updateRect = useCallback(
    (el: Element) => {
      const {
        width,
        height,
        top,
        left,
        bottom,
        right,
      } = el.getBoundingClientRect()
      setRect({ width, height, top, left, bottom, right })
    },
    [setRect],
  )

  const resizeObserver = useMemo(
    () =>
      new (window as any).ResizeObserver(([entry]: { target: Element }[]) => {
        if (entry && entry.target) {
          updateRect(entry.target)
        }
      }),
    [],
  )

  const mutationObserver = useMemo(
    () =>
      new window.MutationObserver(([entry]) => {
        if (entry && entry.target) {
          updateRect(entry.target as Element)
        }
      }),
    [],
  )

  useLayoutEffect(() => {
    if (!ref.current) return
    function handleTransitionEnd() {
      updateRect(ref.current)
    }
    if (!disableResize) resizeObserver.observe(ref.current)
    if (!disableMutation)
      mutationObserver.observe(ref.current, { attributes: true })
    if (!disableTransition)
      ref.current.addEventListener('transitionend', handleTransitionEnd)
    return () => {
      if (!disableResize) resizeObserver.disconnect()
      if (!disableMutation) mutationObserver.disconnect()
      if (!disableTransition)
        ref.current.removeEventListener('transitionend', handleTransitionEnd)
    }
  }, [resizeObserver, mutationObserver])

  return [ref, rect]
}

export default useMeasure
