import firebase from 'firebase/app';
import {
  Artifact,
  Backref,
  BackrefDataDB,
  capitalize,
  ClaimLinkedListObservable,
  ClaimPart,
  ClaimPartDataDB,
  ClaimPartKind,
  DocumentReference,
  firebaseNow,
  getCurrentUserID,
  getReverseEdgeTarget,
  intersection,
  intersects,
  isSubsetOf,
  makeActivatableBackrefs,
  Observable,
  Query,
  QueryObservable,
  sleep,
  Timestamp,
  Transaction,
} from '../internal';
import { ArtifactPart } from './ArtifactPart';
import { AsyncMutex } from './AsyncMutex';
import { ActivatableAsync, AwaitableValue, AwaitValue, NowValue } from './AwaitValue';
import {
  Base,
  BaseConstructorArgs,
  BaseCreateArgs,
  BaseDataDB,
  BaseGetArgs,
  BaseGetByIdArgs,
  BaseHydrateArgs,
  BaseMeta,
} from './Base';
import { Entity, Referenceable, UnifiedId } from './Entity';
import { FilteredObservableList } from './FilteredObservableList';
import { Registry } from './Registry';

export interface ClaimDataDB extends BaseDataDB {}

export interface ClaimConstructorArgs extends BaseConstructorArgs<ClaimDataDB> {
  // docRef is mandatory for Claim construction
  docRef: DocumentReference<ClaimDataDB>;
  meta?: BaseMeta;
}

export interface CreateEdgeArgs {
  trx: Transaction;
  role: string[];
  target: Referenceable;
  weak?: boolean;
  meta?: BaseMeta;
}

export class Claim extends Base<ClaimDataDB> {
  readonly type = 'claim';
  readonly backrefs: ActivatableAsync<QueryObservable<BackrefDataDB, Backref>>;
  readonly parts: ActivatableAsync<QueryObservable<ClaimPartDataDB, ClaimPart>>;
  private _childObservables: Record<string, ClaimLinkedListObservable> = {};
  private bodyTextObs?: Observable<string | null | undefined>;

  private static registry = new Registry<Claim>();

  private constructor(args: ClaimConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);
    Claim.registry.add_or_throw(args.docRef.id, this, 'Attempt to register duplicate claim');

    this.parts = makeActivatableClaimParts(this);
    this.backrefs = makeActivatableBackrefs(this);

    this.constructorFinish();
  }

  static create(args: BaseCreateArgs, _firebase: firebase.app.App = firebase.app()): Claim {
    const firestore = _firebase.firestore();
    const docRef = firestore.collection('claim').doc() as DocumentReference<ClaimDataDB>;
    // console.log('Claim.create', docRef.id)
    const userID = getCurrentUserID();

    const data: ClaimDataDB = {
      id: docRef.id,
      userID,
      createdAt: firebaseNow(),
      updatedAt: firebaseNow(),
      status: 'active',
      meta: args.meta || null,
    };

    const claim = new Claim({ docRef, data }, _firebase);
    claim.backrefs.value().setLoaded(true); // They're not executed, but they are "Loaded" because we just created the claim
    claim.parts.value().setLoaded(true); // it's not possible for them to be missing records

    args.trx.insert(claim, data);

    return claim;
  }

  static hydrate(args: BaseHydrateArgs<ClaimDataDB>, _firebase: firebase.app.App = firebase.app()): Claim {
    const { docRef, data } = args;
    let claim = Claim.registry.get(docRef.id);
    if (claim) {
      claim.applyData(data);
      return claim;
    }

    claim = new Claim({ docRef, data }, _firebase);
    return claim;
  }

  static get(args: BaseGetArgs<ClaimDataDB>, _firebase: firebase.app.App = firebase.app()): Claim {
    let claim = Claim.registry.get(args.docRef.id);
    if (claim) return claim;

    claim = new Claim({ docRef: args.docRef }, _firebase);
    return claim;
  }

  static getById(args: BaseGetByIdArgs, _firebase: firebase.app.App = firebase.app()): Claim {
    const firestore = _firebase.firestore();
    const docRef = firestore.collection('claim').doc(args.id) as DocumentReference<ClaimDataDB>;
    return Claim.get({ docRef });
  }

  applyData(data: ClaimDataDB) {
    super.applyData(data);
  }

  docRef(): DocumentReference<ClaimDataDB> {
    // We KNOW we have this because our constructor args mandate docRef
    return this.docReference.peek_or_throw('Claim always has a docRef')!;
  }

  // overload base class function with more restrictive return type
  id(): string {
    return this.docRef().id;
  }
  unifiedId(): UnifiedId {
    return new UnifiedId('claim', this.docRef().id);
  }

  children(roles: RolesMap): ClaimLinkedListObservable {
    const { memberRoles } = roles;
    // probably a better way to do this, but at least this should be correct
    let key = JSON.stringify(memberRoles);
    let obs = (this._childObservables[key] =
      this._childObservables[key] ||
      new ClaimLinkedListObservable(this, roles, `${this.prettyId()}.children`, this._firebase));
    return obs;
  }

  subCollection(key: 'backrefs' | 'parts' | string): ActivatableAsync<QueryObservable<any, any>> {
    if (key === 'backrefs') return this.backrefs;
    if (key === 'parts') return this.parts;
    throw `${key} not supported`;
  }

  // getTierNumber({ role }: { role: string }): number {
  //   let parentref = this.findPartByRoles([role]);
  //   if (parentref && parentref.reference !== this) {
  //     return parentref.reference.getTierNumber({ role }) + 1;
  //   } else {
  //     return 0;
  //   }
  // }

  // this is an OR inclusion, rather than AND
  // findPartsByRoles(role: string[]): ClaimPart[] {
  //   return this.parts.get_sync().filter((part) => {
  //     const partRole = part.role.peek();
  //     // Not loaded = not included
  //     if (!partRole) return false;

  //     if (partRole instanceof Array) {
  //       return intersects(partRole, role);
  //     } else {
  //       console.warn('part ', part.prettyId(), 'has a non-array role:', part.role, '(claim:', part.parent.id, ')');
  //       return role.includes(partRole);
  //     }
  //   });
  // }
  // findPartByRoles(role: string[]): ClaimPart | null {
  //   const parts = this.findPartsByRoles(role);
  //   if (parts.length === 0) return null;
  //   return parts[0];
  // }

  // getRoleReference(role: string[]): Entity | null {
  //   const part = this.findPartByRoles(role);
  //   if (!part) {
  //     return null;
  //   }
  //   // Not loaded = not found
  //   return part.target.peek();
  // }

  // get whatever claim parts we have right now
  partsByRoles(role: string[]): ClaimPart[] {
    const parts = this.parts.value();

    const filtered = parts.filter((part) => {
      if (part.status === 'archived') {
        // This should not happen, but it does
        console.warn(`filtered out archived claimPart from ${this.prettyId()}.parts (${part.prettyId()})`);
        return false;
      }
      const partRole = part.role.peek_or_throw('parts should be hydrated');
      // Not loaded = not included. We probably won't ever hit this, given that this.parts is hydrating
      if (!partRole) return false;

      if (partRole instanceof Array) {
        return intersects(partRole, role);
      } else {
        console.error(
          'part ',
          part.prettyId(),
          'has a non-array role:',
          part.role,
          '(claim:',
          part.parent.peek()?.id(),
          ')',
        );
        return role.length === 1 && role[0] === partRole;
      }
    });

    return filtered;
  }

  async getPartsByRoles(role: string[]): Promise<ClaimPart[]> {
    // console.log(`${this.prettyId()}.getPartsByRoles(${role.join(', ')})`)

    const parts = await this.parts.get();

    const filtered = parts.filter((part) => {
      if (part.status === 'archived') {
        // This should not happen, but it does
        console.warn(`filtered out archived claimPart from ${this.prettyId()}.parts (${part.prettyId()})`);
        return false;
      }
      const partRole = part.role.peek_or_throw('parts should be hydrated');
      // Not loaded = not included. We probably won't ever hit this, given that this.parts is hydrating
      if (!partRole) return false;

      if (partRole instanceof Array) {
        return intersects(partRole, role);
      } else {
        console.error(
          'part ',
          part.prettyId(),
          'has a non-array role:',
          part.role,
          '(claim:',
          part.parent.peek()?.id(),
          ')',
        );
        return role.length === 1 && role[0] === partRole;
      }
    });

    return filtered;
  }

  async getPartByRoles(role: string[]): Promise<ClaimPart | null> {
    const parts = await this.getPartsByRoles(role);
    if (parts.length === 0) return null;
    return parts[0];
  }

  async getEdgeTarget(role: string[], matchType?: 'artifact' | 'claim' | 'quest'): Promise<Referenceable | null> {
    const part = await this.getPartByRoles(role);

    if (!part) {
      return null;
    }
    if (matchType) {
      const kindValue: ClaimPartKind = await part.kind.get();
      if (matchType + 'Ref' !== kindValue) return null;
    }

    const target = await part.target.get();
    // console.log(`${this.prettyId()}.getEdgeTarget(`, role, `) 2`, !!target);
    return target;
  }

  edgeObs(roles: string[], kind?: string, load = true): FilteredObservableList<ClaimPart> {
    if (load) this.parts.activate();
    return new FilteredObservableList(this.parts.value(), (part) => {
      const partRoles = part.role.peek_or_throw('should be hydrated');
      if (kind && kind !== part.kind.peek_or_throw('should be hydrated')) return false;
      return intersects(roles, partRoles);
    });
  }
  async getEdgeObs(roles: string[], kind?: string): Promise<FilteredObservableList<ClaimPart>> {
    const parts = await this.parts.get();
    return new FilteredObservableList(parts, (part) => {
      const partRoles = part.role.peek_or_throw('should be hydrated');
      if (kind && kind !== part.kind.peek_or_throw('should be hydrated')) return false;
      return intersects(roles, partRoles);
    });
  }
  async getReverseEdgeObs(roles: string[]): Promise<FilteredObservableList<Backref>> {
    const backrefs = await this.backrefs.get();
    return new FilteredObservableList(backrefs, (part) => {
      const partRoles = part.role.peek_or_throw('should be hydrated');
      return intersects(roles, partRoles);
    });
  }
  reverseEdgeObs(roles: string[]): FilteredObservableList<Backref> {
    const backrefs = this.backrefs.value();
    return new FilteredObservableList(backrefs, (part) => {
      const partRoles = part.role.peek_or_throw('should be hydrated');
      return intersects(roles, partRoles);
    });
  }
  async getReverseEdgeTarget(role: string[], matchType?: 'claim' | 'artifact' | 'quest'): Promise<Entity | null> {
    return getReverseEdgeTarget(this, role, matchType);
  }

  // TODO: we can add more specificity to the actual entity we want to remove from: detachFrom?: Entity
  async removeEdge(trx: Transaction, role: string[]) {
    // TODO: This may need to be made async eventually
    // first, we need to remove the reference part/backref that correspond to this claim.
    // We can do that by looking through the parts and finding the $role part.
    // console.log(this.prettyId(), '.detach(', role.join(','), ')');
    const parts = await this.getPartsByRoles(role);
    if (parts.length === 0) {
      // not an error
      return;
    }

    await Promise.all(parts.map((part) => part.archive(trx)));
  }

  createEdge({ trx, role, target, weak, meta }: CreateEdgeArgs): ClaimPart {
    if (target === this) {
      debugger;
      console.error(`Cannot attach a claim to itself ${target.prettyId()} vs ${this.prettyId()}`);
      throw 'Cannot attach a claim to itself!';
    }

    const weakRef = `weak${capitalize(target.type)}Ref`;
    const strongRef = `${target.type}Ref`;
    const kind = (weak ? weakRef : strongRef) as ClaimPartKind;
    const part = ClaimPart.create({ trx, role, kind, parent: this, target, meta }, this._firebase);

    return part;
  }

  async replaceEdge({ trx, role, target, weak, meta }: CreateEdgeArgs) {
    await this.removeEdge(trx, role);
    this.createEdge({ trx, role, target, weak, meta });
  }

  async departAndCleanupNeighbors(trx: Transaction, roleBase: RoleBase) {
    let { headRole, itemRole, tailRole, prevRole, allRoles } = getRolesFromRoleBase(roleBase);

    // should not be null if the claim already exists. If it's a newly created claim, then we just want to no-op.
    let formerParent = await this.getEdgeTarget([itemRole]);

    if (!formerParent) {
      return;
    }
    // emptyClaim
    // Should be null if we're the head
    let formerPrev = (await this.getEdgeTarget([prevRole], 'claim')) as Claim | null;
    // Should be null if we're the last
    let formerNext = (await this.getReverseEdgeTarget([prevRole], 'claim')) as Claim | null;
    if (formerPrev instanceof Claim && formerNext instanceof Claim) {
      // I was between two other items. Lets hook them up
      await formerNext.removeEdge(trx, [prevRole]);
      formerNext.createEdge({ trx, target: formerPrev, role: [prevRole] });
    } else if (formerNext) {
      // If there's a next but not a previous, we need to promote it to head
      await formerNext.removeEdge(trx, [prevRole]);
      formerNext.createEdge({ trx, target: formerParent, role: [headRole] });
    } else if (formerPrev) {
      // No next, so promote the prev to tail
      formerPrev.createEdge({ trx, target: formerParent, role: [tailRole] });
    }
    await this.removeEdge(trx, allRoles);
  }

  // attaches THIS claim as a member of the target claim/entity. Target could potentially be the root artifact
  async attachMemberOf(trx: Transaction, target: Referenceable, roleBase: RoleBase) {
    // first we repoint to establish the new "child-parent" reference
    // the prevClaim will become the new "parent" claim.

    // First lets fix up the neighborhood we're leaving
    await this.departAndCleanupNeighbors(trx, roleBase);
    let { tailRole, headRole, itemRole, prevRole } = getRolesFromRoleBase(roleBase);

    let newPrev = await getReverseEdgeTarget(target, [tailRole]);

    // We're definitely the tail, but we might also be the head
    if (newPrev instanceof Claim && newPrev !== this) {
      // There is an existing tail, so lets detach that
      // Also I'm not the head
      await newPrev.removeEdge(trx, [tailRole]);
      // attach that as a prev
      this.createEdge({ trx, target: newPrev, role: [prevRole] });
    } else {
      // No tail means we are ALSO the head
      this.createEdge({ trx, target, role: [headRole] });
    }

    // But we're definitely an item, and we're definitely the tail
    this.createEdge({ trx, target, role: [itemRole] });
    this.createEdge({ trx, target, role: [tailRole] });
  }

  // attaches THIS claim as the "next" for the target claim (in other words, makes the target claim point to this one in a previous capacity)
  async attachNextOf(trx: Transaction, target: Claim, roleBase: RoleBase) {
    await this.departAndCleanupNeighbors(trx, roleBase);
    let { prevRole, tailRole, itemRole } = getRolesFromRoleBase(roleBase);
    // you cant be the next of a category that has no head
    // so we're definitely NOT a head
    // we are going upward, so we use a positive number (ie, search through claim parts)
    let newParent = await target.getEdgeTarget([itemRole]);
    if (!newParent) {
      return;
    }
    let newPrev = target;
    // we are going "backward" (ie, opposite of "prev") so we use a negative number
    let newNext = await getReverseEdgeTarget(target, [prevRole]);

    // TODO: for some reason, when un-indenting, target's newNext ends up being itself.
    if (newNext instanceof Claim && newNext !== this) {
      // I'm NOT the tail
      // First unlink and relink the newNext to us
      await newNext.removeEdge(trx, [prevRole]);
      newNext.createEdge({ trx, target: this, role: [prevRole] });
    } else {
      // I AM the tail
      // That also means that the target WAS the tail
      let oldTail = target;
      await oldTail.removeEdge(trx, [tailRole]);
      this.createEdge({ trx, target: newParent, role: [tailRole] });
    }

    this.createEdge({ trx, target: newParent, role: [itemRole] }); // We're definitely an item of the new parent
    this.createEdge({ trx, target: newPrev, role: [prevRole] }); // We are definitely the next of the newPrev
  }

  async setBodyText(trx: Transaction, text: string) {
    if (text.length > 0) {
      const artifact = Artifact.create({ trx, parent: null, kind: 'text' });
      ArtifactPart.create({
        trx,
        parent: artifact,
        role: ['body'],
        contentType: 'text/plain',
        content: text,
      });
      await this.replaceEdge({ trx, target: artifact, role: ['body'] });
    } else {
      await this.removeEdge(trx, ['body']);
    }
  }

  async getBodyText(): Promise<string | null> {
    const artifact = (await this.getEdgeTarget(['body'], 'artifact')) as Artifact | null;
    if (!artifact) return null;

    const artifactPart = await artifact.getPart(['body'], 'text/plain');
    let body = await artifactPart?.content.get();
    return body || null;
  }
  getBodyTextObs(): Observable<string | null | undefined> {
    if (this.bodyTextObs) return this.bodyTextObs;

    const bodyTextObs: Observable<string | null | undefined> = new Observable(undefined);

    let bodyObs: FilteredObservableList<ClaimPart> = this.edgeObs(['body'], 'artifactRef', true);

    let latestClaimPart: ClaimPart | null = null;

    // It's possible that two claimPart changes could come in quick succession
    // and that the lookup of the first target Artifact / ArtifactPart could
    // finish after the one pointed to by the second claimPart.
    // THus we must insist that they are processed serially
    const updateMutex = new AsyncMutex();

    bodyObs.subscribe({
      ITEM_LISTENER: (part, op) => {
        // TODO - It's possible that even with the updateMutex, latency with the server
        // could cause one claimpart removal to be received AFTER the replacing claimpart
        // addition ObservableList.replace fires ITEM_LISTENER(op=REMOVE, then ADD), then CHANGE

        if (op === 'ADD') {
          latestClaimPart = part;
        } else if (op === 'REMOVE') {
          latestClaimPart = null;
        }
      },
      CHANGE: () => {
        updateMutex.run_locked_async(async () => {
          if (!latestClaimPart) {
            bodyTextObs.setValue(null);
            return;
          }

          await latestClaimPart.target.get_then(async (target) => {
            const artifact = target as Artifact;
            // TODO - make this run without yielding if the value is there already
            const artifactPart = await artifact.getPart(['body'], 'text/plain');
            if (artifactPart) {
              const content = await artifactPart.content.get();
              bodyTextObs.setValue(content);
            } else {
              bodyTextObs.setValue(null);
            }
          });
        });
      },
    });

    this.bodyTextObs = bodyTextObs;
    return bodyTextObs;
  }
  diag(_load: boolean, _tier: number, _postfix?: string): Promise<string> {
    throw 'unimplemented diag for claim';
    // return this._diag(this, load, tier, postfix)
  }
}

function makeActivatableClaimParts(claim: Claim): ActivatableAsync<QueryObservable<ClaimPartDataDB, ClaimPart>> {
  const parts = new QueryObservable<ClaimPartDataDB, ClaimPart>({
    val: [],
    ctor: (snapshot) => {
      const docRef = snapshot.ref;
      const data = snapshot.data();
      if (!data) throw new Error('Error deserializing claimPart ' + docRef.id);
      const part = ClaimPart.hydrate({ docRef, data, parent: claim }, claim._firebase);

      return part;
    },
    name: `${claim.prettyId()}-ClaimPart`,
  });

  const activator = async (parts: QueryObservable<ClaimPartDataDB, ClaimPart>) => {
    if (!parts.isInjected) {
      let partQuery = claim
        .docRef()
        .collection(`claimPart`)
        .where('recipientID', 'array-contains-any', [getCurrentUserID(), 'PUBLIC'])
        .where('status', '==', 'active') as Query<ClaimPartDataDB>;
      parts.injectQuery(partQuery);
    }
    parts.execute();
    await parts.awaitLoad();
  };

  return new ActivatableAsync(parts, activator);
}

export type RoleBase = 'category' | 'response-topic' | 'intention' | 'source';
export type RolesMap = {
  roleBase: RoleBase;
  itemRole: string;
  headRole: string;
  tailRole: string;
  prevRole: string;
  shadowRole: string;
  memberRoles: string[];
  allRoles: string[];
};

export function getRolesFromRoleBase(roleBase: RoleBase): RolesMap {
  let itemRole = roleBase + '-item';
  let headRole = roleBase + '-head';
  let tailRole = roleBase + '-tail';
  let prevRole = roleBase + '-prev';
  let shadowRole = roleBase + '-shadow';
  return {
    roleBase,
    itemRole,
    headRole,
    tailRole,
    prevRole,
    shadowRole,
    memberRoles: [headRole, itemRole, tailRole],
    allRoles: [headRole, tailRole, itemRole, prevRole, shadowRole],
  };
}
