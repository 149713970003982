import { FunctionComponent, h } from 'preact';
import { applyInjections, config, ResourceAttributes } from '@edvoapp/plm-common';
import { ArtifactComponent, EventNav, NodeMutator, StandardRenderer, Viewer, ChevronRight } from '@edvoapp/plm-ui';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { usePhantom } from '../../hooks/usePhantom';
import { useAuth, useMode, useNavigator } from '../../providers';
import { usePageArtifact } from '../../providers/page-provider';
import './styles.scss';

interface Props {}

export const Sidebar: FunctionComponent<Props> = () => {
  // TODO: what what to do when a highlight artifact gets created?
  // do we bind onExist?
  const { updateMode, mode } = useMode();
  const { pageArtifact } = usePageArtifact();
  const [ref] = usePhantom<HTMLDivElement>('SIDEBAR');
  const [title, setTitle] = useState('');
  const open = mode === 'ACTIVE';


  const toggleSidebarOpen = useCallback(() => updateMode(mode === 'BACKGROUND' ? 'ACTIVE' : 'BACKGROUND'), [
    updateMode,
    mode,
  ]);

  useEffect(() => {
    const apply = async () => {
      if (pageArtifact) {
        await applyInjections({
          entity: pageArtifact,
          renderMode: 'category',
          blank: true,
        });
        void pageArtifact.attributes.passive_then(attr => setTitle((attr as ResourceAttributes).title || ''));
      }
    };
    void apply();
  }, [pageArtifact]);

  let header;
  if (pageArtifact) {
    header = (
      <div className={cx('page_title')}>
        MY OUTLINE
        <br />
        <br />
        {title}
      </div>
    );
  }

  let [evtNav] = useState(() => new EventNav(new NodeMutator('category')));
  // TODO: Change this back to ShadowRenderer, and restore lazy-shadowing in ApplyDefaultBehaviors after CowClaim is implemented
  let [renderer] = useState(() => new StandardRenderer('category', true, 'artifact'));

  let viewer;
  if (pageArtifact && evtNav) {
    viewer = (
      <Viewer keyNav={evtNav}>
        <ArtifactComponent
          artifact={pageArtifact}
          renderContext={renderer.baseContext(evtNav)}
          renderer={renderer}
          omitBody={true}
        />
      </Viewer>
    );
  }

  const { currentUser } = useAuth();
  const { publishMessageToExtension } = useNavigator();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const uri = `${config.webappUrl}/auth/register?returnToPreviousTab=true`;
      publishMessageToExtension(
        'NAVIGATE_NEW_TAB',
        { uri, keepOpen: true },
        () => {},
        () => {
          if (typeof window !== 'undefined' && pageArtifact) {
            const win = window.open(uri, '_blank');
            win?.focus();
          }
        },
      );
    },
    [pageArtifact, publishMessageToExtension],
  );

  return (
    <>
      <div ref={ref} className={cx('fixed inset-y-0 left-0 p-4 overflow-auto bg-white', 'sidebar', { open })}>
        {currentUser ? (
          <>
            {header}
            {viewer}
          </>
        ) : (
          <div>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a onClick={handleClick} href="#">
              Click here to create an account or log in.
            </a>
          </div>
        )}
      </div>
      <div
      class={cx('expander', { open })} onClick={toggleSidebarOpen}>
        {h(ChevronRight as FunctionComponent, null)}
      </div>
    </>
  );
};
