import { RegionBase } from './region-base';

export class ContentRegion extends RegionBase {
  type = 'content' as const;
  protected _debugColor = 'rgba(250, 0, 0, 0.1)';
  constructor(
    protected _initialDimensions: DOMRectReadOnly,
    protected _contentAreaIndex: number,
    protected _initialWindowScroll: { x: number; y: number },
    protected _mode: 'INACTIVE' | 'BACKGROUND' | 'ACTIVE',
  ) {
    super(_initialDimensions, _initialWindowScroll, _mode);
  }
}
