import { Artifact, ArtifactPart, useObservable } from '@edvoapp/plm-common'
import { FunctionComponent, h } from 'preact'
import { useMemo } from 'preact/hooks'
import cx from 'classnames'

import './viewer.scss'
import { useNavigator } from '../../service'

interface ArtifactPartComponentProps {
  part: ArtifactPart
}

export const sortingOrder = {
  'text/plain': 1,
  'text/x-uri': 2,
  image: 3,
}

export function compareArtifactParts<
  Obj extends Record<string, keyof typeof sortingOrder | any>
>(key: keyof Obj, order = 'asc') {
  return function (a: Obj, b: Obj) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0

    const first =
      a[key].toLowerCase() in sortingOrder
        ? sortingOrder[a[key]]
        : Number.MAX_SAFE_INTEGER

    const second =
      b[key].toLowerCase() in sortingOrder
        ? sortingOrder[b[key]]
        : Number.MAX_SAFE_INTEGER

    let result = 0
    if (first < second) result = -1
    else if (first > second) result = 1
    return order === 'desc' ? ~result : result
  }
}

export function getArtifactPartText(part?: ArtifactPart): string {
  if (!part) return ''
  const { contentType: awaitableCtype, data: awaitableData } = part
  const contentType = awaitableCtype.peek()
  const data = awaitableData.peek()
  if (!contentType || !data) return ''
  const { payload } = data
  switch (contentType) {
    case 'text/plain':
      return payload
    case 'image':
      // TODO: fix this later
      return payload
    // return <img src={payload} width={400} height={300} />
    case 'text/x-uri':
      return payload
    // return (
    //   <a
    //     onClick={(e) => e.stopPropagation()}
    //     target="_blank"
    //     rel="noopener noreferrer"
    //     href={`${viewerURL}/${payload}`}
    //     style={{ color: 'inherit' }}
    //   >
    //     Navigate
    //   </a>
    // )
    default:
      return ''
  }
}

export const ArtifactPartComponent: FunctionComponent<ArtifactPartComponentProps> = ({
  part,
}) => {
  const navigator = useNavigator()
  const children = useMemo(() => {
    const { contentType: awaitableCtype, data: awaitableData } = part
    const contentType = awaitableCtype.peek()
    const data = awaitableData.peek()
    if (!contentType || !data) return null
    const { payload } = data
    switch (contentType) {
      case 'text/plain':
        return payload
      case 'image':
        // no images for now
        return null
      // return <img src={payload} width={400} height={300} />
      case 'text/x-uri':
        return (
          <a
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              // talk to extension
              void navigator?.open(part.parent)
            }}
            href="#"
            // target="_blank"
            // rel="noopener noreferrer"
            // href={`${viewerURL}/${payload}`}
            style={{ color: 'inherit' }}
          >
            {payload}
          </a>
        )
      default:
        return ''
    }
  }, [part])

  return (
    <div
      id={'artifact_part__' + part.key()}
      className={cx('flex', 'artifact-part')}
    >
      {children}
    </div>
  )
}
