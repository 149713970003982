import { trxWrap } from '../entities/Transaction';
import { Artifact, ArtifactPart, Claim } from '../internal';

/**
 * creates and returns a new highlight artifact. Note that this doesn't save it to the DB -- it just creates it, as there are some circumstances in which we may
 * want to do stuff in between local creation and DB saving. It also doesn't assume what we are doing with this artifact -- perhaps we could expose a way to save
 * a highlight without necessarily annotating against it.
 */
interface CreateHighlightArgs {
  selectorSet: string;
  text: string,
  leadingText: string;
  trailingText: string;
  parentArtifact: Artifact;
}

interface CreateQuickCaptureArgs {
  text: string;
}

/**
 * creates and returns a new quick capture / note. Note that this doesn't save it to the DB -- it just creates it, as there are some circumstances in which we may
 * want to do stuff in between local creation and DB saving
 */
export async function createQuickCapture({ text }: CreateQuickCaptureArgs): Promise<Claim> {
  return trxWrap(async (trx) => {
    // the dummyArtifact is a hack to get this to show up in the search results
    const dummyArtifact = Artifact.create({ trx, parent: null, kind: 'resource' });
    const annotationText = Artifact.create({ trx, parent: null, kind: 'resource' });
    ArtifactPart.create({ trx, parent: annotationText, role: ['body'], contentType: 'text/plain', content: text });

    const claim = Claim.create({ trx });
    claim.createEdge({ trx, target: dummyArtifact, role: ['topic-response'] });
    claim.createEdge({ trx, target: annotationText, role: ['body'] });
    return claim;
  });
}
