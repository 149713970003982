import { h, FunctionComponent, JSX } from 'preact'

export const ChevronRight: FunctionComponent<JSX.SVGAttributes> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.29279 5.29357C7.10532 5.4811 7 5.7354 7 6.00057C7 6.26573 7.10532 6.52004 7.29279 6.70757L10.5858 10.0006L7.29279 13.2936C7.11063 13.4822 7.00983 13.7348 7.01211 13.997C7.01439 14.2592 7.11956 14.51 7.30497 14.6954C7.49038 14.8808 7.74119 14.986 8.00339 14.9882C8.26558 14.9905 8.51818 14.8897 8.70679 14.7076L12.7068 10.7076C12.8943 10.52 12.9996 10.2657 12.9996 10.0006C12.9996 9.7354 12.8943 9.4811 12.7068 9.29357L8.70679 5.29357C8.51926 5.1061 8.26495 5.00078 7.99979 5.00078C7.73462 5.00078 7.48031 5.1061 7.29279 5.29357Z"
      fill="currentColor"
    />
  </svg>
)
