import { Component, ComponentType, h } from 'preact';
import { useEffect, useReducer } from 'preact/hooks';
import { Listener, Unsubscriber } from '../internal';
import { Transaction } from './Transaction';

export type ItemListener<T> = (item: T, type: 'ADD' | 'REMOVE') => void;

export class ObservableList<Obj> {
  private _key?: string;
  protected _listeners: {
    ITEM_LISTENER: ItemListener<Obj>[];
    CHANGE: Listener<Obj>[];
  } = {
      ITEM_LISTENER: [],
      CHANGE: [],
    };
  // _val is the list of items that exist, whether created in the DB or not. Note that the ObservableList class is intended to be agnostic from any DB implementation
  protected _val: Obj[] = [];
  name: string | null;

  constructor(_val: Obj[] = [], name?: string, public onSubscribe?: Function) {
    this.name = name || null;
    // this is for doing notifies, otherwise we could do constructor(protected _val: Obj[] = [])
    _val.forEach((obj) => {
      this.insert(obj);
    });
  }
  isLoaded() {
    // A plain ObservableList is always loaded.
    // IMPORTANT: Subclasses should strongly consider implementing their own isLoaded method
    return true
  }

  key(): string {
    if (this._key) return this._key;
    this._key = this.name + '+' + Math.random().toString().substring(2);
    return this._key;
  }

  protected fireItemListeners(obj: Obj, op: 'ADD' | 'REMOVE') {
    this._listeners.ITEM_LISTENER.forEach((l) => l(obj, op));
  }

  // Fire the change listeners

  protected fireChangeListeners(trx?: Transaction) {
    if (trx) {
      // If a transaction is provided, then defer the change until immediately after it's applied
      // That allows us to debounce events for multiple changes in a single transaction, and also avoid certain race conditions
      trx.defer_unique(this.key(), async () => {
        await Promise.all(this._listeners.CHANGE.map((l) => l()));
      })
    } else {
      // Otherwise fire the listener now
      this._listeners.CHANGE.forEach((l) => l());
    }
  }
  // Fire the change listeners, with detection of when all the listeners (sync or async) are done
  // Transaction not supported for this
  protected async fireChangeListenersAsync(): Promise<void> {
    await Promise.all(this._listeners.CHANGE.map((l) => l()));
  }
  subscribe({ ITEM_LISTENER, CHANGE }: { ITEM_LISTENER?: ItemListener<Obj>; CHANGE?: Listener<Obj> }): Unsubscriber {
    if (this.onSubscribe) this.onSubscribe();

    const isLoaded = this.isLoaded(); // ObservableList is always loaded, but the child classes might not be

    if (ITEM_LISTENER && (isLoaded || (this._val.length > 0))) {
      this._val.forEach((obj) => ITEM_LISTENER(obj, 'ADD'));
    }
    if (CHANGE && (isLoaded || (this._val.length > 0))) CHANGE();

    if (ITEM_LISTENER) this._listeners.ITEM_LISTENER.push(ITEM_LISTENER);
    if (CHANGE) this._listeners.CHANGE.push(CHANGE);

    return () => {
      this._listeners.ITEM_LISTENER = this._listeners.ITEM_LISTENER.filter((l) => l !== ITEM_LISTENER);
      this._listeners.CHANGE = this._listeners.CHANGE.filter((l) => l !== CHANGE);
    };


  }

  get(): Obj[] {
    return this._val;
  }

  get length() {
    return this._val.length;
  }

  idx(i: number) {
    return this._val[i];
  }

  first() {
    return this._val[0];
  }

  hasSubscribers(): boolean {
    return this._listeners.ITEM_LISTENER.length !== 0 || this._listeners.CHANGE.length !== 0;
  }

  clear(trx?: Transaction) {
    if (this._val.length === 0) return;
    this._val.forEach((obj) => {
      this.fireItemListeners(obj, 'REMOVE')
    });
    this._val = [];
    this.fireChangeListeners(trx)
  }

  replaceAll(val: Obj[], trx?: Transaction) {
    // TODO: consolidate the old and new list so that the intersection between
    this._val.forEach((obj) => {
      this.fireItemListeners(obj, 'REMOVE')
    });
    this._val = val;
    val.forEach((obj) => {
      this.fireItemListeners(obj, 'ADD')
    });
    this.fireChangeListeners(trx)
  }

  replace(remove: Obj[], insert: Obj[]) {

    // Lets not assume that the items to be removed are actually in the set
    let removedItems: Obj[] = [];
    this._val = this._val.filter((v) => {
      if (remove.includes(v)) {
        removedItems.push(v);
        return false // remove
      }
      return true; // preserve
    });

    this._val.push(...insert);

    removedItems.forEach(r => this._listeners.ITEM_LISTENER.forEach((l) => l(r, 'REMOVE')));
    insert.forEach(i => this._listeners.ITEM_LISTENER.forEach((l) => l(i, 'ADD')));

    this._listeners.CHANGE.forEach((l) => l());
  }

  insert(obj: Obj, trx?: Transaction, dedupe?: boolean,) {
    if (dedupe && this.contains(obj)) return;
    this._val.push(obj);

    this.fireItemListeners(obj, 'ADD');
    this.fireChangeListeners(trx);
  }
  protected rawInsert(obj: Obj, dedupe?: boolean) {
    if (dedupe && this.contains(obj)) return;
    this._val.push(obj);
    this.fireItemListeners(obj, 'ADD');
  }
  notify(trx?: Transaction) {
    this.fireChangeListeners(trx)
  }
  async notifyAsync(): Promise<void> {
    return this.fireChangeListenersAsync()
  }

  contains(obj: Obj) {
    return this._val.includes(obj);
  }

  remove(val: Obj, trx: Transaction) {
    if (!this._val.includes(val)) {
      // throw new Error('Value not present');
      return;
    }

    // console.log(`OL deleting ${(val as any).prettyId()}:${(val as any).role._value} -> ${(val as any).data?._value?.payload}`)
    this._val = this._val.filter((v) => v !== val);
    this.fireItemListeners(val, 'REMOVE')
    this.fireChangeListeners(trx)
  }
  protected rawRemove(val: Obj) {
    if (!this._val.includes(val)) {
      // throw new Error('Value not present');
      return;
    }
    this._val = this._val.filter((v) => v !== val);
    this.fireItemListeners(val, 'REMOVE')
  }

  removeWhere(pred: (val: Obj) => boolean, trx: Transaction) {
    let val = this.find(pred);
    if (val) {
      this.remove(val, trx);
      this.fireItemListeners(val, 'REMOVE')
      this.fireChangeListeners(trx)
    }
  }

  map<Ret>(f: (obj: Obj, index: number, arr: Obj[]) => Ret) {
    return this._val.map((obj, index, arr) => f.call(undefined, obj, index, arr));
  }

  forEach(f: (obj: Obj) => void) {
    this._val.forEach((obj) => f.call(undefined, obj));
  }

  sort(fn?: (a: Obj, b: Obj) => number) {
    return [...this._val].sort(fn);
  }

  filter(predicate: (value: Obj, index: number, obj: Obj[]) => boolean, _thisArg?: any): Obj[] {
    return this._val.filter(predicate);
  }

  find(predicate: (value: Obj, index: number, obj: Obj[]) => boolean, _thisArg?: any): Obj | undefined {
    return this._val.find(predicate);
  }
}

export function useObservableList<Obj>(obs: ObservableList<Obj>) {
  const [, forceUpdate] = useReducer((x) => {
    return x + 1;
  }, 0);

  useEffect(() => {
    // let unsub: Function = () => {};
    let unsub = obs.subscribe({
      ITEM_LISTENER: () => forceUpdate(null),
      CHANGE: () => forceUpdate(null),
    });

    return () => {
      unsub();
    };
  }, [obs]);
}

interface ObservableListHOCProps<Obj> {
  list: ObservableList<Obj>;
}
interface ObservableListHOCState {
  counter: number;
}

export function bindObservableList<P extends ObservableListHOCProps<any>>(Comp: ComponentType<P>) {
  return class extends Component<P, ObservableListHOCState> {
    unsub = () => { };
    state = { counter: 0 };
    forceUpdate() {
      this.setState(({ counter }) => ({ counter: counter + 1 }));
    }
    componentDidMount() {
      this.unsub = this.props.list.subscribe({
        ITEM_LISTENER: () => this.forceUpdate(),
        CHANGE: () => this.forceUpdate(),
      });
    }
    componentWillUnmount() {
      this.unsub();
    }
    render(props: P) {
      return h(Comp, props);
    }
  };
}
