import {
  queryQuests,
  Quest,
  useObservable,
  useSessionManager,
} from '@edvoapp/plm-common'
import cx from 'classnames'
import { RefObject, Fragment } from 'preact'
import { useEffect, useRef, useState } from 'preact/compat'
import { ChevronRight } from '../../assets/icons/chevron-right'
import { HomeIcon } from '../../assets/icons/home-icon'
import { Checkmark } from '../../assets/icons/white-checkmark'
import { truncate } from '../../utils/string'
import styles from './context-bar.module.scss'

export interface ContextBarProps {
  /** ref to the context bar */
  innerRef: RefObject<HTMLDivElement>

  /** Called when the context bar is clicked. If omitted, the caret will not appear. */
  onClick?: (e: MouseEvent) => void

  /** True if the quest selection pane is expanded. */
  open: boolean

  /** True if the context bar should auto-collapse when the mouse leaves the context bar area. */
  autoCollapse: boolean

  /** The Quest object of the current active quest, if any. */
  activeQuest: Quest | null

  /** Called when the user wants to create a new quest given the quest name. */
  createQuest: (name: string) => Promise<Quest>

  /** Called when the user wants to activate a particular quest from the quest filter. */
  activateQuest: (quest: Quest) => void

  /** Called when the user clicks the home button in the context bar. */
  goHome: () => void
}

/* This attribute is used in an effect (line 78), but its setter is never called, and the actual
 *  click event is handled by a callback defined in CtxBar

/**
 * Context Bar provides the Quest search and create feature in the browser extension.
 *
 * The bar has multiple states:
 *  * filter value: the string being typed into the context bar
 *    * what changes this state: user types into the context bar input field
 *
 *  * filter results: an array of the previously created quests that match the filter string
 *    * what changes this state: ??
 *
 *  * selecting quest: true while we're in the process of selecting a member of the filter results
 *    * what changes this state: toggles true/false when clicking the bottom (select quest) button
 *    * when selecting quest == true: selection box is open
 *
 *  * creating quest: true while a quest is being created and activated
 *    * what changes this state: set true when buildQuest is called, set false prior to returning
 *    * when creating quest == true: ??
 * */

export function ContextBar(props: ContextBarProps) {
  const sessionManager = useSessionManager()
  useObservable(sessionManager.status)
  const statusClass = styles['session-' + sessionManager.status.getValue()]
  const [selectingQuest, setSelectingQuest] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [filterResults, setFilterResults] = useState<Quest[]>([])
  const [creatingQuest, setCreatingQuest] = useState(false)

  const filterTextRef = useRef<HTMLInputElement>(null)

  /**
   * Maintain focus on text input when selectingQuest is true, blur when a quest is being created.
   * */
  useEffect(() => {
    if (selectingQuest && !creatingQuest) {
      filterTextRef.current.focus()
    }
  }, [selectingQuest, creatingQuest])

  /**
   * Keep context bar open while we're waiting for a quest to be created(?)
   * */
  useEffect(() => {
    // Quest selection shouldn't be closed if we're waiting for a quest to be created
    const handleClick = (event: MouseEvent) => {
      if (!props.innerRef.current?.contains(event.target as Node | null)) {
        setSelectingQuest(false)
      }
    }
    if (selectingQuest && !creatingQuest) {
      window.addEventListener('click', handleClick)
      return () => window.removeEventListener('click', handleClick)
    }
  }, [selectingQuest, creatingQuest])

  /**
   * Query for Quests when the filter value has changed.
   * */
  useEffect(() => {
    const timeout = setTimeout(async () => {
      const questsObservable = queryQuests(filterValue)
      await questsObservable.awaitLoad()
      setFilterResults(questsObservable.get())
    }, 500)
    return () => clearTimeout(timeout)
  }, [filterValue])

  /**
   * Createasync  a quest when we have a filter value.
   * */
  const buildQuest = async () => {
    if (!filterValue) {
      return
    }
    setCreatingQuest(true)
    const quest = await props.createQuest(filterValue)
    await props.activateQuest(quest)
    setFilterValue('')
    setCreatingQuest(false)
    setSelectingQuest(false)
  }

  /**
   * Search for quests in the db and list results.
   * */
  const startSelectingQuest = async () => {
    setSelectingQuest(true)
    const questsObservable = queryQuests()
    await questsObservable.awaitLoad()
    setFilterResults(questsObservable.get())
  }

  /**
   * Clear filter string and results.
   * */
  const stopSelectingQuest = async () => {
    setSelectingQuest(false)
    setFilterValue('')
    setFilterResults([])
  }

  /**
   * onInput handler for filter input that keeps track of the entered value.
   * */
  const handleFilterTextInput = (event: Event) => {
    const value = (event.currentTarget as HTMLInputElement).value
    setFilterValue(value)
  }

  /**
   * onKeyDown handler for filter input that fires on pressing 'Enter'.
   * */
  const handleFilterTextKeyDown = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (filterValue && !creatingQuest) {
        await buildQuest()
      } else if (!filterValue) {
        stopSelectingQuest()
      }
    }
  }

  /**
   * Component Body
   */
  return (
    <div
      class={cx(styles.contextBarRoot, {
        [styles.flagActiveQuest]: props.activeQuest,
        [styles.flagNoCollapse]: !props.autoCollapse || selectingQuest,
      })}
      ref={props.innerRef}
    >
      {/* Edvo Icon - onClick Returns user to Home */}
      <button class={cx(styles.homeButton, statusClass)} onClick={props.goHome}>
        <HomeIcon width={24} height={24} style={{ marginRight: 8 }} />
      </button>
      <div
        class={cx(styles.breadcrumbRoot, {
          [styles.open]: props.open,
          [styles.selectingQuest]: selectingQuest,
        })}
      >
        {/* If select quest pane is open, then display the input field (and results, if any) */}
        {props.open ? (
          <div
            class={cx(styles.questSelect, {
              [styles.open]: selectingQuest,
            })}
          >
            {selectingQuest ? (
              <Fragment>
                <label class={styles.questFilter}>
                  {/* Input field for quest filtering */}
                  <input
                    class={styles.questFilterText}
                    ref={filterTextRef}
                    type="text"
                    value={filterValue}
                    placeholder="Enter a topic..."
                    disabled={creatingQuest}
                    onInput={handleFilterTextInput}
                    onKeyDown={handleFilterTextKeyDown}
                  />
                  {filterValue && (
                    <button
                      class={styles.questFilterCreate}
                      disabled={creatingQuest}
                      onClick={async (e) => {
                        e.stopPropagation()
                        await buildQuest()
                      }}
                    >
                      {creatingQuest ? 'Creating...' : 'Create'}
                    </button>
                  )}
                </label>
                {filterValue && !filterResults.length && (
                  <div class={styles.questSelectEmptyMessage}>
                    No matching results
                  </div>
                )}
                {/* Filtered Results Drop-Down w/ Labels and Radio Buttons */}
                <div class={styles.questFilterResults}>
                  {filterResults.map((quest) => {
                    const checked = quest.id() === props.activeQuest?.id()

                    return (
                      <label
                        class={styles.questOption}
                        key={quest.id()}
                        onClick={async () => {
                          await props.activateQuest(quest)
                          stopSelectingQuest()
                        }}
                      >
                        {quest.name.peek() || ''}
                        <input
                          class={styles.questOptionRadio}
                          type="radio"
                          checked={checked}
                          disabled={true}
                        />
                        <div class={styles.questOptionRadioIcon}>
                          {checked ? <Checkmark /> : null}
                        </div>
                      </label>
                    )
                  })}
                </div>
              </Fragment>
            ) : (
              /* Opens the topic selection/save Topic pane. */
              <button
                class={styles.questSelectBottomButton}
                onClick={() => {
                  startSelectingQuest()
                }}
                disabled={creatingQuest}
              >
                {selectingQuest
                  ? 'Save'
                  : props.activeQuest
                  ? truncate(props.activeQuest.name.peek() || '')
                  : 'Search or Create Topic'}
              </button>
            )}
          </div>
        ) : null}
        {selectingQuest || !props.onClick ? null : (
          <ChevronRight onClick={props.onClick} />
        )}
      </div>
    </div>
  )
}
