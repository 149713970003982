import { FunctionComponent } from 'preact'
import './viewer.scss'

export const ClaimPartComponent: FunctionComponent = () => {
  // TODO: do we need this component?
  return null
  // const children = useMemo(() => {
  //   const { reference, kind } = part
  //   switch (kind) {
  //     case 'artifactRef': {
  //       const artifact = reference as Artifact
  //       const id = artifact.id as string
  //       return <ArtifactComponent artifact={artifact} />
  //     }
  //     case 'claimRef': {
  //       const claim = reference as Claim
  //       const id = claim.id as string
  //       return null
  //       // return (
  //       //   <ClaimComponent
  //       //     activeQuest={activeQuest}
  //       //     claim={claim}
  //       //     path={[...path, id]}
  //       //     attachRole={attachRole}
  //       //   />
  //       // )
  //     }
  //     case 'weakArtifactRef':
  //       // TODO: cause the thread viewer to re-render with this artifactID as its root
  //       // 1. (minimally) fetch artifact, render some small version of it
  //       // OR
  //       // 2. encode the summary into the claim part itself
  //       // regardless, we cannot recurse any further
  //       return <a href="#">Weak artifact summary (TODO)</a>
  //     case 'weakClaimRef':
  //       // TODO: cause the thread viewer to re-render with this claimID as its root
  //       // see above
  //       return <a href="#">Weak claim summary (TODO)</a>
  //     case 'question':
  //     case 'feeling':
  //       return <div className="flex-1">Text Placeholder</div>
  //     case 'questReference':
  //       return <div>Quest Reference</div>
  //     case 'visit':
  //       return (
  //         <div>Visited on {claim.createdAt?.toDate().toLocaleDateString()}</div>
  //       )
  //     case 'saveForLater':
  //       return (
  //         <div>
  //           Saved for later on {claim.createdAt?.toDate().toLocaleDateString()}
  //         </div>
  //       )
  //     case 'null':
  //       return <div>null</div>
  //     default:
  //       console.warn('No part matched')
  //       return null
  //   }
  // }, [part, claim])

  // return (
  //   <div id={'claim_part__' + part.key()} className={cx('flex', 'claim-part')}>
  //     {children}
  //     {/*<pre>{JSON.stringify(part, null, 2)}</pre>*/}
  //   </div>
  // )
}
