// TODO: un-duplicate this and put into plm-common package
import cx from 'classnames'
import { JSX } from 'preact'
import { forwardRef } from 'preact/compat'

import { TextareaAutosize } from '../textarea-autosize'

export interface FormTextareaProps
  extends JSX.HTMLAttributes<HTMLTextAreaElement> {
  value: string
  onSubmit: (evt: JSX.TargetedEvent) => void | Promise<void>
  formCx?: string
  textareaCx?: string
  autofocus?: boolean
  style?: JSX.CSSProperties
  formProps?: JSX.HTMLAttributes<HTMLFormElement>
}

export const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  (
    {
      formProps = {},
      style,
      onSubmit,
      formCx,
      textareaCx,
      autofocus,
      children,
      ...props
    },
    textareaRef,
  ) => {
    // const prevAutofocus = usePrevious(autofocus);
    // useEffect(() => {
    //   if (!prevAutofocus && autofocus && textareaRef.current) {
    //     textareaRef.current.focus();
    //   }
    // }, [autofocus, prevAutofocus]);
    return (
      <form
        style={style}
        className={cx(formCx)}
        onSubmit={onSubmit}
        {...formProps}
      >
        {/*<animated.form style={style} className={cx(formCx)} ref={formRef} {...springProps} {...formProps}>*/}
        <TextareaAutosize
          className={cx('resize-none w-full h-full p-2', textareaCx)}
          onKeyDown={async (evt) => {
            evt.stopPropagation()
            if (evt.key === 'Enter') {
              evt.preventDefault()
              await onSubmit(evt)
            }
            if (['Esc', 'Escape'].includes(evt.key)) {
              if (textareaRef.current) {
                textareaRef.current.blur()
              }
            }
          }}
          ref={textareaRef}
          {...props}
        />
        {children}
      </form>
    )
  },
)
