import {
  Claim,
  RolesMap,
  useObserve,
  getCurrentUserID,
  useAwaitable,
} from '@edvoapp/plm-common'
import cx from 'classnames'
import { Fragment } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { BodyComponent } from '.'
import { RenderContext, Renderer, RootType } from './renderer/renderer'
import './viewer.scss'

export interface ClaimComponentProps {
  claim: Claim
  readonly?: boolean
  roles: RolesMap
  shadowParent?: Claim // This needs a better name - it's both a flag to say "you're unshadowed" and also the parent of the parent user claim
  renderer: Renderer
  renderContext: RenderContext
  rootType: RootType
}

export type RenderedClaim = {
  claim: Claim
  shadowParent?: Claim
}

export function ClaimComponent({
  claim,
  roles,
  renderer,
  renderContext,
  rootType,
}: ClaimComponentProps) {
  // const { headRole, itemRole, prevRole, tailRole, shadowRole, allRoles } = roles
  // const prevClaim = claim.getEdgeTarget([prevRole])
  // const nextClaim = claim.getReverseEdgeTarget([prevRole])

  // const prevClaimData =
  //   prevClaim instanceof Claim ? prevClaim.data.peek() : null
  // const nextClaimData =
  //   nextClaim instanceof Claim ? nextClaim.data.peek() : null
  // if (!claimData) return ''

  // const prevAuthorSame =
  //   prevClaimData && prevClaimData.userID === claimData.userID
  // const nextAuthorSame =
  //   nextClaimData && nextClaimData.userID === claimData.userID

  const isFocused = useObserve(() => renderContext.evtNav.isClaimFocused(claim)) // save a render cycle if we're starting out focused
  const claimData = useAwaitable(claim.data)

  // Important: useAwaitable is not the same as useObserve
  // it only fetches the first value. We're using this to avoid a 'blink' when loading
  const firstBodyText = useAwaitable(claim.getBodyTextObs())

  const wrapperCx = useMemo(() => {
    return cx(
      'focusable',
      `claim-component`,
      renderContext.extraClass,
      claimData?.meta?.customClass,
      // prevAuthorSame ? 'prev-author-same' : 'prev-author-different',
      // nextAuthorSame ? 'next-author-same' : 'next-author-different',
      claimData?.userID === getCurrentUserID()
        ? 'is-current-user'
        : 'not-current-user',
      isFocused.getValue() && 'is-active',
    )
  }, [claimData, isFocused.getValue()])

  const children = renderer.useRenderChildren(claim, renderContext)

  if (!(claimData && typeof firstBodyText !== 'undefined')) return null

  return (
    <Fragment>
      <div
        id={'claim__' + claim.key()}
        className={wrapperCx}
        ref={(r: any) =>
          renderContext.evtNav.bindComponent(r, {
            entity: claim,
            renderCtx: renderContext,
            isFocused,
          })
        }
      >
        <div className="main">
          <div className="controls">
            <div className="handle" />
            {/* {claim.id?.substr(0, 4)} */}
          </div>
          <div className="body">
            <BodyComponent claim={claim} rootType={rootType} />
          </div>
        </div>
        {children}
      </div>
    </Fragment>
  )
}
