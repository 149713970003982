import firebase from 'firebase/app';
import { Artifact, DocumentReference, firebaseNow, getCurrentUserID, Timestamp } from '../internal';
import { AwaitableValue, AwaitValue, NowValue } from './AwaitValue';
import { Base, BaseConstructorArgs, BaseCreateArgs, BaseDataDB, BaseDataRecipient, BaseHydrateArgs } from './Base';
import { Registry } from './Registry';

export interface ArtifactPartData extends BaseDataRecipient {
  parentID: string;
  role: string[];
  contentType: string;
  payload: string; // TODO make this a Buffer / binary firestore field
}

interface ArtifactPartConstructorArgs extends BaseConstructorArgs<ArtifactPartData> {
  parent: Artifact;
  role?: string[];
  contentType?: string;
  content?: string;
}

export interface ArtifactPartCreateArgs extends BaseCreateArgs {
  parent: Artifact;
  role: string[];
  contentType: string;
  content: string;
}

export interface ArtifactPartHydrateArgs extends BaseHydrateArgs<ArtifactPartData> {
  parent: Artifact;
}

export class ArtifactPart extends Base<ArtifactPartData> {
  type = 'artifactPart';
  role: AwaitableValue<string[]>;
  contentType: AwaitableValue<string>;
  parent: Artifact;
  content: AwaitableValue<string>;
  private static registry = new Registry<ArtifactPart>();

  private constructor(args: ArtifactPartConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);

    this.parent = args.parent;
    this.role = args.role ? new NowValue(args.role) : new AwaitValue();
    this.contentType = args.contentType ? new NowValue(args.contentType) : new AwaitValue();
    this.content = args.content ? new NowValue(args.content) : new AwaitValue();

    // Have to do deferred registration, which means no dedup, but that's ok
    this.docReference.passive_then((docRef) => {
      // Might already be registered. have to live with duplicates for anything with an AwaitValue docRef
      ArtifactPart.registry.add(docRef.id, this);
    });
    this.constructorFinish();
  }
  static create(args: ArtifactPartCreateArgs, _firebase: firebase.app.App = firebase.app()): ArtifactPart {
    const { parent, role, contentType, content } = args;

    const part = new ArtifactPart({ parent, role, contentType }, _firebase);
    parent.parts.value().insert(part, args.trx);

    args.trx.prepareOp(part, async () => {
      let parentDocRef = await parent.docReference.get();

      const docRef = parentDocRef.collection('artifactPart').doc() as DocumentReference<ArtifactPartData>;
      part.docReference.set(docRef);

      const userID = getCurrentUserID();

      let data: ArtifactPartData = {
        id: docRef.id,
        parentID: parentDocRef.id,
        status: 'active',
        keyID: '',
        cipher: '',
        recipientID: [userID],
        userID,
        createdAt: firebaseNow(),
        updatedAt: firebaseNow(),
        role,
        contentType,
        payload: content,
        meta: args.meta || null,
      };
      part.applyData(data);
      args.trx.insert(part, data);
    });

    return part;
  }

  static hydrate(args: ArtifactPartHydrateArgs, _firebase: firebase.app.App = firebase.app()): ArtifactPart {
    // NOTE: it is unlikely we will ever query backrefs by something other than ParentId
    // Because everything else will be opaque/encrypted. Such a query would be done via parts instead
    // Thus, hydration should be able to safely require the parent both here and for ClaimPart

    const { docRef, parent, data } = args;
    let part = ArtifactPart.registry.get(docRef.id);
    if (part) {
      part.applyData(data);
      return part;
    }

    part = new ArtifactPart({ docRef, parent, data }, _firebase);
    return part;
  }
  applyData(data: ArtifactPartData) {
    super.applyData(data);
    if (data.role) this.role.set(data.role);
    if (data.payload) this.content.set(data.payload);
    if (data.contentType) this.contentType.set(data.contentType);
  }
  diag() {
    return '';
  }
}
