import { Component, ComponentType, h } from 'preact';
import { useEffect, useReducer, useState } from 'preact/hooks';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  getCurrentUserID,
  ItemListener,
  Listener,
  ObservableList,
  Query,
  QueryDocumentSnapshot,
  SharedQueryObservable,
  Transaction,
  Unsubscriber,
} from '../internal';
import { AwaitableValue } from './AwaitValue';
import { BaseDataDB } from './Base';
import firebase from 'firebase/app'

export interface QueryableObj<Db> {
  docReference: AwaitableValue<DocumentReference<Db>>;
  prettyId(): string;
}

export class QueryObservable<Db extends BaseDataDB, Obj extends QueryableObj<Db>> extends ObservableList<Obj> {
  query?: Query<Db>;
  sharedQuery?: SharedQueryObservable<Db, Obj>;
  unsub?: Function | null;
  isInjected = false;
  isExecuted = false;
  activeOp: Promise<void> | null = null;
  loaded: Promise<void>;
  protected _signalLoaded!: Function;
  protected unAcked: Obj[] = [];
  protected removed: string[] = [];
  protected _isLoaded = false;
  protected _isLoadedAuthoritatively = false;
  // The lookup defines any item that has been stored to the DB
  protected _lookup: Record<string, Obj> = {};
  protected _ctor: (doc: DocumentSnapshot<Db>) => Obj;

  constructor({
    val,
    ctor,
    name,
    onSubscribe,
  }: {
    val: Obj[];
    ctor: (doc: DocumentSnapshot<Db>) => Obj;
    name?: string;
    onSubscribe?: Function;
  }) {
    super(val, name, onSubscribe);
    this._ctor = ctor;
    this._val = val;

    // TODO convert this to Observable<boolean> or similar
    // We shouldn't be rolling our own notification mechanisms in individual classes
    this.loaded = new Promise((r) => {
      this._signalLoaded = r;
    });
  }
  isLoaded() {
    // Important to override ObservableList because we are NOT automatically loaded
    return this._isLoaded
  }
  injectQuery(query: CollectionReference<Db> | Query<Db>) {
    if (this.isInjected) {
      throw `Cannot inject query -- ${this.sharedQuery ? 'shared' : ''} query already injected`;
    }
    if (this.unsub) {
      this.unsub();
      this.unsub = null;
      this.isExecuted = false;
    }
    this.query = query;
    this.isInjected = true;
    if (this.hasSubscribers()) this.execute();
  }

  async acquireLock(): Promise<() => void> {
    if (this.activeOp) {
      await this.activeOp;
    }

    let res: () => void = () => { };
    this.activeOp = new Promise((resolve) => {
      res = resolve;
    });

    return res;
  }

  // It is acceptable to call this even if we don't have a query yet
  execute() {
    if (this.isExecuted) return;
    // NOTE: We may not have a query at this point because sometimes we want to inject a query after construction of the query observable
    // (e.g., in highlights -- we wait for the artifact to be loaded)
    if (this.query) {
      this.isExecuted = true;

      this.query?.onSnapshot((snapshot) => {
        // console.log(`QueryObservable(${this.name}).onSnapshot returned`, snapshot.metadata.fromCache, snapshot.docs.length, snapshot.docs.map(d => d.id))
        // For some reason Firestore caching behavior really sucks.
        // Have't done a deep dive as to why, but it appears that immediately after
        // calling .get({source: server}), .onSnapshot will return fromCache:true
        // snapshots which are missing data. Madness
        // if (snapshot.metadata.fromCache) return true;

        // TODO - consider updating this to use snapshot.docChanges 
        this.mergeDocs(snapshot.docs);
        this.setLoaded(snapshot.metadata.fromCache === false);
      }, (err) => {
        console.error(`QueryObservable(${this.name}).onSnapshot failed`, err)
      });
    }
  }

  async awaitLoad() {
    this.execute();
    if (this._isLoaded) return;
    await this.loaded;
  }
  async awaitAuthoritativeLoad() {
    await this.awaitLoad();
    if (this._isLoadedAuthoritatively) return;

    try {
      const queryRef = await this.query!.get({ source: 'server' });
      this.mergeDocs(queryRef.docs);
      this.setLoaded(true)
    } catch (e) {
      console.error(e);
    }
  }

  injectShared(shared: SharedQueryObservable<Db, Obj>, subsetKey: string) {
    if (this.isInjected) {
      throw `Cannot inject shared query -- ${this.sharedQuery ? 'shared' : ''} query already injected`;
    }
    this.sharedQuery = shared;
    shared.subscribeSubset(subsetKey, (subsetList: QueryDocumentSnapshot<Db>[]) => {
      this.mergeDocs(subsetList);
      this.setLoaded(shared._isLoadedAuthoritatively);
    });
    this.isInjected = true;
    this.isExecuted = true;

    // consider moving execution into ConditionallyExecuteQuery
  }

  clear() {
    this.isInjected = false;
    this.isExecuted = false;
    this._isLoaded = false;
    this._isLoadedAuthoritatively = false;
    this.query = undefined;
    this.sharedQuery = undefined;
    this._lookup = {};
    this.unAcked = [];
    this.removed = [];
    super.clear();
  }

  // Immediately fire the change listener on subscribe if we're already loaded or anything is in the set
  subscribe(args: { ITEM_LISTENER?: ItemListener<Obj>; CHANGE?: Listener<Obj> }): Unsubscriber {
    const { ITEM_LISTENER, CHANGE } = args;
    if (this._val.length !== 0 || this._isLoaded) {
      this._val.forEach((obj) => {
        if (ITEM_LISTENER) {
          ITEM_LISTENER(obj, 'ADD');
        }
      });
      if (CHANGE && (this._isLoaded || this._val.length > 0)) {
        CHANGE();
      }
    }

    // Do not actually execute the query. Subscription in and of itself is NOT a signal to load

    return super.subscribe(args);
  }

  mergeDocs(docs: QueryDocumentSnapshot<Db>[]) {

    let changed = false;

    let omitted = { ...this._lookup };
    // console.log(`${this.name}.mergeDocs`, docs.map(doc => doc.data()))

    docs.forEach((doc) => {
      const docId = doc.id;
      if (this.removed.includes(docId)) return;

      delete omitted[docId];

      let obj = this._lookup[docId];

      if (obj) {
        // TODO await obj.lock() to make sure we're not firing events about this object while there's an outstanding write transaction
        this.unAcked = this.unAcked.filter((o) => o !== obj);
        // TODO call obj.applyData()
        // console.log('ackd', docId, obj.prettyId(), (obj as any).role?._value, doc.metadata);
      } else {
        const data = this._ctor(doc);
        // TODO: re-order and remove omissions
        this._val.push(data);
        this._lookup[docId] = data;
        this.fireItemListeners(data, 'ADD')
        changed = true;
      }
    });
    Object.entries(omitted).forEach(([docId, obj]) => {
      if (this.unAcked.includes(obj)) {
        // console.log(`ignore unackd delete ${docId} (${obj.prettyId()})`, (obj as any).role?._value);
        return;
      }
      // console.log(`QO(${this.name}): deleting ${docId} (${obj.prettyId()})`, 'Meta:', meta, (obj as { role?: { _value: string } }).role?._value);

      delete this._lookup[docId];
      this._val = this._val.filter((d) => d !== obj);
      this.fireItemListeners(obj, 'REMOVE');
      changed = true;
    });
    if (changed || !this._isLoaded) {
      this.fireChangeListeners();
      // TODO - apply this to CommonQuery mergeDocs as well
    }
  }

  setLoaded(authoritative: boolean) {
    this._isLoaded = true;
    if (authoritative) {
      this._isLoadedAuthoritatively = true
    }
    this._signalLoaded();
  }

  insert(obj: Obj, trx: Transaction) {
    super.insert(obj, trx, true);
    this.unAcked.push(obj);
    // console.log(`QO(${this.name}).insert`, obj.prettyId(), (obj as any).status);

    obj.docReference.passive_then((docRef) => {
      if (!this._val.includes(obj)) return; // Must have gotten removed already
      const id = docRef.id;
      // console.log(`QO(${this.name}).register ${obj.prettyId()} = ${id}`);
      if (this._lookup[id]) {
        throw `QueryObservable(${this.name || ''}): Duplicate detected for id: ${id} `;
      }
      // console.log('inserted with ID', id);
      this._lookup[id] = obj;
    });
  }

  remove(obj: Obj, trx: Transaction) {
    const beforelen = this._val.length;
    super.remove(obj, trx);

    // console.log(`QO(${this.name}).remove ${obj?.prettyId()}`, beforelen, this._val.length)
    let objId = obj.docReference.peek()?.id;
    if (objId) {
      delete this._lookup[objId];
      this.removed.push(objId); // Firestore sucks - we have to have to maintain this list of items to not add back to the set
    }
  }

  removeWhere(pred: (val: Obj) => boolean, trx: Transaction) {
    const obj = this.find(pred);
    if (obj) {
      this.remove(obj, trx);
    }
  }
}

export function useQueryObservable<Db extends BaseDataDB, Obj extends QueryableObj<Db>>(obs: QueryObservable<Db, Obj> | null) {
  const [, forceUpdate] = useReducer((x) => {
    return x + 1;
  }, 0);

  useEffect(() => {
    // let unsub: Function = () => {};
    let unsub = obs?.subscribe({
      ITEM_LISTENER: () => forceUpdate(null),
      CHANGE: () => forceUpdate(null),
    });

    return () => {
      unsub?.();
    };
  }, [obs]);
}

export function useObserveQuery<Db extends BaseDataDB, Obj extends QueryableObj<Db>>(
  ctor: (doc: DocumentSnapshot<Db>) => Obj
): QueryObservable<Db, Obj> {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  let unsub: Function = () => { };

  useEffect(() => {
    return () => {
      unsub();
    };
  }, []);

  const [observable] = useState(() => {
    const obs = new QueryObservable({ val: [], ctor, name: 'observe' });
    unsub = obs.subscribe({
      ITEM_LISTENER: (_item, _type) => forceUpdate(null),
      CHANGE: () => forceUpdate(null),
    });
    return obs;
  });
  return observable;
}

interface QueryObserverHOCProps { }

interface QueryObserverHOCState {
  counter: number;
}

export function bindQueryObservable<
  Db extends BaseDataDB,
  Obj extends QueryableObj<Db>,
  P extends QueryObserverHOCProps
>(Comp: ComponentType<P>, ctor: (doc: DocumentSnapshot<Db>) => Obj) {
  return class extends Component<P, QueryObserverHOCState> {
    unsub = () => { };
    state = { counter: 0 };
    forceUpdate() {
      this.setState(({ counter }) => ({ counter: counter + 1 }));
    }
    obs?: QueryObservable<Db, Obj>;
    componentDidMount() {
      this.obs = new QueryObservable({ val: [], ctor, name: 'observe' });
      this.unsub = this.obs.subscribe({
        ITEM_LISTENER: () => this.forceUpdate(),
        CHANGE: () => this.forceUpdate(),
      });
    }
    componentWillUnmount() {
      this.unsub();
    }
    render(props: P) {
      return h(Comp, props);
    }
  };
}
