import { Claim, getRolesFromRoleBase, RoleBase, RolesMap, Transaction, trxWrap } from '../internal';
import { Referenceable } from './Entity';

export type Injection = {
  claim: Claim;
  role: string;
};

export type ApplyBehaviorsArgs = {
  entity: Referenceable;
  renderMode: RoleBase;
  injections?: Injection[];
  blank?: boolean;
};

export async function applyInjections({ entity, injections, blank, renderMode }: ApplyBehaviorsArgs) {
  const roles = getRolesFromRoleBase(renderMode);
  const childClaims = entity.children(roles);
  await childClaims.awaitLoad();

  let backrefs = await entity.backrefs.get();
  let claim_id_set = new Set<string>();
  await backrefs.awaitAuthoritativeLoad();

  backrefs.forEach((backref) => {
    const targetIdValue = backref.targetId.peek_or_throw('Hydrated backref should have targetId').toString();
    claim_id_set.add(targetIdValue);
  });

  let isEmpty = childClaims.isEmpty();
  await trxWrap(async (trx) => {
    if (injections) {
      for (const injection of injections) {
        if (!claim_id_set.has(injection.claim.unifiedId().toString())) {
          isEmpty = false;
          // await recurseMaterializeTemplate(trx, entity, injection.claim, null, roles);
          const template = injection.claim;
          const templateChildren = template.children(roles);
          const iter = await templateChildren.getIter();
          let myPrev: Claim | null = null;
          while (true) {
            const next = iter.next();
            if (!next) break;
            myPrev = await recurseMaterializeTemplate(trx, entity, next, myPrev, roles);
          }
        }
      }
    }
    if (blank && isEmpty) {
      // Insert blank bullet
      const claim = Claim.create({ trx, meta: { placeholderText: 'Start typing or press /' } });
      await claim.attachMemberOf(trx, entity, 'category');
    }
  }, 'Template');
}

const recurseMaterializeTemplate = async (
  trx: Transaction,
  attachTo: Referenceable,
  template: Claim,
  _prev: Claim | null,
  roles: RolesMap,
): Promise<Claim> => {
  // console.log(`recurseMaterializeTemplate(${template.id()})`);
  let body_artifact = await template.getEdgeTarget(['body']);
  // console.log('got body artifact', body_artifact?.id(), (body_artifact as Artifact | null)?.parts.value().isLoaded);

  // Instantiate the template into a userspace claim, and attach that as normal!
  const meta = (await template.meta.get()) || undefined;
  const materializedClaim = Claim.create({ trx, meta });

  if (body_artifact) {
    materializedClaim.createEdge({ trx, target: body_artifact, role: ['body'] });
  }
  await materializedClaim.attachMemberOf(trx, attachTo, 'category');
  // Record the fact that we done did it by adding an embed relationship directly to the template
  // This combined with the .has above will prevent us from doing this a second time

  // Remember from whence you came
  materializedClaim.createEdge({ trx, target: template, role: ['category-shadow'] });

  const templateChildren = template.children(roles);

  const iter = await templateChildren.getIter();
  let myPrev: Claim | null = null;
  while (true) {
    const next = iter.next();
    if (!next) break;
    myPrev = await recurseMaterializeTemplate(trx, materializedClaim, next, myPrev, roles);
  }

  return materializedClaim;
};
