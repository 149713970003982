// This registry leaks memory, but works in all browsers
export class Registry<T extends object>{
    items: Record<string, T>
    constructor() {
        this.items = {}
    }
    add_or_throw(k: string, v: T, msg: string) {
        if (!this.add(k, v)) {
            throw `Duplicate entry (${msg})`
        }
    }
    add(k: string, v: T): boolean {
        const existing = this.items[k];
        if (existing) {
            return false; // decline to add the item to the registry
        }

        this.items[k] = v;
        return true
    }
    get(k: string): T | null {
        const entry = this.items[k];
        if (!entry) return null;

        return entry;
    }
    clear() {
        this.items = {}
    }
}

// This one does not, but requires ESNext
// export class Registry<T extends object>{
//     items: Record<string, WeakRef<T>>
//     constructor() {
//         this.items = {}
//         setInterval(() => { this.flush() }, 120000);
//     }
//     flush() {
//         Object.entries(this.items).forEach(([k, v]) => {
//             if (!v.deref()) {
//                 delete this.items[k]
//             }
//         });
//     }
//     add_or_throw(k: string, v: T) {
//         if (!this.add(k, v)) {
//             throw "Duplicate entry"
//         }
//     }
//     add(k: string, v: T): boolean {
//         const existing = this.items[k];
//         if (existing) {
//             // We have an entry. Is the object still resident?
//             if (existing.deref()) {
//                 return false; // decline to add the item to the registry
//             }
//             // no longer resident. overwrite the entry below
//         }

//         this.items[k] = new WeakRef(v);
//         return true
//     }
//     get(k: string): T | null {
//         const entry = this.items[k];
//         if (!entry) return null;

//         // We have an entry. Is the object still resident?
//         const ref = entry.deref();
//         if (ref) return ref; // yup

//         // no longer resident. delete the entry
//         delete this.items[k]

//         return null
//     }
// }
