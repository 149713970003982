import { Ref, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'preact/hooks';

export type UseMeasureRect = {
  rect: DOMRectReadOnly;
  cursor: string;
  pointerEvents: string;
};

export type UseMeasureRef<E extends HTMLElement = HTMLElement> = Ref<E>;
export type UseMeasureResult<E extends HTMLElement = HTMLElement> = [UseMeasureRef<E>, UseMeasureRect];

const defaultState = {
  rect: new DOMRectReadOnly(),
  cursor: 'pointer',
  pointerEvents: 'all',
};

interface UseMeasureArgs {
  disableResize?: boolean;
  disableMutation?: boolean;
  disableTransition?: boolean;
}

export const useMeasure = <E extends HTMLElement = HTMLElement>(opts: UseMeasureArgs = {}): UseMeasureResult<E> => {
  const { disableMutation, disableResize, disableTransition } = opts;
  const ref = useRef<E>();
  const [attrs, setAttrs] = useState<UseMeasureRect>(defaultState);

  const updateRect = useCallback(
    (el: Element) => {
      const rect = el.getBoundingClientRect();
      const style = getComputedStyle(el);
      const pointerEvents = style.pointerEvents;
      const cursor = style.pointerEvents === 'none' ? 'default' : 'pointer';
      setAttrs({ rect, cursor, pointerEvents });
    },
    [setAttrs],
  );

  const resizeObserver = useMemo(
    () =>
      new (window as any).ResizeObserver(([entry]: { target: Element }[]) => {
        if (entry && entry.target) {
          updateRect(entry.target);
        }
      }),
    [],
  );

  const mutationObserver = useMemo(
    () =>
      new window.MutationObserver(([entry]) => {
        if (entry && entry.target) {
          updateRect(entry.target as Element);
        }
      }),
    [],
  );

  useLayoutEffect(() => {
    if (!ref.current) return;
    function handleTransitionEnd() {
      updateRect(ref.current);
    }
    if (!disableResize) resizeObserver.observe(ref.current);
    if (!disableMutation) mutationObserver.observe(ref.current, { attributes: true });
    if (!disableTransition) ref.current.addEventListener('transitionend', handleTransitionEnd);
    return () => {
      if (!disableResize) resizeObserver.disconnect();
      if (!disableMutation) mutationObserver.disconnect();
      if (!disableTransition) ref.current.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [resizeObserver, mutationObserver]);

  return [ref, attrs];
};

export default useMeasure;
