export type RegionType = 'content' | 'paintable';

export abstract class RegionBase {
  protected _node: HTMLDivElement;
  protected _rendered = false;
  boundingClientRect: DOMRectReadOnly;
  abstract type: RegionType;
  // TODO - add dom elements for margin manipulation in cases where the paintable space is insufficient

  protected constructor(
    protected _initialDimensions: DOMRectReadOnly,
    protected _initialWindowScroll: { x: number; y: number },
    protected _mode: 'INACTIVE' | 'BACKGROUND' | 'ACTIVE',
  ) {
    const node = document.createElement('div');
    this._node = node;
    this.boundingClientRect = _initialDimensions;
  }

  protected abstract _debugColor: string;

  public paint() {
    if (this._rendered) {
      console.warn('Already rendered, either use #move or unpaint before repainting');
    } else {
      const node = this._node;
      const { left, top, width, height } = this.getBoundingClientRect();
      node.style.pointerEvents = 'none';
      node.style.position = 'absolute';
      node.style.left = `${left}px`;
      node.style.top = `${top}px`;
      node.style.width = `${width}px`;
      node.style.height = `${height}px`;

      switch (this.type) {
        case 'paintable':
      }
      if (
        [process.env.NODE_ENV, process.env.PREACT_APP_NODE_ENV].includes('development')
        // uncomment for debugging positioning
        // || true
      ) {
        node.style.backgroundColor = this._debugColor;
      }

      node.style.transform = 'translate3d(0, 0, 0)';
      const appNode = document.getElementById('edvo__app-annotator-inner');
      if (appNode) {
        appNode.appendChild(node);
        this._rendered = true;
      }
    }
  }
  public unpaint() {
    if (!this._rendered) {
      console.warn('Cannot unpaint a region that hasnt been painted yet.');
    } else {
      document.getElementById('edvo__app-annotator-inner')?.removeChild(this._node);
      this._rendered = false;
    }
  }

  public move({ x: scrollX, y: scrollY }: { x: number; y: number }) {
    const { x, y } = this._initialWindowScroll;
    if (this._rendered) this._node.style.transform = `translate3d(${x - scrollX}px, ${y - scrollY}px, 0)`;
  }

  public setMode(mode: 'INACTIVE' | 'BACKGROUND' | 'ACTIVE') {
    if (this._mode !== mode) {
      this._mode = mode;
      if (mode === 'ACTIVE') {
        this.boundingClientRect = new DOMRectReadOnly();
      } else {
        this.boundingClientRect = new DOMRectReadOnly();
      }
    }
  }

  private _setBoundingClientRect(mode: 'INACTIVE' | 'BACKGROUND' | 'ACTIVE') {
    const { left, width, top, height } = this._initialDimensions;
    const { x, y } = this._initialWindowScroll;
    // const l = mode === 'ACTIVE' ? left + 300 : left;
    return new DOMRectReadOnly(left - x, top - y, width, height);
  }

  public getBoundingClientRect() {
    const { left, width, top, height } = this._initialDimensions;
    const { x, y } = this._initialWindowScroll;
    // const l = this._mode === 'ACTIVE' ? left + 300 : left;
    return new DOMRectReadOnly(left - x, top - y, width, height);
  }
}
