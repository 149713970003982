import { JSX } from 'preact'
import { RefObject } from 'preact'

export type ActiveTextAreaElement = HTMLTextAreaElement & {
  setFocused?: (focused: boolean) => void
  setActive?: () => void
}

// export interface Focusability {
//   setActive?: () => void
//   setInactive?: () => void
//   handleDelete?: () => void | Promise<void>
//   handleEnter?: (evt?: Event) => void | Promise<void>
//   handleTab?: (evt?: Event) => void | Promise<void>
//   handleShiftTab?: (evt?: Event) => void | Promise<void>
// }

// Entity and Claim components, but NOT Artifact (components) themselves
export type EntityElement = HTMLDivElement// & Focusability

export function collectionHas(a: NodeListOf<HTMLElement>, b: HTMLElement) {
  //helper function (see below)
  for (let i = 0, len = a.length; i < len; i++) {
    if (a[i] == b) return true
  }
  return false
}

export function findParentBySelector(elm: HTMLElement, selector: string) {
  const all = document.querySelectorAll(selector)
  let cur = elm.parentElement
  while (cur && !collectionHas(all as NodeListOf<HTMLElement>, cur)) cur = cur.parentElement
  return cur
}

export function findPreviousSiblingBySelector(elm: HTMLElement, selector: string): HTMLElement {
  const all = document.querySelectorAll(selector)
  let cur = elm.previousElementSibling
  while (cur && !collectionHas(all as NodeListOf<HTMLElement>, cur as HTMLElement)) cur = cur.previousElementSibling
  return cur as HTMLElement
}

export function findNextSiblingBySelector(elm: HTMLElement, selector: string): HTMLElement | null {
  const all = document.querySelectorAll(selector)
  let cur = elm.nextElementSibling
  while (cur && !collectionHas(all as NodeListOf<HTMLElement>, cur as HTMLElement)) cur = cur.nextElementSibling
  return cur as HTMLElement
}

export function findFirstChildBySelector(elm: HTMLElement, selector: string): HTMLElement | null {
  return elm.querySelector(selector) as HTMLElement
}

export function findLastChildBySelector(elm: HTMLElement, selector: string) {
  const all = elm.querySelectorAll(selector)
  if (!all.length) return null
  return all[all.length - 1]
}

// returns the next sibling of the HTMLElement's parent
export function findNextCousinOnceRemoved(elm: HTMLElement, selector: string): HTMLElement | null {
  const parent = findParentBySelector(elm, selector)
  if (!parent) return null
  return findNextSiblingBySelector(parent, selector)
}

// returns the last deepest nested child of the previous sibling
export function findPrevCousinNthRemoved(elm: HTMLElement, selector: string) {
  const previousSibling = findPreviousSiblingBySelector(elm, selector)
  if (!previousSibling) return null
  let cur = findLastChildBySelector(previousSibling, selector) as HTMLElement | null
  if (!cur) return previousSibling
  let next = findLastChildBySelector(cur, selector)
  while (next) {
    cur = next as HTMLElement
    next = findLastChildBySelector(cur, selector)
  }
  return cur
}


// export function navigateDom(
//   el: HTMLElement | null,
//   ref: { current: EntityElement | null },
//   evt?: JSX.TargetedKeyboardEvent<HTMLTextAreaElement | HTMLDivElement>,
// ) {
//   if (el && ref.current) {
//     // set the previous one to inactive
//     ref.current.setInactive?.()
//     ref.current = el as EntityElement
//     // set the next one to active
//     ref.current.setActive?.()
//     if (evt) {
//       evt.preventDefault
//     }
//   }
// }

export function getCss(el: HTMLElement, ruleName: keyof CSSStyleDeclaration) {
  return getComputedStyle(el)[ruleName]
}

export function getCursorPos(input: HTMLTextAreaElement) {
  if ('selectionStart' in input && document.activeElement == input) {
    return {
      start: input.selectionStart,
      end: input.selectionEnd,
    }
  }
  return -1
}

export function setCursorPos(
  input: HTMLTextAreaElement,
  start: number,
  end: number = start,
) {
  if ('selectionStart' in input) {
    setTimeout(function () {
      input.selectionStart = start
      input.selectionEnd = end
    }, 1)
  }
}
