import firebase from 'firebase/app';
import { h, Component, ComponentType } from 'preact';
import { useEffect, useReducer, useState } from 'preact/hooks';
import { ThenFunction } from './AwaitValue';

export type Listener<T = unknown> = (value?: T) => (void | Promise<void>);
export type Unsubscriber = () => void;

export abstract class ObservableBase {
  protected constructor() { }
  protected _listeners: Listener[] = [];

  ticker(): Ticker {
    return new Ticker(this)
  }
  async tickOnce(): Promise<void> {
    return new Promise((resolve) => {
      let unsub: () => void;
      unsub = this.subscribe(() => {
        unsub!()
        resolve()
      })
    })
  }

  subscribe(fn: Listener, notifyInitialValue?: boolean): Unsubscriber {
    if (notifyInitialValue) {
      fn();
    }
    this._listeners.push(fn);

    return () => {
      this._listeners = this._listeners.filter((l) => l !== fn);
    };
  }
  protected notify() {
    this._listeners.forEach((l) => l());
  }
}

export class Observable<T> extends ObservableBase {
  protected _val: T;
  constructor(defaultValue: T) {
    super()
    this._val = defaultValue;
  }

  // Baby steps toward unifying Observables and Awaitables
  // TODO - do the rest

  /** Call the callback as soon as this Observable has a non-undefined value **/
  get_then<O>(f: ThenFunction<T, O>): Promise<O> {
    // In Rust you could do this with a blanket implementation for all Observable<Option<T>>
    if (typeof this._val !== 'undefined') {
      return Promise.resolve(f(this._val))
    }

    return new Promise((resolve) => {
      // Subscribe to myself
      const unsub = this.subscribe(() => {
        if (typeof this._val !== 'undefined') {
          // call the callback and resolve the promise
          unsub!()
          resolve(f(this._val))
        }
      })
    })
  }

  getValue(): T {
    return this._val
  }
  setValue(value: T) {
    const changed = value !== this._val;
    this._val = value;
    if (changed) this.notify()
  }
}

export function useObservable(obs: ObservableBase | null | undefined) {
  const [updateKey, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    if (obs) {
      let unsub = obs.subscribe(() => forceUpdate(null));
      return () => {
        unsub();
      };
    }
  }, [obs]);
  return updateKey;
}

export function useObservableValue<V>(obs: ObservableBase & { getValue: () => V } | null | undefined) {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    if (obs) {
      let unsub = obs.subscribe(() => forceUpdate(null));
      return () => {
        unsub();
      };
    }
  }, [obs]);
  return obs?.getValue();
}

export function useObserve<T>(initialValue: T | (() => T)): Observable<T> {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [obs] = useState(() => {
    if (typeof initialValue === 'function') initialValue = (initialValue as () => T)()
    return new Observable<T>(initialValue)
  });

  useEffect(() => {
    let unsub = obs.subscribe(() => forceUpdate(null));
    return () => {
      unsub();
    };
  });

  return obs
}




interface ObserverHOCProps {
  observable: ObservableBase;
}
interface ObserverHOCState {
  counter: number;
}

export function bindObservable<P extends ObserverHOCProps>(Comp: ComponentType<P>) {
  return class extends Component<P, ObserverHOCState> {
    unsub = () => { };
    state = { counter: 0 };
    componentDidMount() {
      this.unsub = this.props.observable.subscribe(() => this.setState(({ counter }) => ({ counter: counter + 1 })));
    }
    componentWillUnmount() {
      this.unsub();
    }
    render(props: P) {
      return h(Comp, props);
    }
  };
}

// Ticker is a class which is used for tests
// It allows the behavior of Observable objects to be precisely measured such that
// we can determine if and Observable object (or a chain of observable objects) is behaving according to our expectations
// If we have too many, or too few ticks for a given exercise, then something has likely gone wrong
export class Ticker {
  private queuedTicks: { resolve: () => void, debug: boolean }[] = [];
  private _uncapturedTicks: number = 0;
  private unsub: () => void;
  constructor(obs: ObservableBase) {
    this.unsub = obs.subscribe(() => {
      const next = this.queuedTicks.shift();
      if (next) {
        if (next.debug) debugger;
        next.resolve()
      } else {
        this._uncapturedTicks += 1;
      }
    })
  }
  async tick(): Promise<void> {
    if (this._uncapturedTicks > 0) {
      this._uncapturedTicks -= 1;
      return
    }

    return new Promise((resolve) => {
      this.queuedTicks.push({ resolve: resolve as () => void, debug: false });
    })
  }
  // return the present number of uncaptured ticks
  // and decrement by up to the specified number
  uncapturedTicks(decrementTicks = 0): number {
    let t = this._uncapturedTicks;

    this._uncapturedTicks -= Math.min(Math.abs(decrementTicks), this._uncapturedTicks);

    return t
  }

  async tickDebug() {
    if (this._uncapturedTicks > 0) {
      throw "cannot debug uncaptured tick"
    }

    return new Promise((resolve) => {
      this.queuedTicks.push({ resolve: resolve as () => void, debug: true });
    })
  }
}