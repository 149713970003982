import firebase from 'firebase/app';
import {
  ArtifactPart,
  ArtifactPartData,
  Backref,
  BackrefDataDB,
  Claim,
  ClaimLinkedListObservable,
  DocumentReference,
  DocumentSnapshot,
  Entity,
  firebaseNow,
  getReverseEdgeTarget,
  makeActivatableBackrefs,
  Query,
  QueryObservable,
  Quest,
  RolesMap,
  Timestamp,
  Transaction,
} from '../internal';
import { getCurrentUser, getCurrentUserID } from '../utils';
import { intersects } from '../utils/tools';
import { ActivatableAsync, AwaitableValue, AwaitValue, NowValue } from './AwaitValue';
import {
  Base,
  BaseConstructorArgs,
  BaseCreateArgs,
  BaseDataDB,
  BaseGetArgs,
  BaseGetByIdArgs,
  BaseHydrateArgs,
} from './Base';
import { Registry } from './Registry';
import { trxWrap } from './Transaction';

export interface ResourceAttributes {
  url: string;
  // currently, title and img aren't being used
  title?: string;
  img?: string;
}

export interface HighlightAttributes {
  highlightSelectorSet: string;
}

export type questVisitCacheItem = {
  questId: string;
  visitId: string;
};

export interface ArtifactDataDB extends BaseDataDB {
  parentArtifactID: string | null;
  attributes?: ResourceAttributes | HighlightAttributes;
  kind: string;
  questVisitCache: questVisitCacheItem[];
  visitedQuestIds: string[];
}

export interface ArtifactConstructorArgs extends BaseConstructorArgs<ArtifactDataDB> {
  parent?: Artifact | null; // undefined = Unknown parent, null = no parent
  kind?: string;
  attributes?: ResourceAttributes | HighlightAttributes;
}

export interface ArtifactCreateArgs extends BaseCreateArgs {
  parent: Artifact | null;
  kind: string;
  attributes?: ResourceAttributes | HighlightAttributes;
}

export interface AttachToArtifactArgs {
  trx: Transaction;
  role: string[];
  content: string;
  contentType: string;
}

export interface ArtifactHydrateArgs extends BaseHydrateArgs<ArtifactDataDB> {
  // unlike backrefs and parts, sub artifacts are not currently collected under their parent
  // so we don't need to require parent on hydrate
  parent?: Artifact | null; // undefined = Unknown parent, null = no parent
}

export interface ArtifactUpsertArgs extends BaseCreateArgs {
  parent: Artifact | null;
  userId?: string;
  kind: string;
  attributes: ResourceAttributes | HighlightAttributes;
  onCreate?: (artifact: Artifact) => void;
}

export class Artifact extends Base<ArtifactDataDB> {
  readonly type = 'artifact';
  readonly parent: AwaitableValue<Artifact | null>;
  readonly attributes: AwaitableValue<ResourceAttributes | HighlightAttributes>;
  readonly kind: AwaitableValue<string>;
  readonly parts: ActivatableAsync<QueryObservable<ArtifactPartData, ArtifactPart>>;
  readonly backrefs: ActivatableAsync<QueryObservable<BackrefDataDB, Backref>>;

  private static registry = new Registry<Artifact>();
  private _childObservables: Record<string, ClaimLinkedListObservable> = {};

  private constructor(args: ArtifactConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);

    this.parent = args.parent === undefined ? new AwaitValue() : new NowValue(args.parent);
    this.kind = args.kind ? new NowValue(args.kind) : new AwaitValue();
    this.attributes = args.attributes ? new NowValue(args.attributes) : new AwaitValue();

    this.parts = makeActivatableArtifactParts(this);
    this.backrefs = makeActivatableBackrefs(this);

    // Have to do deferred registration, which means no dedup, but that's ok
    this.docReference.passive_then((docRef) => {
      // Might already be registered. have to live with duplicates for anything with an AwaitValue docRef
      Artifact.registry.add(docRef.id, this);
    });
    this.constructorFinish();
  }

  static create(args: ArtifactCreateArgs, _firebase: firebase.app.App = firebase.app()): Artifact {
    const { parent, kind, attributes } = args;
    const firestore = _firebase.firestore();
    const docRef = firestore.collection('artifact').doc() as DocumentReference<ArtifactDataDB>;

    const artifact = new Artifact({ docRef, parent, kind, attributes }, _firebase);
    artifact.backrefs.value().setLoaded(true); // They're not executed, but they are "Loaded" because we just created the artifact
    artifact.parts.value().setLoaded(true); // it's not possible for them to be "missing" records

    void args.trx.prepareOp(artifact, async () => {
      let parentDocRef = parent ? await parent.docReference.get() : null;

      const userID = getCurrentUserID();

      const data: ArtifactDataDB = {
        id: docRef.id,
        userID,
        parentArtifactID: parentDocRef?.id || null,
        createdAt: firebaseNow(),
        updatedAt: firebaseNow(),
        status: 'active',
        kind,
        meta: args.meta || null,
        questVisitCache: [],
        visitedQuestIds: [],
      };
      if (attributes) data.attributes = attributes;

      if (attributes) data.attributes = attributes;

      args.trx.insert(artifact, data);
    });

    return artifact;
  }

  static hydrate(args: ArtifactHydrateArgs, _firebase: firebase.app.App = firebase.app()): Artifact {
    const { docRef, data, parent } = args;
    let artifact = Artifact.registry.get(docRef.id);
    if (artifact) {
      artifact.applyData(data);
      return artifact;
    }

    artifact = new Artifact({ docRef: docRef, data, parent }, _firebase);
    return artifact;
  }

  static get(args: BaseGetArgs<ArtifactDataDB>, _firebase: firebase.app.App = firebase.app()): Artifact {
    let artifact = Artifact.registry.get(args.docRef.id);
    if (artifact) return artifact;

    artifact = new Artifact({ docRef: args.docRef }, _firebase);
    return artifact;
  }

  static getById(args: BaseGetByIdArgs, _firebase: firebase.app.App = firebase.app()): Artifact {
    if (!args.id) throw 'No ID provided';
    const firestore = _firebase.firestore();
    const docRef = firestore.collection('artifact').doc(args.id) as DocumentReference<ArtifactDataDB>;
    return Artifact.get({ docRef });
  }

  static upsert(args: ArtifactUpsertArgs, _firebase: firebase.app.App = firebase.app()): Artifact {
    const firestore = _firebase.firestore();

    const { trx, parent, kind, attributes, onCreate } = args;
    // TODO - I think you can make a second registry which is keyed on userId, kind, and attributes

    const artifact = new Artifact({}, _firebase);

    void trx.prepareOp(artifact, async (trx) => {
      const parentDocRef = parent ? await parent.docReference.get() : null;

      let query = firestore
        .collection('artifact')
        .where('status', '==', 'active')
        .where('kind', '==', kind)
        .where('parentArtifactID', '==', parentDocRef?.id || null) as Query<ArtifactDataDB>;

      const userID = getCurrentUserID();

      if (userID) query = query.where('userID', '==', userID);

      Object.entries(attributes).forEach(([key, value]) => {
        query = query.where(`attributes.${key}`, '==', value);
      });

      const snapshot = await query.get();

      if (snapshot.size === 0) {
        const docRef = firestore.collection('artifact').doc() as DocumentReference<ArtifactDataDB>;

        const data: ArtifactDataDB = {
          id: docRef.id,
          userID: getCurrentUserID(),
          parentArtifactID: parentDocRef?.id || null,
          attributes,
          createdAt: firebaseNow(),
          updatedAt: firebaseNow(),
          status: 'active',
          kind,
          meta: args.meta || null,
          questVisitCache: [],
          visitedQuestIds: [],
        };

        trx.insert(artifact, data);

        // The onCreate contract is that we call it at the soonest moment we know
        // this artifact is being created, AND before it has a docReference set.
        // TODO: rename onCreate to beforeCreate?
        onCreate?.(artifact);

        artifact.docReference.set(docRef);
        artifact.applyData(data);
        artifact.backrefs.value().setLoaded(true); // They're not executed, but they are "Loaded" because we just created the artifact
        artifact.parts.value().setLoaded(true); // it's not possible for them to be "missing" records
      } else {
        const docSnapshot = snapshot.docs[0];
        const docRef = docSnapshot.ref;
        const data = docSnapshot.data()!;
        if (!data) throw 'upsert deseralization failed';

        artifact.docReference.set(docRef);
        artifact.applyData(data);
      }
    });

    return artifact;
  }

  applyData(data: ArtifactDataDB) {
    super.applyData(data);
    if (data.kind) this.kind.set(data.kind);
    if (data.attributes) this.attributes.set(data.attributes);
  }

  children(roles: RolesMap): ClaimLinkedListObservable {
    const { memberRoles } = roles;
    // probably a better way to do this, but at least this should be correct
    let key = JSON.stringify(memberRoles);
    let obs = (this._childObservables[key] =
      this._childObservables[key] ||
      new ClaimLinkedListObservable(this, roles, `${this.prettyId()}.children`, this._firebase));
    return obs;
  }

  // TODO: implement this later
  // async removeEdge(trx: Transaction, role: string[]) {
  //   // TODO: This may need to be made async eventually
  //   // first, we need to remove the reference part/backref that correspond to this claim.
  //   // We can do that by looking through the parts and finding the $role part.
  //   console.log(this.prettyId(), '.detach(', role.join(','), ')');
  //   const parts = await this.getPartsByRoles(role);
  //   if (parts.length === 0) {
  //     // not an error
  //     return;
  //   }

  //   parts.forEach((part) => {
  //     part.archive(trx);
  //   });

  //   await Promise.all(parts.map((part) => part.archive(trx)));
  // }

  createEdge({ trx, role, contentType, content }: AttachToArtifactArgs) {
    ArtifactPart.create({ trx, role, parent: this, content, contentType }, this._firebase);
  }

  async getPart(role: string[], contentType?: string): Promise<ArtifactPart | null> {
    const parts = await this.parts.get();

    let found = parts.find((part) => {
      if (!intersects(role, part.role.peek_or_throw('ArtifactPart should be hydrated (role)'))) return false;
      if (
        contentType &&
        contentType !== part.contentType.peek_or_throw('ArtifactPart should be hydrated (contentType)')
      )
        return false;
      return true;
    });

    return found || null;
  }

  async getReverseEdgeTarget(role: string[], matchType?: 'claim' | 'artifact' | 'quest'): Promise<Claim | null> {
    return getReverseEdgeTarget(this, role, matchType);
  }

  // add a visit to the artifactVisitCache
  async cacheQuestVisit(trx: Transaction, quest: Quest, visit: Claim) {
    let questId = await quest.getId();
    let visitId = visit.id();
    let questVisitCache = (await this.data.get()).questVisitCache || [];

    questVisitCache = questVisitCache.filter((v) => v.visitId !== visitId); // remove any previous quests from this visit
    questVisitCache.unshift({ questId, visitId }); // and add this one
    let visitedQuestIds = Array.from(new Set(questVisitCache.map((v) => v.questId)));
    trx.set(this, { questVisitCache, visitedQuestIds }, true);
  }

  diag(_load: boolean, _tier: number, _postfix?: string): Promise<string> {
    throw 'unimplemented diag for artifact';
  }
}

function makeActivatableArtifactParts(
  artifact: Artifact,
): ActivatableAsync<QueryObservable<ArtifactPartData, ArtifactPart>> {
  const parts = new QueryObservable<ArtifactPartData, ArtifactPart>({
    val: [],
    ctor: (snapshot) => {
      const docRef = snapshot.ref;
      const data = snapshot.data();
      if (!data) throw new Error('Error deserializing ArtifactPart ' + docRef.id);
      return ArtifactPart.hydrate({ docRef, data, parent: artifact }, artifact._firebase);
    },
    name: `${artifact.prettyId()}-ArtifactPart`,
  });

  const activator = async (parts: QueryObservable<ArtifactPartData, ArtifactPart>) => {
    if (!parts.isInjected) {
      const docRef = await artifact.docReference.get();

      parts.name = `${artifact.prettyId()}-ArtifactPart`;
      let partQuery = docRef
        .collection(`artifactPart`)
        .where('recipientID', 'array-contains-any', [getCurrentUserID(), 'PUBLIC'])
        .where('status', '==', 'active') as Query<ArtifactPartData>;
      parts.injectQuery(partQuery);
    }
    parts.execute();
    await parts.awaitLoad();
  };

  return new ActivatableAsync(parts, activator);
}

// Parts bin

// constructor(args: ArtifactArgs, protected _firebase: firebase.app.App = firebase.app()) {
//   super(args, _firebase);
//   const firestore = _firebase.firestore();
//   const { parentArtifact = null, attributes, id, kind, onExists, doc, parts, plainTextPart, dummy } = args;
//   const hasPlainTextPart = typeof plainTextPart !== 'undefined';
//   this.onExists = onExists;
//   const _parts = parts
//     ? parts
//     : // it could be an empty string
//     hasPlainTextPart
//       ? [
//         new ArtifactPart({
//           payload: plainTextPart,
//           role: ['body'],
//           contentType: 'text/plain',
//         }),
//       ]
//       : [];

//   // if we already have an ID or a doc, then let's just hydrate from the DB
//   if (id || doc) {
//     // (docRef("artifact", id)
//     const d = doc || (firestore.collection('artifact').doc(id) as DocumentReference<ArtifactDataDB>);
//     this.constructFromDoc(d).then(() => {
//       this.onExists?.(this);
//     });
//   } else if (hasPlainTextPart || dummy) {
//     // dummy will create a blank artifact with no data for the sake of attaching it to "something"
//     // TODO: this should just be resource, but let's make it resource_response for now
//     this.kind = 'resource_response';
//     this.setLoaded();
//     this.createDocRef();
//     this.setLoaded();
//   } else if (!kind) {
//     throw new Error('Kind not specified');
//   } else {
//     this.parentArtifact = parentArtifact || null;
//     this.attributes = attributes;
//     this.kind = this.kind || kind!;

//     // if we have attributes, but no doc, then let's do a query to get a doc
//     if (attributes) {
//       // do a lookup
//       let query = firestore
//         .collection('artifact')
//         .where('status', '==', 'active')
//         .where('kind', '==', this.kind)
//         .where('parentArtifactID', '==', parentArtifact?.id ?? null) as Query<ArtifactDataDB>;

//       Object.entries(attributes).forEach(([key, value]) => {
//         query = query.where(`attributes.${key}`, '==', value);
//       });
//       // TODO: add user filtering based on userID
//       //
//       query.get().then((docs) => {
//         if (docs.size !== 1) {
//           // TODO: do we want to do anything here?
//           this.createDocRef();
//         } else {
//           const [doc] = docs.docs;
//           this.setDoc(doc.ref);
//           this.isCreated = true;
//           this.register();
//         }

//         this.setLoaded();

//         // TODO - clearly define isCreated, isLoaded, isLookedup and _lookedUp/_signalLookedup
//         if (this.isCreated) {
//           this.onExists?.(this);
//           // Found existing artifact
//         }
//         this.notify();
//       });
//     } else {
//       // if we have no attributes, then we are not attempting to do a query. Thus, we should create a docref
//       this.setLoaded();
//       this.createDocRef();
//     }
//   }
// }
// async save(trx: Transaction): Promise<void> {
//   // finish looking up before creating
//   await this.awaitLookup();
//   if (this.isCreated) return;
//   const parentArtifactID = this.parentArtifact ? await this.parentArtifact.getId() : null;
//   const artifactData: ArtifactDataDB = {
//     kind: this.kind,
//     parentArtifactID,
//     createdAt: firebaseNow(),
//     updatedAt: firebaseNow(),
//     status: 'active',
//   };
//   if (this.attributes) {
//     artifactData.attributes = this.attributes;
//   }

//   // TODO: deduplicate the artifact?
//   trx.insert(this, artifactData);

//   trx.prepareOp(this, async () => {
//     // important to note that this does NOT await on the transaction or its result, just the getId for this artifact
//     // TODO: consider calling part.saveToDb on the part constructor
//     await Promise.all(this.parts.map((part) => part.save(this, trx)));
//     this.onExists?.(this);
//     this.notify();
//   });
//   this.isCreated = true;
//   // await super.saveToDb(trx);
// }
