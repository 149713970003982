import {
  getRolesFromRoleBase,
  Referenceable,
  useObservable,
} from '@edvoapp/plm-common'
import './annotation.scss'
import { MarginNote } from './margin-note'

export interface MarginNoteSetProps {
  entity: Referenceable
}

export function MarginNoteSet({ entity }: MarginNoteSetProps) {
  const roles = getRolesFromRoleBase('response-topic')
  const children = entity.children(roles)
  useObservable(children)

  const marginNotes = children.map((claim) => {
    return <MarginNote key={claim.key()} claim={claim}></MarginNote>
  })

  return (
    <div id={'margin-note-set__' + entity.key()} className="margin-note-set">
      {marginNotes}
    </div>
  )
}

// AUDIT ABOVE TO MAKE SURE IT DOES THE RIGHT THING
// {inline
//   ? parts.sort(compareArtifactParts('contentType')).map((e) => {
//     const entityID = e.id
//     return (
//       <ArtifactPartComponent
//         key={e.key()}
//         // artifact={entity as Artifact}
//         part={e}
//         path={[entity.id || '', entityID]}
//       />
//     )
//   })
//   : null}
