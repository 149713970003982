import firebase from 'firebase/app';
import {
  Artifact,
  Backref,
  BackrefDataDB,
  Claim,
  ClaimLinkedListObservable,
  DocumentReference,
  Entity,
  firebaseNow,
  getCurrentUserID,
  getReverseEdgeTarget,
  makeActivatableBackrefs,
  Query,
  RolesMap,
  Timestamp,
  Transaction,
} from '../internal';
import { ActivatableAsync, AwaitableValue, AwaitValue, NowValue } from './AwaitValue';
import {
  Base,
  BaseConstructorArgs,
  BaseCreateArgs,
  BaseDataDB,
  BaseGetArgs,
  BaseGetByIdArgs,
  BaseHydrateArgs,
} from './Base';
import { QueryObservable } from './QueryObservable';
import { Registry } from './Registry';

export type artifactVisitCacheItem = {
  artifactId: string;
  visitId: string;
};
export interface QuestDataDB extends BaseDataDB {
  keywords: string[];
  name: string;
  artifactVisitCache: artifactVisitCacheItem[];
}

export interface QuestConstructorArgs extends BaseConstructorArgs<QuestDataDB> {
  name?: string;
  keywords?: string[];
}

export interface QuestCreateArgs extends BaseCreateArgs {
  name: string;
}
export interface QuestHydrateArgs extends BaseHydrateArgs<QuestDataDB> {}
export interface QuestGetArgs extends BaseGetArgs<QuestDataDB> {}

export class Quest extends Base<QuestDataDB> {
  readonly type = 'quest';
  readonly name: AwaitableValue<string>;
  readonly keywords: AwaitableValue<string[]>;
  readonly backrefs: ActivatableAsync<QueryObservable<BackrefDataDB, Backref>>;

  private static idRegistry = new Registry<Quest>();
  private static nameRegistry = new Registry<Quest>();
  private _childObservables: Record<string, ClaimLinkedListObservable> = {};

  private constructor(args: QuestConstructorArgs, readonly _firebase: firebase.app.App = firebase.app()) {
    super(args);

    this.name = args.name ? new NowValue(args.name) : new AwaitValue();
    this.keywords = args.keywords ? new NowValue(args.keywords) : new AwaitValue();
    this.backrefs = makeActivatableBackrefs(this);

    // Have to do deferred registration, which means no dedup, but that's ok
    void this.docReference.passive_then((docRef) => {
      // Might already be registered. have to live with duplicates for anything with an AwaitValue docRef
      Quest.idRegistry.add(docRef.id, this);
    });

    void this.name.passive_then((name) => {
      Quest.nameRegistry.add(name, this);
    });
    this.constructorFinish();
  }
  static create(args: QuestCreateArgs, _firebase: firebase.app.App = firebase.app()): Quest {
    const { name } = args;
    const firestore = _firebase.firestore();
    const docRef = firestore.collection('quest').doc() as DocumentReference<QuestDataDB>;

    const quest = new Quest({ docRef, name }, _firebase);
    const keywords = name.toLowerCase().split(' ');

    const userID = getCurrentUserID();

    args.trx.prepareOp(quest, async () => {
      const data: QuestDataDB = {
        id: docRef.id,
        userID,
        createdAt: firebaseNow(),
        updatedAt: firebaseNow(),
        status: 'active',
        name,
        keywords,
        artifactVisitCache: [],
        meta: args.meta || null,
      };

      args.trx.insert(quest, data);
    });

    return quest;
  }

  static hydrate(args: QuestHydrateArgs, _firebase: firebase.app.App = firebase.app()): Quest {
    const { docRef, data } = args;
    let quest = Quest.idRegistry.get(docRef.id);
    if (quest) {
      quest.applyData(data);
      return quest;
    }

    quest = new Quest({ docRef: docRef, data }, _firebase);
    return quest;
  }

  applyData(data: QuestDataDB) {
    super.applyData(data);
    if (data.name) this.name.set(data.name);
    if (data.keywords) this.keywords.set(data.keywords);
  }

  children(roles: RolesMap): ClaimLinkedListObservable {
    const { memberRoles } = roles;
    // probably a better way to do this, but at least this should be correct
    let key = JSON.stringify(memberRoles);
    let obs = (this._childObservables[key] =
      this._childObservables[key] ||
      new ClaimLinkedListObservable(this, roles, `${this.prettyId()}.children`, this._firebase));
    return obs;
  }

  static get(args: BaseGetArgs<QuestDataDB>, _firebase: firebase.app.App = firebase.app()): Quest {
    const { docRef } = args;
    let quest = Quest.idRegistry.get(args.docRef.id);
    if (quest) return quest;

    quest = new Quest({ docRef }, _firebase);
    return quest;
  }

  static getById(args: BaseGetByIdArgs, _firebase: firebase.app.App = firebase.app()): Quest {
    const firestore = _firebase.firestore();
    if (!args.id || args.id === 'null') throw 'Must have an ID';
    const docRef = firestore.collection('quest').doc(args.id) as DocumentReference<QuestDataDB>;
    return Quest.get({ docRef });
  }

  async getReverseEdgeTarget(role: string[], matchType?: 'claim' | 'artifact' | 'quest'): Promise<Entity | null> {
    return getReverseEdgeTarget(this, role, matchType);
  }

  // remove a visit from the artifactVisitCache
  async uncacheArtifactVisit(trx: Transaction, visit: Claim) {
    let visitId = visit.id();
    let data = await this.data.get();
    let artifactVisitCache = data.artifactVisitCache.filter((v) => v.visitId !== visitId);
    trx.set(this, { artifactVisitCache }, true);
  }
  // add a visit to the artifactVisitCache
  async cacheArtifactVisit(trx: Transaction, artifact: Artifact, visit: Claim) {
    let artifactId = await artifact.getId();
    let visitId = visit.id();
    let data = await this.data.get();
    let artifactVisitCache = data.artifactVisitCache || [];
    artifactVisitCache.unshift({ artifactId, visitId });
    trx.set(this, { artifactVisitCache }, true);
  }
  diag(_load: boolean, _tier: number, _postfix?: string): Promise<string> {
    throw 'unimplemented diag for quest';
  }
}

// omit search string if you want all quests
export function queryQuests(searchString?: string): QueryObservable<QuestDataDB, Quest> {
  const firestore = firebase.firestore();
  let query = firestore.collection('quest').where('status', '!=', 'archived').where('userID', '==', getCurrentUserID());
  if (searchString && searchString !== '') {
    const keywords = searchString.toLowerCase().split(' ');
    query = query.where('keywords', 'array-contains-any', keywords);
  }

  const qo = new QueryObservable<QuestDataDB, Quest>({
    val: [],
    ctor: (snapshot) => {
      const docRef = snapshot.ref;
      const data = snapshot.data();
      if (!data) throw new Error('Error deserializing quest data');
      return Quest.hydrate({ docRef, data });
    },
  });

  qo.injectQuery(query as Query<QuestDataDB>);
  return qo;
}
