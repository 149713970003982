import { createContext, FunctionComponent } from 'preact';
import { useEffect, useReducer, useState } from 'preact/hooks';
import { useMode } from '.';
import { OverlayManager } from '../services/overlay-manager';
import { useProvider } from './common';

interface OverlayContextProps {
  overlayManager: OverlayManager;
  updateKey: number;
}

const OverlayContext = createContext<OverlayContextProps | null>(null);

export const OverlayProvider: FunctionComponent = ({ children }) => {
  const { mode } = useMode();
  const [updateKey, forceUpdate] = useReducer(x => x + 1, 0);
  const [overlayManager] = useState(() => new OverlayManager(mode, forceUpdate));
  // since this is downstream from mode, we can't really tell the PRM to update its mode, so we're using an effect whenever mode changes
  useEffect(() => {
    overlayManager.setMode(mode);
  }, [mode, overlayManager]);

  return <OverlayContext.Provider value={{ overlayManager, updateKey }}>{children}</OverlayContext.Provider>;
};

export const useOverlay = () => useProvider(OverlayContext, 'paintableRegions');
