import firebase from 'firebase/app';
import { DocumentReference, firebaseNow, getCurrentUserID, Timestamp } from '../utils/firebase';
import { AwaitableValue, AwaitValue, AwaitValueOnce, NowValue, NowValueOnce } from './AwaitValue';
import { Entity, UnifiedId, UnifiedIdStruct } from './Entity';
import { Transaction } from './Transaction';

// TODO rename *DataDB to *
export interface BaseDataDB {
  id: string;
  status: 'active' | 'archived';
  meta: BaseMeta | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedBy?: string;
  deletedAt?: Timestamp;
  userID: string | null;
}
export interface BaseDataRecipient extends BaseDataDB {
  recipientID: string[];
  keyID: string;
  cipher: string;
}

export interface BaseGetArgs<DataDb> {
  docRef: DocumentReference<DataDb>;
}
export interface BaseHydrateArgs<DataDb> {
  docRef: DocumentReference<DataDb>;
  data: DataDb;
}
export interface BaseGetByIdArgs {
  id: string;
}
export interface BaseCreateArgs {
  trx: Transaction;
  meta?: BaseMeta;
}

export interface BaseArchiveDocRefArgs<DataDb> {
  trx: Transaction;
  docRef: DocumentReference<DataDb>;
}

export interface BaseConstructorArgs<DataDb> {
  docRef?: DocumentReference<DataDb>;
  data?: DataDb;
  meta?: BaseMeta;
  status?: 'active' | 'archived';
}

export interface BaseMeta {
  customClass?: string;
  mode?: 'readonly';
  placeholderText?: string;
  testId?: string;
  testSeq?: number;
}

export abstract class Base<DataDb extends BaseDataDB> {
  abstract type: string;
  readonly docReference: AwaitableValue<DocumentReference<DataDb>>;
  readonly data: AwaitableValue<DataDb>;
  readonly meta: AwaitableValue<BaseMeta | null>;
  protected isDestroyed?: boolean;
  status: 'active' | 'archived';
  _key?: string;
  protected abstract _firebase: firebase.app.App;

  // Pending transactions which include this object
  readonly transactions = new Set<Transaction>();

  constructor(args: BaseConstructorArgs<DataDb>) {
    this.docReference = args.docRef ? new NowValueOnce(args.docRef) : new AwaitValueOnce();
    this.data = args.data ? new NowValue(args.data) : new AwaitValue();
    this.meta = args.meta ? new NowValue(args.meta) : new AwaitValue();
    this.status = args.status || 'active'; // Consider making this an AwaitableValue and optional constructor arg
  }

  // Every subclass must call this at the end of it's constructor
  constructorFinish() {
    void this.data.passive_then((data) => {
      this.applyData(data);
    });

    void this.docReference.passive_then((docref) => {
      // Mayyybe this could be if(!data){.get}, if .onSnapshot proves to have a performance problem
      docref.onSnapshot(
        (snapshot) => {
          // console.log('ONSNAPSHOT', this.constructor.name, snapshot.data())
          // CONSIDER - how do we avoid calling applyData twice with the same stuff?
          const data = snapshot.data();
          if (data) {
            // TODO - since we are not copying the titles of the template artifacts, this will throw

            // if (this.validateDocumentUserId(data.userID)) {
            //   console.warn(`uid check failed for ${this.prettyId()}. Expected: "${expectUid}" Got: "${data.userID}"`);
            // }

            // if (!data) throw `No data for ${this.type} snapshot ${snapshot.ref.id}`;
            // TODO - figure out how to tighten this up with .create, which calls trx.insert AFTER
            // we have a docRef, and have fired onSnapshot

            this.data.set(data);
            this.applyData(data);
          }
        },
        (err) => {
          console.error(`${this.prettyId()}.onSnapshot failed`, err);
        },
      );
    });
  }

  id(): string | undefined {
    return this.docReference.peek()?.id;
  }
  key(): string {
    if (this._key) return this._key;

    const id = this.docReference.peek()?.id;
    // For those subclasses which always have a docRef, this will always short circuit, and we won't bloat things up with _key
    if (id) return id;

    this._key = this._key || id || '*' + Math.random().toString().substring(2);
    return this._key;
  }
  prettyId() {
    return `${this.type}:${(this.id() || this.key()).substr(0, 4)}`;
  }
  unifiedId(): UnifiedId | undefined {
    const docRef = this.docReference.peek();
    return docRef ? UnifiedId.fromDocRef(docRef) : undefined;
  }
  unifiedIdStruct(): UnifiedIdStruct | undefined {
    const docRef = this.docReference.peek();
    return docRef ? { [this.type + 'Id']: docRef.id } : undefined;
  }
  async getId(): Promise<string> {
    return (await this.docReference.get()).id;
  }
  async getDoc(): Promise<DocumentReference> {
    return await this.docReference.get();
  }

  archive(trx: Transaction) {
    const uid = this._firebase.auth().currentUser?.uid || 'UNKNOWN';
    this.status = 'archived';
    this.data.passive_then((data) => {
      data.status = 'archived';
    });

    // console.log("ARCHIVE", uid)
    trx.update(this, {
      status: 'archived',
      deletedBy: uid,
      deletedAt: firebaseNow(),
    });
  }

  touch(trx: Transaction) {
    trx.update(this, { updatedAt: firebaseNow() });
  }

  applyData(data: DataDb) {
    const d = data;
    if (d.status) {
      this.status = d.status; // TODO consider making status an AwaitableValue
    }
    this.meta.set(data.meta || null);

    // this.notify()
    // nobody really needs to know right now tbh
  }
  destroy() {
    this.isDestroyed = true;

    for (let property of Object.values(this)) {
      if (typeof property.destroy === 'function') property.destroy();
    }
  }
}
