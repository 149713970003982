import firebase from 'firebase/app';
import 'firebase/analytics';
// import 'firebase/database';
import 'firebase/auth';
require('firebase/firestore')
// import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

export type Query<T = firebase.firestore.DocumentData> = firebase.firestore.Query<T>;
export type DocumentReference<T = firebase.firestore.DocumentData> = firebase.firestore.DocumentReference<T>;
export type QueryDocumentSnapshot<T = firebase.firestore.DocumentData> = firebase.firestore.QueryDocumentSnapshot<T>;
export type CollectionReference<T = firebase.firestore.DocumentData> = firebase.firestore.CollectionReference<T>;
export type DocumentSnapshot<T = firebase.firestore.DocumentData> = firebase.firestore.DocumentSnapshot<T>;
export type QuerySnapshot<T = firebase.firestore.DocumentData> = firebase.firestore.QuerySnapshot<T>;
export type Timestamp = firebase.firestore.Timestamp;
export type User = firebase.User;

export const firebaseNow = () => firebase.firestore.Timestamp.now();

// ANCHOR: Env

/**
 * This enum describes the environment that the API is being consumed in. Right now, we
 * have three different firebase projects (dev, staging, prod), and two different clients
 * which will be using them (webapp and extension). This gives six different init
 * environments we can use. In the future, there may be more, or there may be less; as we
 * add or remove various instances of the application, we should update this enum and the
 * init function.
 */
export enum Env {
  Dev = 'dev',
  Staging = 'staging',
  Prod = 'prod',
}

// ANCHOR: init

/**
 * Arguments for the init function.
 */
export interface InitArgs {
  /**
   * Indicates the environment that the API will be operating in. This translates to the
   * firebase config to use when initializing the app. Each type is associated with a
   * different config.
   */
  env: Env;

  /**
   * Indicates whether or not to use emulators after initializing the application.
   * Emulators are used when you want to test out functionality without using remote
   * backends, be they production, staging, or whatever. The assumed default is false.
   */
  useEmulators?: boolean;
  hideAnalytics?: boolean;
}

/**
 * Initializes the firebase app. This must be run before any other API functions which use
 * firebase, which is pretty much all of them, so run it first before using any API
 * functions.
 */

const commonStagingConfig = {
  apiKey: 'AIzaSyBQSJZga9jHeNvLuQhwJYFY1hAi4kbiU-E',
  authDomain: 'edvo-plm-staging.firebaseapp.com',
  databaseURL: 'https://edvo-plm-staging.firebaseio.com',
  projectId: 'edvo-plm-staging',
  storageBucket: 'edvo-plm-staging.appspot.com',
  messagingSenderId: '697738573788',
  appId: '1:697738573788:web:2bf6960f4fdd8e340e4119',
};

const commonProdConfig = {
  apiKey: 'AIzaSyAXGT5h3y2VPBVqRKQzmFXdG5t8cFPe31c',
  authDomain: 'edvo-plm.firebaseapp.com',
  databaseURL: 'https://edvo-plm.firebaseio.com',
  projectId: 'edvo-plm',
  storageBucket: 'edvo-plm.appspot.com',
  messagingSenderId: '648498933456',
  appId: '1:648498933456:web:65734b78781a3015fcb8d5',
};

function getConfig(env: Env | string) {
  switch (env) {
    case Env.Dev:
      // this actually doesn't matter if we're using emulators
      return commonStagingConfig;
    case Env.Staging:
      return {
        ...commonStagingConfig,
        measurementId: 'G-N5RGSZ2S5S',
      };
    case Env.Prod:
      return {
        ...commonProdConfig,
        measurementId: 'G-XVDEMCR7T4',
      };
    default:
      throw new Error(`Invalid env: ${env}`);
  }
}

export function getEnv(nodeEnv: string): Env {
  switch (nodeEnv) {
    case 'staging':
      return Env.Staging;
    case 'production':
      return Env.Prod;
    case 'development':
    default:
      return Env.Dev;
  }
}

export { firebase };

export function init({ env, useEmulators, hideAnalytics }: InitArgs) {
  let _ = hideAnalytics;
  // Select the config to initialize with
  const config = getConfig(env);

  // Initialize the application
  firebase.initializeApp(config);

  // If using emulators, set those up
  if (useEmulators) {
    // The ports used here should match those listed in the firebase.json config in
    // the root of the api package
    firebase.auth().useEmulator('http://localhost:4101');
    firebase.firestore().useEmulator('localhost', 4102);
    firebase.functions().useEmulator('localhost', 4104);
    firebase.storage().useEmulator("localhost", 4105);
  }
  // if (!hideAnalytics) {
  //   firebase.analytics();
  // }
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export function getCurrentUserID(): string {
  const uid = getCurrentUser()?.uid || '';
  // TODO: throwing has a bad consequence. ideally we should not even be calling getCurrentUserID if the user is not even logged in.
  // if (!uid) throw 'User is not logged in.';
  return uid;
}
