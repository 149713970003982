import {
  Claim,
  Entity,
  getRolesFromRoleBase,
  RoleBase,
  RolesMap,
} from '@edvoapp/plm-common'
import { JSX } from 'preact'
import { EventNav } from '../../../service'

export type RootType = 'quest' | 'artifact'
export interface RenderContext {
  evtNav: EventNav
  renderTier: number
  extraClass: string[]
}

export abstract class Renderer {
  protected roles: RolesMap
  protected relationClass: string
  constructor(roleBase: RoleBase, protected recurse = true, protected rootType: RootType) {
    this.roles = getRolesFromRoleBase(roleBase)
    this.relationClass = 'relation-' + roleBase
  }
  abstract useRenderChildren(
    entity: Entity,
    renderContext: RenderContext,
    awaitLoad?: boolean,
  ): JSX.Element | null
  abstract baseContext(evtNav: EventNav): RenderContext
}
