const env = process.env.PREACT_APP_NODE_ENV || process.env.NODE_ENV;

// TODO: update this to view.edvo.com when a prod version exists
const viewerUrlProduction = 'https://view-staging.edvo.com';
const viewerUrlStaging = 'https://view-staging.edvo.com';
const viewerUrlDev = 'http://localhost:3001';

const injectorUrlProduction = 'https://plm-annotator-injector.edvo.com/annotator-injector-outer-bundle.js';
const injectorUrlStaging = 'https://plm-annotator-injector-staging.edvo.com/annotator-injector-outer-bundle.js';
const injectorUrlDev = 'http://localhost:8080/annotator-injector-outer-bundle.js';

const injectorStylesUrlProduction = 'https://plm-annotator-injector.edvo.com/annotator-injector-outer-styles.css';
const injectorStylesUrlStaging = 'https://plm-annotator-injector-staging.edvo.com/annotator-injector-outer-styles.css';
const injectorStylesUrlDev = 'http://localhost:8080/annotator-injector-outer-styles.css';

const webappUrlProduction = 'https://plm.edvo.com';
const webappUrlStaging = 'https://plm-staging.edvo.com';
const webappUrlDev = 'http://localhost:4000';

const apiEndpointUrlProduction = 'https://plm.edvo.com';
const apiEndpointUrlStaging = 'https://plm-staging.edvo.com';
const apiEndpointUrlDev = 'http://localhost:4103';

const overlayUrlProduction = 'https://plm-annotator-overlay.edvo.com';
const overlayUrlStaging = 'https://plm-annotator-overlay-staging.edvo.com';
const overlayUrlDev = 'http://localhost:8081/index.html';

function getViewerUrl() {
  switch (env) {
    case 'staging':
      return viewerUrlStaging;
    case 'production':
      return viewerUrlProduction;
    case 'development':
    default:
      return viewerUrlDev;
  }
}

function getInjectorUrl() {
  switch (env) {
    case 'staging':
      return injectorUrlStaging;
    case 'production':
      return injectorUrlProduction;
    case 'development':
    default:
      return injectorUrlDev;
  }
}

function getInjectorStylesUrl() {
  switch (env) {
    case 'staging':
      return injectorStylesUrlStaging;
    case 'production':
      return injectorStylesUrlProduction;
    case 'development':
    default:
      return injectorStylesUrlDev;
  }
}

function getWebappUrl() {
  switch (env) {
    case 'staging':
      return webappUrlStaging;
    case 'production':
      return webappUrlProduction;
    case 'development':
    default:
      return webappUrlDev;
  }
}

function getApiEndpoint() {
  switch (env) {
    case 'staging':
      return apiEndpointUrlStaging;
    case 'production':
      return apiEndpointUrlProduction;
    case 'development':
    default:
      return apiEndpointUrlDev;
  }
}

function getOverlayUrl() {
  switch (env) {
    case 'staging':
      return overlayUrlStaging;
    case 'production':
      return overlayUrlProduction;
    case 'development':
    default:
      return overlayUrlDev;
  }
}

export const config = {
  env,
  viewerUrl: getViewerUrl(),
  injectorUrl: getInjectorUrl(),
  injectorStylesUrl: getInjectorStylesUrl(),
  webappUrl: getWebappUrl(),
  apiEndpointUrl: getApiEndpoint(),
  overlayUrl: getOverlayUrl(),
};
