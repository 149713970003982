import { Artifact, intersects, useAwaitable } from '@edvoapp/plm-common'
import { ArtifactPartComponent } from '.'
import { RenderContext, Renderer } from './renderer/renderer'

export interface ArtifactComponentProps {
  artifact: Artifact
  renderer: Renderer
  renderContext: RenderContext
  omitBody?: boolean
  omitLeadingTrailing?: boolean
}

export function ArtifactComponent({
  artifact,
  renderer,
  renderContext,
  omitBody,
  omitLeadingTrailing,
}: ArtifactComponentProps) {
  let body

  const parts = useAwaitable(artifact.parts)

  if (!omitBody && parts) {
    console.log('parts!', parts)
    body = parts
      // .sort(compareArtifactParts('contentType'))
      .map((part) => {
        // don't render highlight leading text
        if (
          omitLeadingTrailing &&
          intersects(part.role.peek_or_throw('Part should have a role'), [
            'highlight_leading_text',
            'highlight_trailing_text',
          ])
        ) {
          return null
        }
        return <ArtifactPartComponent key={part.id()} part={part} />
      })
  }

  const children = renderer.useRenderChildren(artifact, renderContext)

  return (
    <div id={'artifact__' + artifact.key()} className="artifact-component">
      {body}
      {children}
    </div>
  )
}

// AUDIT ABOVE TO MAKE SURE IT DOES THE RIGHT THING
// {inline
//   ? parts.sort(compareArtifactParts('contentType')).map((e) => {
//     const entityID = e.id
//     return (
//       <ArtifactPartComponent
//         key={e.key()}
//         // artifact={entity as Artifact}
//         part={e}
//         path={[entity.id || '', entityID]}
//       />
//     )
//   })
//   : null}
