import { ObservableList } from "./ObservableList";

export class FilteredObservableList<Obj> extends ObservableList<Obj> {
    private unsub: () => void;
    private _changed = false;
    private _isLoaded = false;
    constructor(readonly list: ObservableList<Obj>, filterFn: (obj: Obj) => boolean) {
        super()
        this.unsub = list.subscribe({
            ITEM_LISTENER: (item, op) => {
                if (op == 'ADD' && filterFn(item)) {
                    this.rawInsert(item);
                    this._changed = true;
                }
                if (op === 'REMOVE') {
                    this.rawRemove(item);
                    this._changed = true;
                }
            },
            CHANGE: async () => {
                if (this._changed || !this._isLoaded) {
                    this._isLoaded = true;
                    this._changed = false;
                    await this.notifyAsync()
                }
            },
        });
    }
    isLoaded() {
        // important to overload this. The ObservableList base class is passive, so it's always "loaded"
        return this._isLoaded
    }
    destroy() {
        this.unsub()
    }
}